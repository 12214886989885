import { AbstractRestService } from "../abstractRestService";
import { Route } from "../../model/route/route";
import { AxiosResponse } from "axios";
import http from "../../http-common";
import { RouteFilter } from "../../filter/routeFilter";

export class RoutesRestService extends AbstractRestService<Route> {
    constructor() {
      super('/api/routes');
    }  

    filterRoutes(filter : RouteFilter, page: number, size: number) : Promise<AxiosResponse<Route[]>>{
      return http.get(this.resourceUrl + "/filter", 
      {params:{routeCategoryId:filter.category, durationDays:filter.duration, minRate:filter.rate, clientsIds: filter.clientIds.join(','), page:page, size:size}});
    }

    createRoute(data : FormData) : Promise<AxiosResponse<Route[]>>{
      return http.post(this.resourceUrl, data);
      }

    updateRoute(data : FormData) : Promise<AxiosResponse<Route[]>>{
      return http.put(this.resourceUrl, data);
      }
    
    findPopularRoutes() : Promise<AxiosResponse<Route[]>>{
      return http.get(this.resourceUrl + "/popular");
    }

    getSharedRouteById(id : number) : Promise<AxiosResponse<Route>>{
      return http.get(this.resourceUrl + "/shared/" + id);
    }
}

export const routesRestService = new RoutesRestService();