import { Table } from "react-bootstrap";
import  {Link}  from 'react-router-dom';
import styles from "./ReviewsPage.module.css";
import { useTranslation } from "react-i18next";
import { Client } from "../../../model/client/client";

function ReviewsTable(props : {clients : Client[]}) {
    const {clients} = props;
    const { t }  = useTranslation('all');

    return (
            <div className={styles.rowTable}>
                <Table bordered hover className={styles.table}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>{t("components.clients.client.name")}</th>
                            <th>{t("components.clients.client.numOfUnapprowedReviews")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    {clients.length > 0 &&
                    <tbody>
                    {clients.map((item: Client) => {
                        return (
                            <tr className={styles.tableRow} key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.numOfReviews}</td>
                                <td><Link onClick={()=> setTimeout(()=> document.getElementById('commentSection')?.scrollIntoView(),500)} className={styles.link} to={{pathname:"/client-details-page/" + item.id}}>{t("components.clients.client.visitClient")}</Link></td>
                            </tr>
                        );
                    })}
                    </tbody>}
                </Table>
            </div>
    )
}

export default ReviewsTable;