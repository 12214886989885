import styles from "./ProductPage.module.css";
import { Product } from "../../../model/product/product";
import { useEffect, useState } from "react";
import ProductDetails from "./ProductDetails";
import { getProductById } from "../../../actions/product/product";
import { useParams } from "react-router-dom";
import HeaderByClientCategory from "../../layouts/header/HeaderByClientCategory";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import PhotoVideoSection from "../../client/photoVideoSection/PhotoVideoSection";

export default function SingleProduct() {
  const [product, setProduct] = useState<Product>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    getProductById(+id).then((resp) => setProduct(resp.data));
  }, [id]);

  if (!product) return <DownloadApp />;

  return (
    <div>
      <HeaderByClientCategory client={product.client} />
      <div className={styles.productPage}>
        <ProductDetails product={product} />
      </div>
      <DownloadApp />
      <PhotoVideoSection client={product.client} />
    </div>
  );
}
