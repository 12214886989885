import styles from "./Title.module.css";
import routeCardStyles from "../RouteCard.module.css";
import { useNavigate } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import { MdEdit } from "react-icons/md";
import {
  getUsernameFromLocalStorage,
  userHasRole,
} from "../../../../utils/jwt";
import { nameOf } from "../../../../utils/i18n";
import { Route } from "../../../../model/route/route";

export default function Title(props: {
  route: Route;
  setDeleteModalShow: (del: boolean) => void;
}) {
  const { route, setDeleteModalShow } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.routeTitleContainer}>
      <div className={styles.col}>
        <p className={styles.routeTitle}>{nameOf(route)}</p>
      </div>
      {(userHasRole("admin") ||
        (route.creator &&
          route.creator.username === getUsernameFromLocalStorage())) && (
        <>
          <div
            className={`${styles.deleteIconCol} ${routeCardStyles.deleteIconCol}`}
          >
            <MdEdit
              className={styles.deleteIcon}
              onClick={() => navigate("/edit-route/" + route.id)}
              size={25}
              style={{ paddingTop: "5%" }}
            ></MdEdit>
          </div>
          <div
            className={`${styles.deleteIconCol} ${routeCardStyles.deleteIconCol}`}
          >
            <TiDelete
              className={styles.deleteIcon}
              onClick={() => setDeleteModalShow(true)}
              size={30}
              color="red"
              style={{ paddingTop: "2.5%" }}
            ></TiDelete>
          </div>
        </>
      )}
    </div>
  );
}
