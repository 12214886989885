import { Dispatch } from "redux";
import { clientCategoryService } from "../../rest/client/clientCategoryService";

export const LOAD_CLIENT_CATEGORIES = 'LOAD_CLIENT_CATEGORIES';

export const loadCategories = () => {
    return clientCategoryService.findAll();
}

export const loadClientCategories = (dispatch: Dispatch<any>) => {
    clientCategoryService.findAll()
    .then(response => {
        dispatch({ type: LOAD_CLIENT_CATEGORIES, payload : response.data })})
} 