import styles from "./EditName.module.css";
import { Form, InputGroup } from "react-bootstrap";
import ZASTAVA_EN from "../../../assets/styles/icons/ZASTAVA_EN.png";
import ZASTAVA_SR from "../../../assets/styles/icons/ZASTAVA_SR.png";
import ZASTAVA_HR from "../../../assets/styles/icons/ZASTAVA_HR.png";

export default function EditName(props: {
  name: string;
  nameSr: string;
  nameHr: string;
  setName: (name: string) => void;
  setNameSr: (name: string) => void;
  setNameHr: (name: string) => void;
  register: any;
  errors: any;
  title: string;
}) {
  const {
    name,
    nameSr,
    nameHr,
    setName,
    setNameSr,
    setNameHr,
    register,
    errors,
    title,
  } = props;

  return (
    <div className={styles.row} style={{ marginTop: "2vh" }}>
      <div style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <Form.Label className={styles.title}>{title}</Form.Label>
          <InputGroup style={{ marginTop: "3vh" }}>
            <div className={styles.countryIcon}>
              <img width="100%" height="100%" src={ZASTAVA_EN} alt="" />
            </div>
            <div style={{ width: "1vw" }}></div>
            <Form.Control
              type="text"
              {...register("title")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <div className={styles.errorMessage}>
            {errors.title?.message || errors.name?.message}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <InputGroup>
            <div className={styles.countryIcon}>
              <img width="100%" height="100%" src={ZASTAVA_SR} alt="" />
            </div>
            <div style={{ width: "1vw" }}></div>
            <Form.Control
              type="text"
              {...register("titleSrb")}
              value={nameSr}
              onChange={(e) => setNameSr(e.target.value)}
            />
          </InputGroup>
          <div className={styles.errorMessage}>
            {errors.titleSrb?.message || errors.nameSrb?.message}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <InputGroup>
            <div className={styles.countryIcon}>
              <img width="100%" height="100%" src={ZASTAVA_HR} alt="" />
            </div>
            <div style={{ width: "1vw" }}></div>
            <Form.Control
              type="text"
              {...register("titleCro")}
              value={nameHr}
              onChange={(e) => setNameHr(e.target.value)}
            />
          </InputGroup>
          <div className={styles.errorMessage}>
            {errors.titleCro?.message || errors.nameCro?.message}
          </div>
        </Form.Group>
      </div>
    </div>
  );
}
