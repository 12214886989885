import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditCountry.module.css";
import { useTranslation } from "react-i18next";
import News from "../../../home/news/News";
import { Form } from "react-bootstrap";
import { Country } from "../../../../model/admin/country";
import { countryService } from "../../../../rest/admin/countryService";
import { useForm, SubmitHandler } from "react-hook-form";
import HeaderWithTxt from "../../../layouts/header/HeaderWithTxt";
import Title from "../../../layouts/title/Title";
import { STATIC_HOST } from "../../../../utils/communication";

type Inputs = {
  nameRequired: string;
  nameSrRequired: string;
  nameHrRequired: string;
  codeRequired: string;
};

function EditCountry() {
  const { id } = useParams();
  const [country, setCountry] = useState<Country>(new Country());
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();

  useEffect(() => {
    if (id !== undefined) {
      countryService.findById(Number(id)).then((resp) => {
        setCountry(resp.data);
        setValue("nameRequired", resp.data.name);
        setValue("nameSrRequired", resp.data.nameSrb);
        setValue("nameHrRequired", resp.data.nameCro);
        setValue("codeRequired", resp.data.code);
      });
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (country.id === 0)
      countryService.create(country).then(() => {
        navigate("/countries");
      });
    else
      countryService.update(country.id, country).then(() => {
        navigate("/countries");
      });
  };

  return (
    <div>
      <HeaderWithTxt
        background={STATIC_HOST + "front/home/header/HOME_HEADER_POZADINA.png"}
        height={84.10732714138287}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={32.6109391124871}
        title=""
        golden={false}
      ></HeaderWithTxt>
      <div className={styles.sectionCountryNews}>
        <div className={styles.countrySection}>
          <div className={styles.titleRow}>
            <Title
              title={
                id === undefined || Number(id) === 0
                  ? t("components.countries.edit.titleCreate")
                  : t("components.countries.edit.titleEdit")
              }
              icon="balon"
            ></Title>
          </div>
          <div className={styles.formRow}>
            <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("components.countries.edit.name")} (en)
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameRequired", { required: true })}
                  value={country.name}
                  onChange={(e) =>
                    setCountry({ ...country, name: e.target.value })
                  }
                />
                {errors.nameRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.countries.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("components.countries.edit.name")} (sr)
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameSrRequired", { required: true })}
                  value={country.nameSrb}
                  onChange={(e) =>
                    setCountry({ ...country, nameSrb: e.target.value })
                  }
                />
                {errors.nameSrRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.countries.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("components.countries.edit.name")} (hr)
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameHrRequired", { required: true })}
                  value={country.nameCro}
                  onChange={(e) =>
                    setCountry({ ...country, nameCro: e.target.value })
                  }
                />
                {errors.nameHrRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.countries.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.countries.edit.code")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("codeRequired", { required: true })}
                  value={country.code}
                  onChange={(e) =>
                    setCountry({ ...country, code: e.target.value })
                  }
                />
                {errors.codeRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.countries.error.required")}
                  </span>
                )}
              </Form.Group>
              <button className={styles.btnSave} type="submit">
                {t("components.countries.edit.save")}
              </button>
            </Form>
          </div>
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
    </div>
  );
}

export default EditCountry;
