import {DefaultModel} from '../defaultModel'

export class ServiceCategory extends DefaultModel {
    name: string;
    nameSrb : string;
    nameCro : string;
    color: string;
    code: string;
    constructor(){
        super();
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
        this.color = "";
        this.code = "";
    }
}