import styles from "./Arrows.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function Arrows(props: {
  page: number;
  lastPage: number;
  changePage: (page: number) => void;
}) {
  const { page, lastPage, changePage } = props;

  return (
    <div className={styles.rowArrows}>
      <div className={styles.paginationCol}>
        <div className={styles.paginationSection}>
          <div
            className={page <= 0 ? styles.arrowColWithOpacity : styles.arrowCol}
          >
            <IoIosArrowBack
              style={{ paddingRight: "0.1vw" }}
              color="#FFFFFF"
              opacity="0.5"
              size={25}
              onClick={() => {
                if (page > 0) changePage(page - 1);
              }}
            />
          </div>
          <div
            className={
              page === lastPage ? styles.arrowColWithOpacity : styles.arrowCol
            }
          >
            <IoIosArrowForward
              style={{ paddingLeft: "0.1vw" }}
              color="#FFFFFF"
              opacity="0.5"
              size={25}
              onClick={() => {
                if (page < lastPage) changePage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Arrows;
