import http from "../../http-common";
import { googleApiService } from "./googleApiService";
import { mapBoxService } from "./mapBoxService";
import { openRouteService } from "./openRouteService";

export class DirectionsService {

  async getRoute(waypoints : any[], waypointsForORS : any[]): Promise<string>{
    let ret = await openRouteService.getRoute(waypointsForORS);
    if(ret !== "")
        return ret;
    ret = await mapBoxService.getRoute(waypointsForORS);
        if(ret !== "")
            return ret;
    ret = await googleApiService.getRoute(waypoints);
    return ret;
  }
}

export const directionsService = new DirectionsService();