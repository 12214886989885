import {DefaultModel} from '../defaultModel'
import { Client } from '../client/client';
import {File} from '../file/file'
import { ServiceCategory } from './serviceCategory';

export class ClientService extends DefaultModel {
    name: string;
    nameSrb : string;
    nameCro: string;
    description: string;
    descriptionSrb : string;
    descriptionCro : string;
    client : Client;
    files : File[];
    videos : File[];
    serviceCategory : ServiceCategory;
    constructor(){
        super();
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
        this.description = "";
        this.descriptionSrb = "";
        this.descriptionCro = "";
        this.client = {} as Client;
        this.files = [];
        this.videos = [];
        this.serviceCategory = {} as ServiceCategory;
    }
}
