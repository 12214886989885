import { LOAD_CLIENT_TYPES } from "../actions/client/clientType";
import { ClientType } from "../model/client/clientType";

export interface ApplicationState {
  clientTypes: ClientType[];
}

const initialState: ApplicationState = {
  clientTypes: [],
};

const clientTypeReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case LOAD_CLIENT_TYPES:
      return { ...state, clientTypes: payload };
    default:
      return state;
  }
};

export default clientTypeReducer;
