import { useMemo, useState } from "react";
import styles from "./Home.module.css";
import Map from "./map/Map";
import News from "./news/News";
import Clients from "./clients/Clients";
import Products from "./products/Products";
import Services from "./services/Services";
import HeaderSlick from "../layouts/header/HeaderSlick";
import { Client } from "../../model/client/client";
import { STATIC_HOST } from "../../utils/communication";
import FilterSection from "./filter-section/FilterSection";
import AboutProject from "./about/AboutProject";
import TitleDarkLight from "../layouts/title/TitleDarkLight";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Home() {
  const clients = useSelector<any, Client[]>(
    (state) => state.clients.filteredClients
  );
  const { t } = useTranslation("all");
  const headerPhotos = useMemo(
    () => Array.from({ length: 10 }).map((_, i) => `header${i}.jpeg`),
    []
  );

  return (
    <>
      <HeaderSlick
        photos={headerPhotos}
        height={77}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={30}
      />
      <div className={styles.home}>
        <FilterSection />
        <div className={styles.sectionMap}>
          <Map clients={clients} />
        </div>
        <div className={styles.sectionClientsNews}>
          <div className={styles.sectionClients}>
            <TitleDarkLight titleDark={t("components.home.users")} />
            <Clients type="K" />
            <hr className={styles.homeHr} />
            <TitleDarkLight
              titleDark={t("global.eatpannonia")}
              titleLight={t("components.home.clients")}
            />
            <Clients type="P" />
            <hr className={styles.homeHr} />
            <TitleDarkLight titleDark={t("components.home.collaborators")} />
            <Clients type="S" />
            <hr className={styles.homeHr} />
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
        <div className={styles.sectionProducts}>
          <Products />
          <hr className={styles.homeHr} />
        </div>
        <div className={styles.sectionServices}>
          <Services />
          <hr className={styles.homeHr} />
        </div>
        <div className={styles.sectionAbout}>
          <AboutProject />
        </div>
      </div>
    </>
  );
}
