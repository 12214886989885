import styles from "./ProductsAndServices.module.css";
import { Client } from "../../../../model/client/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ServiceCard from "../../../service/service-card/ServiceCard";
import ProductCard from "../../../product/product-card/ProductCard";
import Title from "../../../layouts/title/Title";

export default function ProductsAndServices(props: { client: Client }) {
  const { client } = props;
  const { t } = useTranslation("all");
  const navigate = useNavigate();

  return (
    <>
      {client.products.length > 0 && (
        <Title
          title={t("components.clients.client.products")}
          icon="balon"
        ></Title>
      )}
      {client.products.length > 0 && (
        <div className={styles.products}>
          {client.products.slice(0, 3).map((product, index) => (
            <div key={index}>
              <ProductCard product={product} handleDelete={() => {}} />
              <div className={styles.rowWithHeight}></div>
            </div>
          ))}
        </div>
      )}
      {client.products.length > 0 && (
        <div className={styles.rowShowBtn}>
          <div>
            <button
              className={styles.btnShowAll}
              onClick={() => navigate("/products-page/" + client.name)}
            >
              {t("components.clients.client.showAll")}
            </button>
          </div>
        </div>
      )}
      {client.clientServices.length > 0 && (
        <Title
          title={t("components.clients.client.services")}
          icon="balon"
        ></Title>
      )}
      {client.clientServices.length > 0 && (
        <div className={styles.products}>
          {client.clientServices.slice(0, 3).map((service, index) => (
            <div key={index}>
              <ServiceCard service={service} handleDelete={() => {}} />
              <div className={styles.rowWithHeight}></div>
            </div>
          ))}
        </div>
      )}
      {client.clientServices.length > 0 && (
        <div className={styles.rowShowBtn}>
          <div>
            <button
              className={styles.btnShowAll}
              onClick={() => navigate("/services-page/" + client.name)}
            >
              {t("components.clients.client.showAll")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
