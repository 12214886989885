import { FILTER_ROUTES, LOAD_ROUTES } from '../actions/route/route';
import { LOAD_ROUTE_CATEGORIES } from '../actions/route/routecategory';
import { Route } from '../model/route/route';
import { RouteCategory } from '../model/route/routeCategory';

export interface ApplicationState {
    routes: Route[],
    routeCategories : RouteCategory[]
}

const initialState: ApplicationState = {
    routes: [],
    routeCategories : []
};

const routeReducer = (state = initialState, { type, payload }: {type: any, payload: any}): any => {
  switch (type) {
    case LOAD_ROUTES:
      return {...state, routes: [...payload]};
    case FILTER_ROUTES:
      return {...state, routes: [...payload]};
    case LOAD_ROUTE_CATEGORIES:
      return {...state, routeCategories : [...payload]};
    default:
      return state;
  }
};

export default routeReducer;