import { productsRestService } from "../../rest/product/productsService";
import { Dispatch } from "redux";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const loadProductsPerPage = async (
  page: number,
  size: number,
  dispatch: Dispatch<any>
) => {
  const response = await productsRestService.findByPage(page, size);
  dispatch({ type: LOAD_PRODUCTS, payload: response.data });
  return Number(response.headers["x-total-page"]);
};

export const filterProducts = (
  onlyMy: boolean,
  categoryId: number | null,
  clientName: string,
  page: number,
  size: number
) => {
  return productsRestService.filterProducts(
    onlyMy,
    categoryId,
    clientName,
    page,
    size
  );
};

export const findProductById = (id: number, dispatch: Dispatch<any>) => {
  productsRestService.findById(id).then((response) => {
    dispatch({ type: SELECT_PRODUCT, payload: response.data });
  });
};

export const findProductsByClientId = (id: number) => {
  return productsRestService.findByIdClientId(id);
};

export const loadClientProductsPerPage =
  (id: number, page: number, size: number) => (dispatch: Dispatch<any>) => {
    return productsRestService.findByClientAndPage(id, page, size);
  };

export const existProductsForClient = (id: number) => {
  return productsRestService.existProductsForClient(id);
};

export const deleteProductDispatch = (id: number, dispatch: Dispatch<any>) => {
  dispatch({ type: DELETE_PRODUCT, payload: id });
};

export const deleteProduct = (id: number) => {
  return productsRestService.delete(id);
};

export const create = (data: FormData) => {
  return productsRestService.createProduct(data);
};

export const update = (data: FormData) => {
  return productsRestService.updateProduct(data);
};

export const getProductById = (id: number) => {
  return productsRestService.findById(id);
};
