import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Place } from "../../../../model/admin/place";
import styles from "./Places.module.css";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import {IoMdAddCircle} from "react-icons/io"

function PlacesTable(props: {places : Place[], setDeleteModalShow : (deleteModal : boolean) => void,
    setSelectedPlaceId : (placeId : number) => void}) {
    const {places, setDeleteModalShow, setSelectedPlaceId} = props;
    const navigate = useNavigate();
    const { t }  = useTranslation('all');

    return (
            <div className={styles.tableRow}>
                <Table bordered hover className={styles.table}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>{t("components.places.code")}</th>
                            <th>{t("components.places.name")} (en)</th>
                            <th>{t("components.places.name")} (sr)</th>
                            <th>{t("components.places.name")} (hr)</th>
                            <th style={{textAlign:"center"}}><IoMdAddCircle className={styles.icon} onClick={() => navigate("/places/edit/0")} size={25}></IoMdAddCircle></th>
                        </tr>
                    </thead>
                    <tbody>
                    {places.map((item: Place) => {
                        return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.name}</td>
                            <td>{item.nameSrb}</td>
                            <td>{item.nameCro}</td>
                            <td style={{textAlign:"center"}}>
                            <MdEdit className={styles.icon} onClick={() => navigate("/places/edit/" + item.id)} size={25}></MdEdit>
                            <TiDelete className={styles.icon} onClick={() => {setSelectedPlaceId(item.id);setDeleteModalShow(true)}} size={30} color="red" ></TiDelete>
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
    )
}

export default PlacesTable;