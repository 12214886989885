import {DefaultModel} from '../defaultModel'
import {File} from '../file/file'

export class News extends DefaultModel {
    title: string;
    titleSrb: string;
    titleCro: string;
    description: string;
    descriptionSrb: string;
    descriptionCro: string;
    creationDate: string;
    files : File[];
    videos : File[];
    constructor(){
        super();
        this.title = "";
        this.titleSrb = "";
        this.titleCro = "";
        this.description = "";
        this.descriptionSrb = "";
        this.descriptionCro = "";
        this.creationDate = "";
        this.files = [];
        this.videos = [];
    }
}