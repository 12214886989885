import { useEffect, useState } from "react";
import styles from "./News.module.css";
import NewsCard from "./news-card/NewsCard";
import { searchNewsPerPage } from "../../../actions/admin/news";
import { useDispatch, useSelector } from "react-redux";
import { News as NewsModel } from "../../../model/admin/news";
import { useTranslation } from "react-i18next";
import Arrows from "../../layouts/arrow/Arrows";
import SearchNews from "./search-news/SearchNews";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import { userHasRole } from "../../../utils/jwt";

function News(props: { size: number }) {
  const { t } = useTranslation("all");
  const { size } = props;
  const dispatch = useDispatch();
  const news = useSelector<any, NewsModel[]>((state) => state.news.news);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const isAdmin = userHasRole("admin");

  useEffect(() => {
    let isMounted = true;
    searchNewsPerPage(
      searchText,
      page,
      size
    )(dispatch).then((pages) => {
      if (isMounted) setLastPage(pages - 1);
    });
    return () => {
      isMounted = false;
    };
  }, [page, searchText, size]);

  return (
    <>
      <div className={styles.titleRow}>
        <TitleDarkLight titleDark={t("components.home.news")} titleLight={""} />
      </div>
      <div className={styles.newsContainer}>
        <div className={styles.newsContent}>
          <SearchNews
            page={page}
            setLastPage={setLastPage}
            size={size}
            searchText={searchText}
            setSearchText={setSearchText}
            canEdit={isAdmin}
          />
          <div className={styles.news}>
            {news.map((item, index) => (
              <NewsCard key={index} news={item} canEdit={isAdmin} />
            ))}
          </div>
          <div className={styles.arrowsCol}>
            <Arrows page={page} lastPage={lastPage} changePage={setPage} />
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
