import { DefaultModel } from "../defaultModel";
import { Product } from "../product/product";
import { User } from "./user";

export class ShoppingCartItem extends DefaultModel {
  amount: number;
  unitPrice?: number;
  addedAt?: Date;
  product: Product;

  constructor(product: Product, amount: number) {
    super();
    this.amount = amount;
    this.product = product;
  }
}

export enum ShoppingCartStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ORDERED = "ORDERED",
  CANCELLED = "CANCELLED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
}

export class ShoppingCart extends DefaultModel {
  totalPrice: number;
  orderedAt?: Date;
  items: ShoppingCartItem[];
  status: ShoppingCartStatus;
  user?: User;

  constructor() {
    super();
    this.totalPrice = 0;
    this.items = [];
    this.status = ShoppingCartStatus.IN_PROGRESS;
  }
}
