import { Form, InputGroup } from "react-bootstrap";
import styles from "./InfoForm.module.css";
import { Client } from "../../../../model/client/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Place } from "../../../../model/admin/place";
import { nameOf } from "../../../../utils/i18n";
import { ClientCategory } from "../../../../model/client/clientCategory";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { loadCategories } from "../../../../actions/client/clientCategory";
import { loadAllPlaces } from "../../../../actions/admin/place";
import { ClientType } from "../../../../model/client/clientType";
import { useDispatch, useSelector } from "react-redux";
import { loadClientTypes } from "../../../../actions/client/clientType";

function InfoForm(props: {
  client: Client;
  setClient: (client: Client) => void;
  register: UseFormRegister<FieldValues>;
  errors: any;
}) {
  const { client, setClient, register, errors } = props;
  const { t } = useTranslation("all");
  const [clientCategories, setClientCategories] = useState<ClientCategory[]>(
    []
  );
  const [places, setPlaces] = useState<Place[]>([]);

  const dispatch = useDispatch();
  const clientTypes = useSelector<any, ClientType[]>(
    (state) => state.clientTypes.clientTypes
  );

  useEffect(() => {
    loadClientTypes(dispatch);
    loadCategories().then((resp) => {
      setClientCategories(resp.data);
    });
    loadAllPlaces().then((resp) => {
      setPlaces(resp.data);
    });
  }, [dispatch]);

  return (
    <>
      <div className={styles.firstRow}>
        <Form style={{ width: "90%" }}>
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.address")}
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("address")}
                  value={client.address}
                  onChange={(e) =>
                    setClient({ ...client, address: e.target.value })
                  }
                />
              </Form.Group>
              {client.place && (
                <Form.Group className={styles.formGroupWithPadding}>
                  <Form.Label className={styles.title}>
                    {t("components.clients.edit.place")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("place")}
                    value={client.place.id}
                    onChange={(e) =>
                      setClient({
                        ...client,
                        place: places.find((p) => +e.target.value === p.id),
                      })
                    }
                  >
                    <option key={-1} value={0}></option>
                    {places.map((place, index) => (
                      <option key={index} value={place.id}>
                        {nameOf(place)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              {client.clientCategory && (
                <Form.Group className={styles.formGroupWithPadding}>
                  <Form.Label className={styles.title}>
                    {t("components.clients.edit.category")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("category")}
                    value={client.clientCategory.id}
                    onChange={(e) =>
                      setClient({
                        ...client,
                        clientCategory:
                          clientCategories.find(
                            (cc) => +e.target.value === cc.id
                          ) ?? client.clientCategory,
                      })
                    }
                  >
                    <option key={-1} value={0}></option>
                    {clientCategories.map((clientCategory, index) => (
                      <option key={index} value={clientCategory.id}>
                        {nameOf(clientCategory)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </InputGroup>
            <div className={styles.basicRow}>
              <div className={styles.errorMessage} style={{ width: "35%" }}>
                <div>{errors.address?.message}</div>
              </div>
              <div className={styles.errorMessage} style={{ width: "33%" }}>
                <div>{errors.place?.message}</div>
              </div>
              <div className={styles.errorMessage} style={{ width: "30%" }}>
                <div>{errors.category?.message}</div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
      <div className={styles.row}>
        <Form style={{ width: "90%" }}>
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.email")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={client.email}
                  onChange={(e) =>
                    setClient({ ...client, email: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className={styles.formGroupWithPadding}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.site")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={client.sitePath}
                  onChange={(e) =>
                    setClient({ ...client, sitePath: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className={styles.formGroupWithPadding}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.phone")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={client.phoneNumber}
                  onChange={(e) =>
                    setClient({ ...client, phoneNumber: e.target.value })
                  }
                />
              </Form.Group>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <div className={styles.row}>
        <Form style={{ width: "90%" }}>
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.facebook")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={client.facebookPath}
                  onChange={(e) =>
                    setClient({ ...client, facebookPath: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className={styles.formGroupWithPadding}>
                <Form.Label className={styles.title}>
                  {t("components.clients.edit.instagram")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={client.instagramPath}
                  onChange={(e) =>
                    setClient({ ...client, instagramPath: e.target.value })
                  }
                />
              </Form.Group>
              {client.type && (
                <Form.Group className={styles.formGroupWithPadding}>
                  <Form.Label className={styles.title}>
                    {t("components.clients.edit.type")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("type")}
                    value={client.type.id}
                    onChange={(e) =>
                      setClient({
                        ...client,
                        type:
                          clientTypes.find((ct) => +e.target.value === ct.id) ??
                          client.type,
                      })
                    }
                  >
                    <option key={-1} value={0}></option>
                    {clientTypes.map((clientType, index) => (
                      <option key={index} value={clientType.id}>
                        {nameOf(clientType)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default InfoForm;
