import { Dispatch } from "redux";
import { Review } from "../../model/client/review";
import { commentService } from "../../rest/client/commentService";

export const loadClientReviewsPerPage = (id: number, page: number, size: number) => {
    return commentService.findByClientAndPage(id,page,size);
}

export const addReview = (id: number, review: Review) => {
    return commentService.addReviewForClient(id, review);
}

export const existReviewsForClient = (id: number) => {
    return commentService.existReviewsForClient(id);
}

export const loadAllClientReviews = (id : number, page: number, size: number) => {
    return commentService.findAllByClientAndPage(id,page,size);
}

export const approveReview = (id: number) => {
    return commentService.approveReview(id);
}

export const hideReview = (id: number) => {
    return commentService.hideReview(id);
}