import {
  LOAD_CLIENT_SERVICES,
  SELECT_SERVICE,
  DELETE_SERVICE,
} from "../actions/client_service/clientService";
import { LOAD_SERVICE_CATEGORIES } from "../actions/client_service/serviceCategory";
import { ClientService } from "../model/client_service/clientService";
import { ServiceCategory } from "../model/client_service/serviceCategory";

export interface ApplicationState {
  clientServices: ClientService[];
  serviceCategories: ServiceCategory[];
  selectedService: ClientService;
}

const initialState: ApplicationState = {
  clientServices: [],
  serviceCategories: [],
  selectedService: new ClientService(),
};

const serviceReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case LOAD_CLIENT_SERVICES:
      return { ...state, clientServices: [...payload] };
    case LOAD_SERVICE_CATEGORIES:
      return { ...state, serviceCategories: [...payload] };
    case SELECT_SERVICE:
      return { ...state, selectedService: payload };
    case DELETE_SERVICE:
      return {
        ...state,
        clientServices: state.clientServices.filter((s) => s.id !== payload),
      };
    default:
      return state;
  }
};

export default serviceReducer;
