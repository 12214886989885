import styles from "./CategoryAndClient.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";
import { nameOf } from "../../../../utils/i18n";
import { Product } from "../../../../model/product/product";
import { ProductCategory } from "../../../../model/product/productCategory";
import { Client } from "../../../../model/client/client";
import { userHasRole } from "../../../../utils/jwt";
import { loadCategories } from "../../../../actions/product/productCategories";
import { getClients } from "../../../../actions/client/client";

export default function CategoryAndClient(props: {
  product: Product;
  setProduct: (product: Product) => void;
  register: any;
  errors: any;
}) {
  const { t } = useTranslation("all");
  const { product, setProduct, register, errors } = props;
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const setProductCategory = (id: number) => {
    const productCategory = categories.find((c) => id === c.id);
    if (productCategory) setProduct({ ...product, productCategory });
  };

  const setProductClient = (id: number) => {
    const client = clients.find((c) => id === c.id);
    if (client) setProduct({ ...product, client });
  };

  useEffect(() => {
    loadCategories().then((resp) => {
      setCategories(resp.data);
    });
    if (userHasRole("admin")) {
      getClients().then((resp) => {
        setClients(resp.data);
      });
    }
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div style={{ width: "100%", marginTop: "5vh" }}>
          <Form.Group className="mb-3">
            <InputGroup>
              {product.productCategory && (
                <Form.Group className="mb-3" style={{ width: "48%" }}>
                  <Form.Label className={styles.productTitle}>
                    {t("components.products.edit.category")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("category")}
                    value={product.productCategory.id}
                    onChange={(e) => setProductCategory(Number(e.target.value))}
                  >
                    <option key={-1} value={0}></option>
                    {categories.map((productCategory, index) => (
                      <option key={index} value={productCategory.id}>
                        {nameOf(productCategory)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              {product.client && userHasRole("admin") && (
                <Form.Group
                  className="mb-3"
                  style={{ width: "52%", paddingLeft: "2%" }}
                >
                  <Form.Label className={styles.productTitle}>
                    {t("components.products.edit.client")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("client")}
                    value={product.client.id}
                    onChange={(e) => setProductClient(Number(e.target.value))}
                  >
                    <option key={-1} value={0}></option>
                    {clients.map((client, index) => (
                      <option key={index} value={client.id}>
                        {nameOf(client)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <div className={styles.basicRow}>
        <div className={styles.errorMessage} style={{ width: "50%" }}>
          <div>{errors.category?.message}</div>
        </div>
        <div className={styles.errorMessage} style={{ width: "40%" }}>
          <div>{errors.client?.message}</div>
        </div>
      </div>
    </>
  );
}
