import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../../model/user/user";
import styles from "./EditUser.module.css";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { userRestService } from "../../../../rest/user/userService";
import { useForm } from "react-hook-form";
import { Role } from "../../../../model/user/role";
import { roleService } from "../../../../rest/user/roleService";
import { Client } from "../../../../model/client/client";
import { clientsRestService } from "../../../../rest/client/clientService";
import { nameOf } from "../../../../utils/i18n";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function UserForm() {
  const { id } = useParams();
  const { t } = useTranslation("all");
  const [user, setUser] = useState<User>(new User());
  const [roles, setRoles] = useState<Role[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [usernameExists, setUsernameExists] = useState(false);
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    username: Yup.string().required(
      t("components.users.edit.usernameRequired")
    ),
    email: Yup.string().required(t("components.users.edit.emailRequired")),
    password: Yup.string().test(
      "has text",
      t("components.users.edit.passwordRequired"),
      (value) => {
        if (Number(id) !== 0) return true;
        return user.password.length >= 8;
      }
    ),
    passwordConfirm: Yup.string().test(
      "has text",
      t("components.users.edit.passwordsMustMatch"),
      (value) => {
        return user.password === user.repeatedPassword;
      }
    ),
    role: Yup.number()
      .required(t("components.users.edit.roleRequired"))
      .min(1, t("components.users.edit.roleRequired")),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, setValue, formState } =
    useForm(validationOpt);
  const { errors } = formState;

  useEffect(() => {
    roleService.findAll().then((resp) => {
      setRoles(
        resp.data.filter(
          (role) => role.name !== "unreseted_user" && role.name !== "end_user"
        )
      );
    });
    if (Number(id) !== 0) {
      userRestService.findById(Number(id)).then((resp) => {
        setUser(resp.data);
        setValue("username", resp.data.username);
        setValue("email", resp.data.email);
        setValue("role", resp.data.role.id);
      });
    }
    clientsRestService.findAll().then((resp) => {
      setClients(resp.data);
    });
  }, []);

  const setSelectedRole = (id: number) => {
    setUser({
      ...user,
      role: roles.find((role) => role.id === id) ?? new Role(),
    });
  };

  const onSubmit = (data: any) => {
    let editedUser = user;
    editedUser.roles = [];
    editedUser.roles.push(editedUser.role.id);
    if (user.id === 0)
      userRestService.create(editedUser).then(() => {
        navigate("/users");
      });
    else
      userRestService
        .update(editedUser.id, editedUser)
        .then(() => {
          navigate("/users");
        })
        .catch(() => {
          setUsernameExists(true);
        });
  };

  return (
    <div className={styles.formRow}>
      <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.username")}</Form.Label>
          <Form.Control
            type="text"
            {...register("username")}
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
          <div className={styles.errorMessage}>{errors.username?.message}</div>
          <div className={styles.errorMessage}>
            {usernameExists && t("components.users.edit.usernameExists")}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.email")}</Form.Label>
          <Form.Control
            type="text"
            {...register("email")}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <div className={styles.errorMessage}>{errors.email?.message}</div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.password")}</Form.Label>
          <Form.Control
            type="password"
            {...register("password")}
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <div className={styles.errorMessage}>{errors.password?.message}</div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.repeatPassword")}</Form.Label>
          <Form.Control
            type="password"
            {...register("passwordConfirm")}
            value={user.repeatedPassword}
            onChange={(e) =>
              setUser({ ...user, repeatedPassword: e.target.value })
            }
          />
          <div className={styles.errorMessage}>
            {errors.passwordConfirm?.message}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.firstName")}</Form.Label>
          <Form.Control
            type="text"
            value={user.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.lastName")}</Form.Label>
          <Form.Control
            type="text"
            value={user.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("components.users.edit.role")}</Form.Label>
          {user && (
            <Form.Select
              {...register("role")}
              aria-label="Default select example"
              value={user.role.id}
              onChange={(e) => setSelectedRole(Number(e.target.value))}
            >
              <option key={-1} value={0}></option>
              {roles.map((item: Role, index: number) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          )}
          <div className={styles.errorMessage}>{errors.role?.message}</div>
        </Form.Group>
        {user && user.role.name === "client" && (
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.client")}</Form.Label>
            {user && (
              <Form.Select
                aria-label="Default select example"
                value={user.clientId}
                onChange={(e) =>
                  setUser({ ...user, clientId: Number(e.target.value) })
                }
              >
                <option key={-1} value={0}></option>
                {clients.map((client, index: number) => (
                  <option key={index} value={client.id}>
                    {nameOf(client)}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        )}
        <button
          className={styles.btnSave}
          type="submit"
          style={{ width: "25%", alignSelf: "center" }}
          onClick={() => setUsernameExists(false)}
        >
          {t("components.users.edit.save")}
        </button>
      </Form>
    </div>
  );
}

export default UserForm;
