import styles from "./NewsDetails.module.css";
import News from "../../home/news/News";
import { News as NewsModel } from "../../../model/admin/news";
import { useEffect, useState } from "react";
import Header from "../../layouts/header/Header";
import gradient from "../../../assets/styles/images/vesti/VESTI_GRADIENT.png";
import { useTranslation } from "react-i18next";
import { STATIC_HOST } from "../../../utils/communication";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import { useParams } from "react-router-dom";
import { newsService } from "../../../rest/admin/newsService";
import TitleWithShareButton from "../../layouts/title/TitleWithShareButton";
import DangerousDescription from "../../layouts/dangerous_description/DangerousDescription";
import PhotoGallery from "../../layouts/gallery/photo-gallery/PhotoGallery";
import VideoGallery from "../../layouts/gallery/video-gallery/VideoGallery";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import { descriptionOf, titleOf } from "../../../utils/i18n";

function NewsDetails() {
  const { t } = useTranslation("all");
  const [news, setNews] = useState<NewsModel>();
  let { id } = useParams();

  useEffect(() => {
    newsService.findById(Number(id)).then((resp) => {
      setNews(resp.data);
    });
  }, [id]);

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/vesti/VESTI_HEADER.png"}
        height={57.06914344685242}
        gradient={gradient}
        gradientHeight={24.664602683178536}
      />
      <div className={styles.sectionSingleNewsNews}>
        <div className={styles.singleNewsSection}>
          {news && (
            <>
              <TitleWithShareButton title={titleOf(news)} iconPath={""} />
              <div className={styles.descriptionContainer}>
                <DangerousDescription description={descriptionOf(news)} />
              </div>
            </>
          )}
          {news && news.files.length > 0 && (
            <>
              <div className={styles.mainPicture}>
                <img
                  className={styles.mainImg}
                  src={STATIC_HOST + news.files[0]}
                  alt=""
                />
              </div>
              <PhotoGallery picturePaths={news.files} />
            </>
          )}
          {news && news.videos.length > 0 && (
            <>
              <TitleDarkLight
                titleDark={t("components.clients.client.videoGaleryTitleDark")}
                titleLight={t(
                  "components.clients.client.videoGaleryTitleLight"
                )}
              ></TitleDarkLight>
              <VideoGallery videoPaths={news.videos} />
            </>
          )}
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
      <DownloadApp />
    </div>
  );
}

export default NewsDetails;
