import { useTranslation } from "react-i18next";
import { Product } from "../../model/product/product";
import { useEffect, useState } from "react";
import { ShoppingCartItem } from "../../model/user/shoppingCart";
import { useDispatch, useSelector } from "react-redux";
import AmountInput from "./AmountInput";
import {
  addItem,
  removeItem,
  updateItem,
} from "../../actions/user/shoppingCart";
import styles from "./AmountInput.module.css";

function ManageCartButtons(props: { product: Product }) {
  const { product } = props;
  const { t } = useTranslation("all");

  const dispatch = useDispatch();
  const shoppingCartItem = useSelector<any, ShoppingCartItem>((state) =>
    state.shoppingCart.shoppingCart.items.find(
      (item: ShoppingCartItem) => item.product.id === product.id
    )
  );
  const [amount, setAmount] = useState(
    shoppingCartItem ? shoppingCartItem.amount : 1
  );

  useEffect(
    () => setAmount(shoppingCartItem ? shoppingCartItem.amount : 1),
    [shoppingCartItem]
  );

  return (
    <div>
      <AmountInput
        value={amount}
        onChange={setAmount}
        min={1}
        max={product.availableAmount}
      />
      {shoppingCartItem ? (
        <>
          {shoppingCartItem.amount !== amount && (
            <div>
              <button
                type="button"
                className={styles.manageCartButton}
                onClick={() =>
                  updateItem({ ...shoppingCartItem, amount }, dispatch)
                }
              >
                {t("components.shoppingCart.updateCartItem")}
              </button>
            </div>
          )}
          <div>
            <button
              type="button"
              className={styles.manageCartButton}
              onClick={() => removeItem(shoppingCartItem.id, dispatch)}
            >
              {t("components.shoppingCart.removeCartItem")}
            </button>
          </div>
        </>
      ) : (
        <div>
          <button
            type="button"
            className={styles.manageCartButton}
            onClick={() =>
              addItem(new ShoppingCartItem(product, amount), dispatch)
            }
          >
            {t("components.shoppingCart.addCartItem")}
          </button>
        </div>
      )}
    </div>
  );
}

export default ManageCartButtons;
