import { Place } from "../../model/admin/place";
import { placeService } from "../../rest/admin/placeService"

export const loadAllPlaces = () => {
    return placeService.findAll();
}

export const findById = (id: number) => {
    return placeService.findById(id);
}

export const findAll = () => {
    return placeService.findAll();
}

export const create = (place: Place) => {
    return placeService.create(place);
}

export const update = (place : Place) => {
    return placeService.update(place.id, place);
}

export const deletePlace = (id : number) => {
    return placeService.delete(id);
}