import styles from "./DangerousDescription.module.css";
import DOMPurify from "dompurify";

function DangerousDescription(props: { description: string }) {
  const { description } = props;

  return (
    <div className={styles.descriptionSection}>
      <div>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />
      </div>
    </div>
  );
}

export default DangerousDescription;
