import { useParams } from "react-router-dom";
import styles from "./EditUser.module.css";
import { useTranslation } from "react-i18next";
import News from "../../../home/news/News";
import HeaderWithTxt from "../../../layouts/header/HeaderWithTxt";
import { STATIC_HOST } from "../../../../utils/communication";
import Title from "../../../layouts/title/Title";
import UserForm from "./UserForm";

function EditUser() {
  const { id } = useParams();
  const { t } = useTranslation("all");

  return (
    <div>
      <HeaderWithTxt
        background={STATIC_HOST + "front/home/header/HOME_HEADER_POZADINA.png"}
        height={84.10732714138287}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={32.6109391124871}
        title=""
        golden={false}
      ></HeaderWithTxt>
      <div className={styles.sectionUserNews}>
        <div className={styles.userSection}>
          <div className={styles.titleRow}>
            <Title
              title={
                Number(id) === 0
                  ? t("components.users.edit.titleCreate")
                  : t("components.users.edit.titleEdit")
              }
              icon="balon"
            ></Title>
          </div>
          <UserForm></UserForm>
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
    </div>
  );
}

export default EditUser;
