import { AbstractRestService } from "../abstractRestService";
import { User } from "../../model/user/user";
import { AxiosResponse } from "axios";
import http from "../../http-common";
import { RegistrationDto } from "../../dto/registrationDto";

export class UserRestService extends AbstractRestService<User> {
  constructor() {
    super("/api/users");
  }

  register(dto: RegistrationDto): Promise<AxiosResponse<User>> {
    return http.post(this.resourceUrl + "/register", dto);
  }

  findByPage(page: number, size: number): Promise<AxiosResponse<User[]>> {
    return http.get(this.resourceUrl + "/page", { params: { page, size } });
  }

  getCurrentUser(): Promise<AxiosResponse<User>> {
    return http.get(this.resourceUrl + "/me");
  }

  updateProfileInfo(user: User): Promise<AxiosResponse<User>> {
    return http.put(this.resourceUrl + "/me", user);
  }

  changeLanguage(lang: string) {
    return http.put(this.resourceUrl + `/change-language/${lang}`);
  }
}

export const userRestService = new UserRestService();
