import styles from "./ContactInfo.module.css";
import { Client } from "../../../../model/client/client";
import { useTranslation } from "react-i18next";
import { nameOf } from "../../../../utils/i18n";
import IKONICA_ADRESA from "../../../../assets/styles/icons/IKONICA_ADRESA.png";
import DIVIDER from "../../../../assets/styles/icons/DIVIDER.png";
import IKONICA_PHONE from "../../../../assets/styles/icons/IKONICA_PHONE.png";
import IKONICA_MAIL from "../../../../assets/styles/icons/IKONICA_MAIL.png";
import { ImFacebook2 } from "react-icons/im";
import { IoLogoInstagram } from "react-icons/io";
import { MdOutlineLanguage } from "react-icons/md";

export default function ContactInfo(props: { client: Client }) {
  const { client } = props;
  const { t } = useTranslation("all");

  return (
    <div className={styles.contactSection}>
      <div className={styles.addressCol}>
        <div className={styles.row}>
          <div style={{ marginLeft: "5%", width: "28px", height: "33px" }}>
            <img src={IKONICA_ADRESA} alt="" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t("components.clients.client.address")}
            </div>
            <div className={styles.contactContent}>
              {client.address}, {nameOf(client.place)},{" "}
              {nameOf(client.place?.country)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.phoneCol}>
        <div className={styles.row}>
          <div>
            <img
              src={DIVIDER}
              width="0.06510416666666667vw"
              height="80%"
              alt=""
            />
          </div>
          <div style={{ marginLeft: "30px", width: "28px", height: "33px" }}>
            <img src={IKONICA_PHONE} alt="" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t("components.clients.client.phone")}
            </div>
            <div className={styles.contactContent}>{client.phoneNumber}</div>
          </div>
        </div>
      </div>
      <div className={styles.emailCol}>
        <div className={styles.row}>
          <div>
            <img
              src={DIVIDER}
              width="0.06510416666666667vw"
              height="80%"
              alt=""
            />
          </div>
          <div style={{ marginLeft: "30px", width: "28px", height: "33px" }}>
            <img src={IKONICA_MAIL} alt="" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t("components.clients.client.email")}
            </div>
            <div className={styles.contactContent}>{client.email}</div>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "5%" }}></div>
      {client.sitePath && (
        <div className={styles.socialMediaCol}>
          <MdOutlineLanguage
            className={styles.btn}
            color="#03464b"
            size={30}
            onClick={() => window.open(client.sitePath)}
          ></MdOutlineLanguage>
        </div>
      )}
      {client.facebookPath && (
        <div className={styles.socialMediaCol}>
          <ImFacebook2
            className={styles.btn}
            color="#4267B2"
            size={30}
            onClick={() => window.open(client.facebookPath)}
          ></ImFacebook2>
        </div>
      )}
      {client.instagramPath && (
        <div className={styles.socialMediaCol}>
          <IoLogoInstagram
            className={styles.instagramBtn}
            color="white"
            size={30}
            onClick={() => window.open(client.instagramPath)}
          ></IoLogoInstagram>
        </div>
      )}
    </div>
  );
}
