import { Client } from "../client/client";
import { DefaultModel } from "../defaultModel";
import { File } from "../file/file";
import { ProductCategory } from "./productCategory";

export enum MeasurementUnit {
  KILOGRAM = "KILOGRAM",
  LITRE = "LITRE",
  PIECE = "PIECE",
}

export class Product extends DefaultModel {
  name: string;
  nameSrb: string;
  nameCro: string;
  description: string;
  descriptionSrb: string;
  descriptionCro: string;
  client: Client;
  files: File[];
  videos: File[];
  productCategory: ProductCategory;
  unit: MeasurementUnit;
  unitPrice: number;
  availableAmount: number;

  constructor() {
    super();
    this.name = "";
    this.nameSrb = "";
    this.nameCro = "";
    this.description = "";
    this.descriptionSrb = "";
    this.descriptionCro = "";
    this.client = {} as Client;
    this.files = [];
    this.videos = [];
    this.productCategory = {} as ProductCategory;
    this.unit = MeasurementUnit.PIECE;
    this.unitPrice = 0;
    this.availableAmount = 0;
  }
}
