import { LatLng } from "leaflet";
import {
  LOAD_ALL_CLIENTS,
  LOAD_CLIENTS,
  LOAD_COLLABORATORS,
  LOAD_USERS,
  DELETE_CLIENT,
  FILTER_CLIENTS,
  CREATE_CLIENT,
  SELECT_LOCATION,
  SELECT_CLIENT,
  UPDATE_CLIENT,
} from "../actions/client/client";
import { LOAD_CLIENT_SERVICES } from "../actions/client_service/clientService";
import { LOAD_PRODUCT_CATEGORIES } from "../actions/product/productCategories";
import { Client } from "../model/client/client";
import { ClientService } from "../model/client_service/clientService";
import { ProductCategory } from "../model/product/productCategory";

export interface ApplicationState {
  selectedLocation: LatLng;
  clientServices: ClientService[];
  productCategories: ProductCategory[];
  allClients: Client[];
  filteredClients: Client[];
  clients: Client[];
  collaborators: Client[];
  users: Client[];
  selectedClient: Client;
}

const initialState: ApplicationState = {
  selectedLocation: new LatLng(0, 0),
  clientServices: [],
  productCategories: [],
  allClients: [],
  filteredClients: [],
  clients: [],
  collaborators: [],
  users: [],
  selectedClient: {} as Client,
};

const clientsReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): ApplicationState => {
  switch (type) {
    case SELECT_LOCATION:
      return { ...state, selectedLocation: payload };
    case SELECT_CLIENT:
      return { ...state, selectedClient: payload };
    case UPDATE_CLIENT: {
      const client = state.clients.find((c) => c.id === payload.id);
      if (client) Object.assign(client, payload);
      return { ...state, clients: [...state.clients] };
    }
    case LOAD_ALL_CLIENTS:
      return { ...state, allClients: [...payload] };
    case LOAD_CLIENTS:
      return { ...state, clients: [...payload] };
    case LOAD_COLLABORATORS:
      return { ...state, collaborators: [...payload] };
    case LOAD_USERS:
      return { ...state, users: [...payload] };
    case LOAD_CLIENT_SERVICES:
      return { ...state, clientServices: payload };
    case LOAD_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload };
    case CREATE_CLIENT:
      return { ...state, clients: [...state.clients, payload] };
    case FILTER_CLIENTS:
      return { ...state, filteredClients: payload };
    case DELETE_CLIENT: {
      return {
        ...state,
        clients: state.clients.filter((c) => c.id !== payload),
      };
    }
    default:
      return state;
  }
};

export default clientsReducer;
