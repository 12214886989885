import styles from "./RoutesPage.module.css";
import News from "../../home/news/News";
import { Route } from "../../../model/route/route";
import { useEffect, useState } from "react";
import "../leaflet-legend.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RouteFilter } from "../../../filter/routeFilter";
import Header from "../../layouts/header/Header";
import { routesRestService } from "../../../rest/route/routeService";
import { STATIC_HOST } from "../../../utils/communication";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import FilterAndPagination from "./filter-pagination/FilterAndPagination";
import SelectedRoute from "./selected-route/SelectedRoute";
import PopularRoutes from "./popular-routes/PopularRoutes";
import FilteredRoutes from "./filtered-routes/FilteredRoutes";

export default function RoutesPage() {
  const { t } = useTranslation("all");
  const [routes, setRoutes] = useState<Route[]>([]);
  const [selectedRoute, setSelectedRoute] = useState<Route>();
  const [routeFilter, setRouteFilter] = useState(new RouteFilter());
  const [page, setPage] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    if (Number(id) !== 0) {
      routesRestService.getSharedRouteById(Number(id)).then((resp) => {
        setSelectedRoute(resp.data);
      });
    }
  }, []);

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/rute/RUTE_HEADER.png"}
        height={88}
        gradient={STATIC_HOST + "front/rute/GRADIENT_RUTE.png"}
        gradientHeight={25}
      />
      <div className={styles.sectionRouteNews}>
        <div className={styles.routeSection}>
          <TitleDarkLight
            titleDark={t("global.eatpannonia")}
            titleLight={t("components.routes.popularLight")}
          />
          <PopularRoutes
            setSelectedRoute={setSelectedRoute}
            setRoutes={setRoutes}
            page={page}
            routeFilter={routeFilter}
          />
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
      <div>
        <FilterAndPagination
          setRoutes={setRoutes}
          setSelectedRoute={setSelectedRoute}
          page={page}
          setPage={setPage}
          routeFilter={routeFilter}
          setRouteFilter={setRouteFilter}
        />
        <FilteredRoutes
          routes={routes}
          setRoutes={setRoutes}
          page={page}
          routeFilter={routeFilter}
          setSelectedRoute={setSelectedRoute}
        />
        {selectedRoute && <SelectedRoute selectedRoute={selectedRoute} />}
        <DownloadApp />
      </div>
    </div>
  );
}
