import styles from "./CategoryRateShare.module.css";
import { nameOf } from "../../../../utils/i18n";
import { Route } from "../../../../model/route/route";
import IKONICA_ZVEZDICA from "../../../../assets/styles/images/rute/IKONICA_ZVEZDICA.png";
import { FacebookIcon, FacebookShareButton } from "react-share";

export default function CategoryRateShare(props: { route: Route }) {
  const { route } = props;

  return (
    <div className={styles.rowWithHeight}>
      <div className={styles.categoryContainer}>
        <div className={styles.categoryTag}>
          <p className={styles.category}>{nameOf(route.routeCategory)}</p>
        </div>
      </div>

      <div className={styles.rateContainer}>
        <div className={styles.rateTag}>
          <div className={styles.starCol}>
            <img src={IKONICA_ZVEZDICA} width="100%" height="100%" alt="" />
          </div>
          <div className={styles.rate}>{route.rate}</div>
        </div>
      </div>
      <div className={styles.fbShareContainer}>
        <FacebookShareButton
          url={
            window.location.href.substring(0, window.location.href.length - 1) +
            route.id
          }
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={28} round />
        </FacebookShareButton>
      </div>
    </div>
  );
}
