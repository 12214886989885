import styles from "./PopularRoutes.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect, useState } from "react";
import "../../leaflet-legend.css";
import { getPopularRoutes } from "../../../../actions/route/route";
import RouteCard from "../../route-card/RouteCard";
import { routesRestService } from "../../../../rest/route/routeService";
import { RouteFilter } from "../../../../filter/routeFilter";

export default function PopularRoutes(props: {setSelectedRoute : (route : Route) => void, setRoutes : (routes : Route[]) => void
    routeFilter : RouteFilter, page : number}) {
  const {setSelectedRoute, setRoutes, routeFilter, page} = props;
  const [popularRoutes, setPopularRoutes] = useState<Route[]>([]);
  const size = 4;

  function filter() {
    routesRestService.filterRoutes(routeFilter,page,size).then((resp) => {
      setRoutes(resp.data);
    });
  }

  useEffect(() => {
    getPopularRoutes().then((resp) => {
      setPopularRoutes(resp.data);
    });
  }, []);

  return (
    <>
        {popularRoutes.length > 0 && (
            <div className={styles.routes}>
              {popularRoutes.map((item: Route, index: number) => (
                <RouteCard key={index} route={item} handleSelectRoute={setSelectedRoute} handleDelete={filter}/>
              ))}
            </div>
          )}
    </>
  );
}
