import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./Countries.module.css";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import {IoMdAddCircle} from "react-icons/io"
import { Country } from "../../../../model/admin/country";

function CountriesTable(props: {countries : Country[], setDeleteModalShow : (deleteModal : boolean) => void,
setSelectedCountryId : (countryId : number) => void}) {
    const {countries, setDeleteModalShow, setSelectedCountryId} = props;
    const navigate = useNavigate();
    const { t }  = useTranslation('all');

    return (
            <div className={styles.tableRow}>
                <Table bordered hover className={styles.table}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>{t("components.countries.code")}</th>
                            <th>{t("components.countries.name")} (en)</th>
                            <th>{t("components.countries.name")} (sr)</th>
                            <th>{t("components.countries.name")} (hr)</th>
                            <th style={{textAlign:"center"}}><IoMdAddCircle className={styles.icon} onClick={() => navigate("/countries/edit")} size={25}></IoMdAddCircle></th>
                        </tr>
                    </thead>
                    <tbody>
                    {countries.map((item: Country) => {
                        return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.name}</td>
                            <td>{item.nameSrb}</td>
                            <td>{item.nameCro}</td>
                            <td style={{textAlign:"center"}}>
                            <MdEdit className={styles.icon} onClick={() => navigate("/countries/edit/" + item.id)} size={25}></MdEdit>
                            <TiDelete className={styles.icon} onClick={() => {setSelectedCountryId(item.id);setDeleteModalShow(true)}} size={30} color="red" ></TiDelete>
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
    )
}

export default CountriesTable;