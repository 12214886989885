import {DefaultModel} from '../defaultModel'

export class Review extends DefaultModel {
    name : string;
    description: string;
    date: Date;
    approved: boolean;
    clientsUsernames : string[];

    constructor( name: string, description: string, date: Date){
        super();
        this.name = name;
        this.description = description;
        this.date = date;
        this.approved = false;
        this.clientsUsernames = [];
    }
}