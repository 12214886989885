import {DefaultModel} from '../defaultModel'
import {Client} from '../client/client'
import { RouteCategory } from './routeCategory';
import { User } from '../user/user';

export class Route extends DefaultModel {
    name : string;
    nameSrb : string;
    nameCro : string
    description: string;
    descriptionSrb: string;
    descriptionCro: string;
    routeCategory : RouteCategory;
    picturePath: string;
    rate: number;
    durationDays: number;
    geoJSON: string;
    clientInfo: Client[]
    creator: User;

    constructor(){
        super();
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
        this.description = "";
        this.descriptionSrb = "";
        this.descriptionCro = "";
        this.picturePath = "";
        this.rate = 0.0;
        this.durationDays = 0;
        this.geoJSON = "";
        this.clientInfo = []
        this.routeCategory = {} as RouteCategory;
        this.creator = {} as User;
    }
}