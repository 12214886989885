import { AbstractRestService } from "../abstractRestService";
import http from "../../http-common";
import { RouteRate } from "../../model/route/routeRate";

export class RouteRateService extends AbstractRestService<RouteRate> {
    constructor() {
      super('/api/route-rate');
    }  

    async rateRoute(routeId: number, rate : number): Promise<number>{
        const res = await http.post(this.resourceUrl + "/" + routeId + "/" + rate);
        return res.data;
    }

    async getMyRate(routeId: number): Promise<number>{
      const res = await http.get(this.resourceUrl + "/" + routeId);
      return res.data;
    }
}
export const routeRateService = new RouteRateService();