import React, { useEffect } from "react";
import ReactLoading from "react-loading";

function Loading() {
  return (
    <ReactLoading
      type={"spokes"}
      color={"#1c8693"}
      height={"10%"}
      width={"10%"}
    />
  );
}
export default Loading;
