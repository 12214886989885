import { AbstractRestService } from "../abstractRestService";
import { AxiosResponse } from "axios";
import http from "../../http-common";
import { Review } from "../../model/client/review";

export class CommentService extends AbstractRestService<Review> {
    constructor() {
      super('/api/review');
    }  

    findByClientAndPage(id: number, page: number, size: number) :Promise<AxiosResponse<Review[]>>{
      return http.get(this.resourceUrl + "/page/client/" + id, {params:{page:page, size:size}});
    }

    findAllByClientAndPage(id: number, page: number, size: number) :Promise<AxiosResponse<Review[]>>{
      return http.get(this.resourceUrl + "/page/client/all/" + id, {params:{page:page, size:size}});
    }

    async addReviewForClient(id: number, review: Review): Promise<number>{
        const res = await http.post(`${this.resourceUrl}/${id}`, review)
        return res.data;
    }

    existReviewsForClient(id: number) : Promise<AxiosResponse<boolean>>{
      return http.get(this.resourceUrl + "/exist/client/" + id);
    }

    approveReview(id: number) :Promise<AxiosResponse<Review[]>>{
      return http.put(this.resourceUrl + "/approve/" + id);
    }

    hideReview(id: number) :Promise<AxiosResponse<Review[]>>{
      return http.put(this.resourceUrl + "/hide/" + id);
    }
}

export const commentService = new CommentService();