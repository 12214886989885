import { useEffect, useState } from "react";
import News from "../../home/news/News";
import styles from "./ReviewsPage.module.css";
import { useTranslation } from "react-i18next";
import HeaderWithTxt from "../../layouts/header/HeaderWithTxt";
import { loadClientsWithNumOfComments } from "../../../actions/client/client";
import { Client } from "../../../model/client/client";
import { STATIC_HOST } from "../../../utils/communication";
import Title from "../../layouts/title/Title";
import Arrows from "../../layouts/arrow/Arrows";
import ReviewsTable from "./ReviewsTable";

function ReviewsPage() {
  const [clients, setClients] = useState<Client[]>([]);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const size = 10;
  const { t } = useTranslation("all");

  useEffect(() => loadClientsPerPage(), [page]);

  const loadClientsPerPage = () => {
    loadClientsWithNumOfComments(page, size).then((resp) => {
      setClients(resp.data);
      setLastPage(Number(resp.headers["x-total-page"]) - 1);
    });
  };

  return (
    <div>
      <HeaderWithTxt
        background={STATIC_HOST + "front/home/header/HOME_HEADER_POZADINA.png"}
        height={84}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={33}
        title=""
        golden={false}
      />
      <div className={styles.usersPage}>
        <div className={styles.sectionUsersNews}>
          <div className={styles.usersSection}>
            <Title
              title={t("components.navigation.header.reviews")}
              icon="balon"
            />
            <div className={styles.arrowsRow}>
              <Arrows page={page} lastPage={lastPage} changePage={setPage} />
            </div>
            <ReviewsTable clients={clients} />
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewsPage;
