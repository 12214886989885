import styles from "./DurationAndCategory.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect, useState } from "react";
import { RouteCategory } from "../../../../model/route/routeCategory";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { routeCategoryRestService } from "../../../../rest/route/routeCategoryService";

export default function DurationAndCategory(props: {route : Route, setRoute : (route : Route) => void, register : any, errors : any}) {
  const { t } = useTranslation("all");
  const {route, setRoute, register, errors} = props;
  const [routeCategories, setRouteCategories] = useState<RouteCategory[]>([]);

  const durationList = [
    { value: 1, text: "1 " + t("components.routes.day") },
    { value: 2, text: "2 " + t("components.routes.days") },
    { value: 3, text: "3 " + t("components.routes.days") },
    { value: 4, text: "4 " + t("components.routes.days") },
    { value: 5, text: "5 " + t("components.routes.days") },
    { value: 6, text: "6 " + t("components.routes.days") },
    { value: 7, text: "7 " + t("components.routes.days") },
    { value: 8, text: "8 " + t("components.routes.days") },
    { value: 9, text: "9 " + t("components.routes.days") },
    { value: 10, text: "10 " + t("components.routes.days") },
  ];

  const handleSelectCategory = (id: number) => {
    setRoute({...route,
      routeCategory: routeCategories.filter(function (r: RouteCategory) {
        return r.id === id;
      })[0],
    });
  };

  useEffect(() => {
    routeCategoryRestService.findAll().then((resp) => {
      setRouteCategories(resp.data);
    })
  }, []);

  return (
      <>
        <div className={styles.row}>
          <div className={styles.durationCol}>
            <div className={styles.routeName}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className={styles.title}>{t("components.routes.edit.duration")}</Form.Label>
                  <Form.Select style={{marginTop:"3vh"}}
                    aria-label="Default select example"
                    {...register('duration')}
                    value={route.durationDays}
                    onChange={(e) =>setRoute({...route,durationDays: Number(e.target.value)})}>
                    <option value={0}></option>
                    {durationList.map((item: any) => (
                      <option value={item.value}>{item.text}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className={styles.durationCol}>
            <div className={styles.routeName}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className={styles.title}>
                    {t("components.routes.edit.category")}
                  </Form.Label>
                  <Form.Select style={{marginTop:"3vh"}}
                    aria-label="Default select example"
                    value={route.routeCategory.id}
                    {...register('category')}
                    onChange={(e) => handleSelectCategory(Number(e.target.value))}>
                    <option value={0}></option>
                    {routeCategories.map((item: RouteCategory) => (
                      <option key={item.id} value={item.id}> {item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
        <div className={styles.basicRow}>
            <div className={styles.errorMessage} style={{width:"50%"}}><div>{errors.duration?.message}</div></div>
            <div className={styles.errorMessage} style={{width:"40%"}}><div>{errors.category?.message}</div></div>
        </div>
    </>
  );
}
