import http from "../../http-common";

export class MapBoxService {
    
  directionsUrl: string;
  constructor(){
      this.directionsUrl = "/api/map-box-service"
  };

  async getRoute(waypoints : any[]): Promise<string>{
    let waypointsString = "";
    if(waypoints){
        waypointsString = waypoints
        .map(waypoint => (waypoint.latitude && waypoint.longitude) ? `${waypoint.longitude},${waypoint.latitude}` : waypoint)
        .join(';');
      }
    let url = `${this.directionsUrl}?token=${process.env.REACT_APP_ACCESS_TOKEN_MAPBOX}&waypoints=${waypointsString}`;
    const response =  await http.get(url);

    return response.data && response.data.routes[0] ? response.data.routes[0].geometry : "";
  }
}

export const mapBoxService = new MapBoxService();