import { useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "./Form.module.css";
import { useTranslation } from "react-i18next";
import "../misc.css";
import { RegistrationDto } from "../../../dto/registrationDto";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { registration } from "../../../actions/user/user";

export default function RegisterForm() {
  const { t } = useTranslation("all");

  const navigate = useNavigate();

  const formSchema = yup.object({
    email: yup
      .string()
      .required(t("components.auth.register.txtEmailErrorRequired"))
      .email(t("components.auth.register.txtEmailErrorEmail")),
    password: yup
      .string()
      .required(t("components.auth.register.txtPasswordErrorRequired"))
      .min(8, t("components.auth.register.txtPasswordErrorMin")),
    repeatedPassword: yup
      .string()
      .required(t("components.auth.register.txtConfirmPasswordErrorRequired"))
      .oneOf(
        [yup.ref("password"), null],
        t("components.auth.register.txtConfirmPasswordErrorNotMatch")
      ),
  });

  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    repeatedPassword: "",
  });

  const [messageRegister, setMessageRegister] = useState("");

  const handleChangeNewUser = (e: any) => {
    const inputId = e.target.id;
    const inputValue = e.target.value;
    switch (inputId) {
      case "email":
        setNewUser({ ...newUser, email: inputValue });
        break;
      case "password":
        setNewUser({ ...newUser, password: inputValue });
        break;
      case "repeatedPassword":
        setNewUser({ ...newUser, repeatedPassword: inputValue });
        break;
    }
  };

  const registerErrors = (error: any) => {
    if (error === "ERRORS.USERNAME_EXISTS")
      return t("components.auth.register.usernameExistError");
    return t("components.auth.register.invalidRegister");
  };

  const handleSubmitRegister = async () => {
    registration(new RegistrationDto(newUser.email, newUser.password))
      .then(() => {
        navigate("/home");
      })
      .catch((mess: string) => {
        setMessageRegister(registerErrors(mess));
      });
  };

  const validationOptRegister = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOptRegister);
  const { errors } = formState;

  return (
    <div className={styles.formContainer}>
      <Form
        onSubmit={handleSubmit(handleSubmitRegister)}
        className={styles.form}
      >
        <Form.Group>
          <div className={styles.labelclass}>
            <Form.Label>{t("components.auth.register.email")}</Form.Label>
          </div>

          <Form.Control
            {...register("email")}
            id="email"
            className={styles.inputclass}
            autoFocus
            type="text"
            value={newUser.email}
            onChange={handleChangeNewUser}
          />
          <div className={styles.errorMessage}>{errors.email?.message}</div>
        </Form.Group>

        <Form.Group>
          <div className={`row ${styles.labelclass}`}>
            <Form.Label>{t("components.auth.register.password")}</Form.Label>
          </div>
          <Form.Control
            {...register("password")}
            id="password"
            type="password"
            className={styles.inputclass}
            value={newUser.password}
            onChange={handleChangeNewUser}
          />
          <div className={styles.errorMessage}>{errors.password?.message}</div>
        </Form.Group>
        <Form.Group>
          <div className={`row ${styles.labelclass}`}>
            <Form.Label>
              {t("components.auth.register.repeatPassword")}
            </Form.Label>
          </div>
          <Form.Control
            {...register("repeatedPassword")}
            id="repeatedPassword"
            type="password"
            className={styles.inputclass}
            value={newUser.repeatedPassword}
            onChange={(e) => handleChangeNewUser(e)}
          />
          <div className={styles.errorMessage}>
            {errors.repeatedPassword?.message}
          </div>
        </Form.Group>
        {messageRegister && (
          <div className={styles.messageContainer}>
            <p className={styles.errorMessage}>{messageRegister}</p>
          </div>
        )}
        <div className={styles.submitBtnContainer}>
          <button
            name="action"
            value="login"
            type="submit"
            className={styles.btnSubmit}
          >
            <p className={styles.txtSubmit}>
              {t("components.auth.register.signUp")}
            </p>
          </button>
        </div>
      </Form>
    </div>
  );
}
