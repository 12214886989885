import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ShoppingCart, ShoppingCartItem } from "../../model/user/shoppingCart";
import { nameOf } from "../../utils/i18n";
import { useEffect, useState } from "react";
import {
  getActiveCart,
  makeOrder,
  removeItem,
  updateItem,
  updateWithLocalCart,
} from "../../actions/user/shoppingCart";
import { NavLink } from "react-router-dom";
import { userLoggedIn } from "../../utils/jwt";
import AmountInput from "./AmountInput";
import styles from "./AmountInput.module.css";

function ManageCartButtons(props: { shoppingCartItem: ShoppingCartItem }) {
  const { shoppingCartItem } = props;
  const { t } = useTranslation("all");

  const dispatch = useDispatch();
  const [amount, setAmount] = useState(shoppingCartItem.amount);

  useEffect(() => setAmount(shoppingCartItem.amount), [shoppingCartItem]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AmountInput
        value={amount}
        onChange={setAmount}
        min={1}
        max={shoppingCartItem.product.availableAmount}
      />
      {shoppingCartItem.amount !== amount && (
        <button
          type="button"
          className={styles.manageCartButton}
          onClick={() => updateItem({ ...shoppingCartItem, amount }, dispatch)}
        >
          {t("components.shoppingCart.updateCartItem")}
        </button>
      )}
      <button
        type="button"
        className={styles.manageCartButton}
        onClick={() => removeItem(shoppingCartItem.id, dispatch)}
      >
        {t("components.shoppingCart.removeCartItem")}
      </button>
    </div>
  );
}

export function ShoppingCartModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { t } = useTranslation("all");
  const shoppingCart = useSelector<any, ShoppingCart>(
    (state) => state.shoppingCart.shoppingCart
  );
  const dispatch = useDispatch();
  const loggedIn = userLoggedIn();

  useEffect(() => {
    if (loggedIn) updateWithLocalCart(dispatch);
    getActiveCart(dispatch);
  }, [dispatch, loggedIn]);

  const handleMakeOrder = () => {
    makeOrder(shoppingCart.id, dispatch)
      .then((success) => {
        if (success) props.onHide();
      })
      .catch(() => {
        alert(t("components.shoppingCart.missingUserData"));
      });
  };

  const totalPrice = shoppingCart.items.reduce(
    (total, item) => total + item.amount * item.product.unitPrice,
    0
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("components.shoppingCart.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <tbody>
            {shoppingCart.items.map((item, i) => (
              <tr key={i} style={{ padding: 2 }}>
                <td style={{ paddingRight: 3 }}>
                  {item.amount} {t("components.units." + item.product.unit)}{" "}
                  <NavLink to={"/product/" + item.product.id}>
                    {nameOf(item.product)}
                  </NavLink>
                  {item.amount > item.product.availableAmount && (
                    <div style={{ color: "red", paddingLeft: 10 }}>
                      {t("components.shoppingCart.amountWarning")} (
                      {item.product.availableAmount})
                    </div>
                  )}
                </td>
                <td>
                  <ManageCartButtons shoppingCartItem={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <b>
            {t("components.shoppingCart.total")}: {totalPrice} din
          </b>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {shoppingCart.items.length > 0 && (
          <Button variant="secondary" onClick={handleMakeOrder}>
            {t("components.shoppingCart.makeOrder")}
          </Button>
        )}
        <Button variant="secondary" onClick={props.onHide}>
          {t("components.shoppingCart.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
