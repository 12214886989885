import { useState, useEffect } from "react";
import { filterClients } from "../../../actions/client/client";
import { connect, useDispatch } from "react-redux";
import styles from "./FilterSection.module.css";
import { ProductCategory } from "../../../model/product/productCategory";
import { loadServiceCategories } from "../../../actions/client_service/serviceCategory";
import { loadProductCategories } from "../../../actions/product/productCategories";
import { loadClientCategories } from "../../../actions/client/clientCategory";
import { ServiceCategory } from "../../../model/client_service/serviceCategory";
import { ClientCategory } from "../../../model/client/clientCategory";
import { useTranslation } from "react-i18next";
import Multiselect from "multiselect-react-dropdown";
import { nameField } from "../../../utils/i18n";

function FilterSection(props: {
  clientCategories: ClientCategory[];
  serviceCategories: ServiceCategory[];
  productCategories: ProductCategory[];
}) {
  const { t } = useTranslation("all");
  const { clientCategories, serviceCategories, productCategories } = props;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{
    clientCategories: number[];
    productCategories: number[];
    serviceCategories: number[];
  }>({ clientCategories: [], productCategories: [], serviceCategories: [] });

  useEffect(() => {
    loadClientCategories(dispatch);
    loadServiceCategories(dispatch);
    loadProductCategories(dispatch);
  }, []);

  useEffect(() => {
    filterClients(filters, dispatch);
  }, [filters]);

  const onSelectClientCategory = (
    selectedList: ClientCategory[],
    _selectedItem: ClientCategory
  ) => {
    setFilters({ ...filters, clientCategories: selectedList.map((c) => c.id) });
  };

  const onSelectClientService = (
    selectedList: ServiceCategory[],
    _selectedItem: ServiceCategory
  ) => {
    setFilters({
      ...filters,
      serviceCategories: selectedList.map((c) => c.id),
    });
  };

  const onSelectProductCategory = (
    selectedList: ProductCategory[],
    _selectedItem: ProductCategory
  ) => {
    setFilters({
      ...filters,
      productCategories: selectedList.map((c) => c.id),
    });
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterTitle}>
        <div className={styles.blueLineLeft}></div>
        <div className={styles.selectClientContainer}>
          <p className={styles.selectClientTxt}>
            {t("components.home.selectClient")}
          </p>
        </div>
        <div className={styles.blueLineRight}></div>
      </div>
      <div className={styles.filterBox}>
        <div className={styles.filterCategoryBox}>
          <div className={styles.filterBoxSection1}>
            <div className={styles.filterBoxNameContainer}>
              <p className={styles.filterBoxName}>
                {t("components.home.category")}
              </p>
            </div>
            <div className={styles.filterBoxImgContainer}>
              <div className={styles.filterBoxImg}></div>
            </div>
          </div>
          <div className={styles.filterBoxSection2}>
            <Multiselect
              style={{
                chips: { background: "#197d89" },
                searchBox: {
                  borderRadius: "0.5vw",
                  backgroundColor: "white",
                },
              }}
              placeholder={t("components.home.filterServices")}
              options={clientCategories}
              onSelect={onSelectClientCategory}
              onRemove={onSelectClientCategory}
              displayValue={nameField()}
            />
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.filterCategoryBox}>
          <div className={styles.filterBoxSection1}>
            <div className={styles.filterBoxNameContainer}>
              <p className={styles.filterBoxName}>
                {t("components.home.product")}
              </p>
            </div>
            <div className={styles.filterBoxImgContainer}>
              <div className={styles.filterBoxImg}></div>
            </div>
          </div>
          <div className={styles.filterBoxSection2}>
            <Multiselect
              style={{
                chips: { background: "#197d89" },
                searchBox: {
                  borderRadius: "0.5vw",
                  backgroundColor: "white",
                },
              }}
              placeholder={t("components.home.filterCategories")}
              options={productCategories}
              onSelect={onSelectProductCategory}
              onRemove={onSelectProductCategory}
              displayValue={nameField()}
            />
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.filterCategoryBox}>
          <div className={styles.filterBoxSection1}>
            <div className={styles.filterBoxNameContainer}>
              <p className={styles.filterBoxName}>
                {t("components.home.service")}
              </p>
            </div>
            <div className={styles.filterBoxImgContainer}>
              <div className={styles.filterBoxImg}></div>
            </div>
          </div>
          <div className={styles.filterBoxSection2}>
            <Multiselect
              style={{
                chips: { background: "#197d89" },
                searchBox: {
                  borderRadius: "0.5vw",
                  backgroundColor: "white",
                },
              }}
              placeholder={t("components.home.filterServices")}
              options={serviceCategories}
              onSelect={onSelectClientService}
              onRemove={onSelectClientService}
              displayValue={nameField()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  clientCategories: state.clientCategories.clientCategories,
  serviceCategories: state.services.serviceCategories,
  productCategories: state.products.productCategories,
});

export default connect(mapStateToProps)(FilterSection);
