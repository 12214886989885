import { routesRestService } from "../../rest/route/routeService";
import { Dispatch } from "redux";
import { Route } from "../../model/route/route";

export const LOAD_ROUTES = "LOAD_ROUTES"
export const FILTER_ROUTES = "FILTER_ROUTES"

export const loadRoutes = (dispatch: Dispatch<any>) => {
    routesRestService.findAll()
    .then(response => {
        dispatch({ type: LOAD_ROUTES, payload : response.data })})
}

export const getAllRoutes = () => {
    return routesRestService.findAll();
}

export const getPopularRoutes = () => {
    return routesRestService.findPopularRoutes();
}

export const createRoute = (data : FormData) => {
    return routesRestService.createRoute(data);
}

export const updateRoute = (data: FormData) => {
    return routesRestService.updateRoute(data);
}

export const getRouteById = (id : number) => {
    return routesRestService.findById(id);
}

export const deleteRoute = (id: number) => {
    return routesRestService.delete(id);
}
