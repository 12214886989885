import styles from "./Comments.module.css";
import { useEffect, useState } from "react";
import { Review } from "../../../../model/client/review";
import {
  loadAllClientReviews,
  loadClientReviewsPerPage,
} from "../../../../actions/client/review";
import NewComment from "./new-comment/NewComment";
import { useTranslation } from "react-i18next";
import NAVODNICI_IKONICA from "../../../../assets/styles/icons/NAVODNICI_IKONICA.png";
import CommentCard from "./comment-card/CommentCard";
import { getUserFromLocalStorage } from "../../../../utils/jwt";
import Arrows from "../../../layouts/arrow/Arrows";
import { Client } from "../../../../model/client/client";

export default function Comments(props: { client: Client }) {
  const { client } = props;
  const { t } = useTranslation("all");
  const [comments, setComments] = useState<Review[]>([]);
  const [page, setPage] = useState(0);
  const [numberOfComments, setNumberOfComments] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const currentUser = getUserFromLocalStorage();
  const canApprove =
    currentUser !== null &&
    (currentUser.roles.includes("admin") ||
      (currentUser.roles.includes("client") &&
        client.users.some((u) => u.username === currentUser.sub)));

  useEffect(() => {
    getComments();
  }, [page]);

  const getComments = async () => {
    if (canApprove) {
      loadAllReviews();
    } else {
      loadApprowedReviews();
    }
  };

  const loadAllReviews = () => {
    loadAllClientReviews(client.id, page, 10)
      .then((response) => {
        setComments(response.data);
        setLastPage(Number(response.headers["x-total-page"]) - 1);
        setNumberOfComments(Number(response.headers["x-total-count"]));
      })
      .catch(() => {
        loadApprowedReviews();
      });
  };

  const loadApprowedReviews = () => {
    loadClientReviewsPerPage(client.id, page, 10).then((response) => {
      setComments(response.data);
      setLastPage(Number(response.headers["x-total-page"]) - 1);
      setNumberOfComments(Number(response.headers["x-total-count"]));
    });
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.numberOfComments}>
          {t("components.clients.client.numberOfComments")}:{" "}
        </div>
        <div className={styles.number}>{numberOfComments}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.newCommentContainer}>
          <NewComment id={client.id} />
        </div>
        <div style={{ marginLeft: "2.6vw" }}>
          <img src={NAVODNICI_IKONICA} alt="" />
        </div>
      </div>
      {comments.length > 0 && (
        <>
          <div className={styles.arrowsRow}>
            <Arrows page={page} lastPage={lastPage} changePage={setPage} />
          </div>
          <div id="commentSection" style={{ marginTop: "5vh" }}>
            {comments.map((review, index) => (
              <div key={index}>
                <CommentCard
                  review={review}
                  handleToggleReview={getComments}
                  canApprove={canApprove}
                />
                <div className={styles.rowWithHeight}></div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className={styles.row} style={{ height: "10vh" }}></div>
    </>
  );
}
