import { nameOf } from "../../../utils/i18n";
import HeaderWithTxt from "./HeaderWithTxt";
import { Client } from "../../../model/client/client";
import { STATIC_HOST } from "../../../utils/communication";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function HeaderByClientCategory(props: { client: Client }) {
  const { client } = props;
  const { t } = useTranslation();

  const frontUri = STATIC_HOST + "/front/proizvodi/";

  const [background, setBackground] = useState("RESTORANI_HEADER.png");
  const [gradient, setGradient] = useState("GRADIENT_RESTORANI.png");

  useEffect(() => {
    let bg =
      client?.products?.length > 0 && client.products[0].files?.length > 0
        ? client.products[0].files[0].path
        : client?.clientServices?.length > 0 &&
          client.clientServices[0].files?.length > 0
        ? client.clientServices[0].files[0].path
        : client?.files?.length > 0
        ? client.files[0].path
        : null;
    if (bg) {
      setBackground(STATIC_HOST + bg);
    } else {
      let code = client?.clientCategory?.code;
      if (code === "DEST") {
        setBackground(frontUri + "DESTILERIJE_HEADER.png");
        setGradient("GRADIENT_DESTILERIJE.png");
      } else if (code === "MED") {
        setBackground(frontUri + "MED_HEADER.png");
        setGradient("GRADIENT_MED.png");
      } else if (code === "PIV") {
        setBackground(frontUri + "PIVARE_HEADER.png");
        setGradient("GRADIENT_PIVARE.png");
      } else if (code === "SUHO") {
        setBackground(frontUri + "SUHOMESNATI_HEADER.png");
        setGradient("GRADIENT_SUHOMESNATI.png");
      } else if (code === "SLA") {
        setBackground(frontUri + "SLATKO_I_KOLACI_HEADER.png");
        setGradient("GRADIENT_SLATKO_I_KOLACI.png");
      } else {
        setBackground(frontUri + "RESTORANI_HEADER.png");
        setGradient("GRADIENT_RESTORANI.png");
      }
    }
  }, []);

  return (
    <HeaderWithTxt
      title={nameOf(client)}
      golden={client.type?.code === "S"}
      background={background}
      height={59.33952528379773}
      gradient={frontUri + gradient}
      gradientHeight={24.664602683178536}
    />
  );
}
