import styles from "./Title.module.css";
import { Client } from "../../../../model/client/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { nameOf } from "../../../../utils/i18n";
import { AiFillLike } from "react-icons/ai";
import { clientLikeService } from "../../../../rest/client/clientLikeService";
import IKONICA_ZVEZDICA from "../../../../assets/styles/images/rute/IKONICA_ZVEZDICA.png";
import { getIconByCode } from "../../../../utils/getIcon";

export default function Title(props: { client: Client }) {
  const [client, setClient] = useState(props.client);
  const { t } = useTranslation("all");
  const [liked, setLiked] = useState(false);

  const likeClient = () => {
    clientLikeService.addLikeForClient(client.id).then((resp) => {
      setClient({ ...client, numberOfLikes: resp });
    });
  };

  useEffect(() => {
    clientLikeService.haveILiked(client.id).then((resp) => setLiked(resp));
  }, [client.id]);

  return (
    <div className={styles.sectionTitle}>
      <div className={styles.titleContainer}>
        <div className={styles.iconImg}>
          <img src={getIconByCode(client.clientCategory.code)} alt="" />
        </div>
        <div className={styles.blueColorDark}>
          {t("components.clients.client.aboutUsDark")}
        </div>
        <div style={{ width: "1vw" }}></div>
        <div className={styles.blueColorLight}>
          {t("components.clients.client.aboutUsLight")}
        </div>
        <div className={styles.categoryCol}>
          {nameOf(client.clientCategory)}
        </div>
        <div className={styles.likeCol}>
          <AiFillLike
            size={25}
            style={{ marginBottom: "0.4vh" }}
            className={liked ? styles.likedBtn : styles.likeBtn}
            onClick={() => {
              likeClient();
              setLiked(true);
            }}
          ></AiFillLike>{" "}
          {client.numberOfLikes}
        </div>
        <div className={styles.rateCol}>
          <div className={styles.row}>
            <img className={styles.imgStar} src={IKONICA_ZVEZDICA} alt="" />
            <div className={styles.rate}>{props.client.rate}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
