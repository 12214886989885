import styles from "./Title.module.css";

export default function FilterTitle(props: { title: string; style?: any }) {
  return (
    <div className={styles.filterTitle} style={props.style}>
      <div className={styles.blueLineLeft}></div>
      <div>{props.title}</div>
      <div className={styles.blueLineRight}></div>
    </div>
  );
}
