import styles from "./RouteCard.module.css";
import { useState } from "react";
import { Route } from "../../../model/route/route";
import { DeleteModal } from "./DeleteModal";
import image from "../../../assets/styles/images/BALON_LOGO.png";
import { STATIC_HOST } from "../../../utils/communication";
import ClientCategories from "./client-categories/ClientCategories";
import CategoryRateShare from "./category-rate-share/CategoryRateShare";
import Title from "./title/Title";

export default function RouteCard(props: {
  route: Route;
  handleSelectRoute: (route: Route) => void;
  handleDelete: () => void;
}) {
  const { route, handleDelete } = props;
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  return (
    <div className={styles.routeCardContainer}>
      <div className={styles.routeCard}>
        <div
          className={styles.imgRoute}
          onClick={() => props.handleSelectRoute(route)}
        >
          <img
            src={
              route.picturePath == undefined
                ? image
                : STATIC_HOST + route.picturePath
            }
            width="100%"
            height="100%"
            style={{ borderRadius: "5%" }}
            alt="Route"
          />
        </div>
        <CategoryRateShare route={route}></CategoryRateShare>
        <Title route={route} setDeleteModalShow={setDeleteModalShow}></Title>
        <ClientCategories route={route}></ClientCategories>
      </div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        routeId={route.id}
        handleDelete={handleDelete}
      />
    </div>
  );
}
