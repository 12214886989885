import { useTranslation } from "react-i18next";
import styles from "./PriceAndAmount.module.css";
import { Form, InputGroup } from "react-bootstrap";
import { MeasurementUnit } from "../../../../model/product/product";

export default function PriceAndAmount(props: {
  price: number;
  setPrice: (price: number) => void;
  amount: number;
  setAmount: (amount: number) => void;
  unit: MeasurementUnit;
  setUnit: (unit: MeasurementUnit) => void;
  register: any;
  errors: any;
}) {
  const {
    price,
    setPrice,
    amount,
    setAmount,
    unit,
    setUnit,
    register,
    errors,
  } = props;
  const { t } = useTranslation("all");

  return (
    <div className={styles.row} style={{ marginTop: "2vh" }}>
      <div style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <Form.Label className={styles.title}>
            {t("components.products.edit.unitPrice")}
          </Form.Label>
          <InputGroup style={{ marginTop: "3vh" }}>
            <div style={{ width: "1vw" }}></div>
            <Form.Control
              type="number"
              step={0.01}
              min={0}
              {...register("price")}
              value={price}
              onChange={(e) => setPrice(+e.target.value)}
            />
          </InputGroup>
          <div className={styles.errorMessage}>{errors.price?.message}</div>
        </Form.Group>
        <InputGroup style={{ width: "100%" }}>
          <Form.Group
            className="mb-3"
            style={{ width: "50%", paddingRight: "2%" }}
          >
            <Form.Label className={styles.title}>
              {t("components.products.edit.availableAmount")}
            </Form.Label>
            <InputGroup style={{ marginTop: "3vh" }}>
              <div style={{ width: "1vw" }}></div>
              <Form.Control
                type="number"
                step={0.001}
                min={0}
                {...register("amount")}
                value={amount}
                onChange={(e) => setAmount(+e.target.value)}
              />
            </InputGroup>
            <div className={styles.errorMessage}>{errors.amount?.message}</div>
          </Form.Group>
          <Form.Group className="mb-3" style={{ width: "50%" }}>
            <Form.Label className={styles.title}>
              {t("components.products.edit.unit")}
            </Form.Label>
            <Form.Select
              style={{ marginTop: "3vh" }}
              {...register("unit")}
              value={unit}
              onChange={(e) =>
                setUnit(
                  MeasurementUnit[
                    e.target.value as keyof typeof MeasurementUnit
                  ]
                )
              }
            >
              {Object.keys(MeasurementUnit).map((mu) => (
                <option key={mu} value={mu}>
                  {t("components.units." + mu)}
                </option>
              ))}
            </Form.Select>
            <div className={styles.errorMessage}>{errors.unit?.message}</div>
          </Form.Group>
        </InputGroup>
      </div>
    </div>
  );
}
