import {DefaultModel} from '../defaultModel'

export class File extends DefaultModel {
    name: string;
    path : string
    fileType: string;
    orderNum: number
    constructor(newName: string, newPath : string, newFileType: string, newOrderNum : number){
        super();
        this.name = newName;
        this.path = newPath;
        this.fileType = newFileType;
        this.orderNum = newOrderNum;
    }
}