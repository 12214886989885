import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findById } from "../../../../actions/admin/place";
import { Place } from "../../../../model/admin/place";
import styles from "./EditPlace.module.css";
import { useTranslation } from "react-i18next";
import News from "../../../home/news/News";
import { Form } from "react-bootstrap";
import { Country } from "../../../../model/admin/country";
import { countryService } from "../../../../rest/admin/countryService";
import { placeService } from "../../../../rest/admin/placeService";
import { nameOf } from "../../../../utils/i18n";
import { useForm, SubmitHandler } from "react-hook-form";
import HeaderWithTxt from "../../../layouts/header/HeaderWithTxt";
import Title from "../../../layouts/title/Title";
import { STATIC_HOST } from "../../../../utils/communication";

type Inputs = {
  nameRequired: string;
  nameSrRequired: string;
  nameHrRequired: string;
  codeRequired: string;
  countryRequired: string;
};

function EditPlace() {
  const { id } = useParams();
  const [place, setPlace] = useState<Place>(new Place());
  const [countries, setCountries] = useState<Country[]>([]);
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();

  useEffect(() => {
    countryService.findAll().then((resp) => {
      setCountries(resp.data);
    });
  }, []);

  useEffect(() => {
    if (!Number(id)) return;
    findById(Number(id)).then((resp) => {
      setPlace(resp.data);
      setValue("nameRequired", resp.data.name);
      setValue("nameSrRequired", resp.data.nameSrb);
      setValue("nameHrRequired", resp.data.nameCro);
      setValue("codeRequired", resp.data.code);
      setValue("countryRequired", resp.data.country.id.toString());
    });
  }, [id]);

  const setSelectedCountry = (id: number) => {
    const country = countries.find((c) => c.id === id);
    if (country) setPlace({ ...place, country });
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (place.id === undefined)
      placeService.create(place).then(() => {
        navigate("/places");
      });
    else
      placeService.update(place.id, place).then(() => {
        navigate("/places");
      });
  };

  return (
    <div>
      <HeaderWithTxt
        background={STATIC_HOST + "front/home/header/HOME_HEADER_POZADINA.png"}
        height={84.10732714138287}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={32.6109391124871}
        title=""
        golden={false}
      ></HeaderWithTxt>
      <div className={styles.sectionPlaceNews}>
        <div className={styles.placeSection}>
          <div className={styles.titleRow}>
            <Title
              title={
                Number(id) === 0
                  ? t("components.places.edit.titleCreate")
                  : t("components.places.edit.titleEdit")
              }
              icon="balon"
            ></Title>
          </div>
          <div className={styles.formRow}>
            <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.places.edit.name")} (en)</Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameRequired", { required: true })}
                  value={place.name}
                  onChange={(e) => setPlace({ ...place, name: e.target.value })}
                />
                {errors.nameRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.places.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.places.edit.name")} (sr)</Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameSrRequired", { required: true })}
                  value={place.nameSrb}
                  onChange={(e) =>
                    setPlace({ ...place, nameSrb: e.target.value })
                  }
                />
                {errors.nameSrRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.places.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.places.edit.name")} (hr)</Form.Label>
                <Form.Control
                  type="text"
                  {...register("nameHrRequired", { required: true })}
                  value={place.nameCro}
                  onChange={(e) =>
                    setPlace({ ...place, nameCro: e.target.value })
                  }
                />
                {errors.nameHrRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.places.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.places.edit.code")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("codeRequired", { required: true })}
                  value={place.code}
                  onChange={(e) => setPlace({ ...place, code: e.target.value })}
                />
                {errors.codeRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.places.error.required")}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("components.places.edit.country")}</Form.Label>
                {place && (
                  <Form.Select
                    {...register("countryRequired", { min: 1 })}
                    aria-label="Default select example"
                    value={place.country.id}
                    onChange={(e) => setSelectedCountry(Number(e.target.value))}
                  >
                    <option key={-1} value={0}></option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.id}>
                        {nameOf(country)}
                      </option>
                    ))}
                  </Form.Select>
                )}
                {errors.countryRequired && (
                  <span className={styles.errorMessage}>
                    {t("components.places.error.required")}
                  </span>
                )}
              </Form.Group>
              <button
                className={styles.btnSave}
                type="submit"
                style={{ width: "25%", alignSelf: "center" }}
              >
                {t("components.places.edit.save")}
              </button>
            </Form>
          </div>
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
    </div>
  );
}

export default EditPlace;
