import styles from "./Title.module.css";
import BALON_ICON from "../../../assets/styles/icons/BALON_IKONICA.png";
import COMMENT_ICON from "../../../assets/styles/icons/IKONICA_KOMENTAR.png";

function Title(props: { title: string; icon: string }) {
  const { title, icon } = props;
  const iconPath =
    icon === "balon" ? BALON_ICON : icon === "comment" ? COMMENT_ICON : "";
  return (
    <div className={styles.title}>
      <div className={styles.icon}>
        <img src={iconPath}></img>
      </div>
      <div className={styles.titleStyle}>
        <p>{title}</p>
      </div>
    </div>
  );
}

export default Title;
