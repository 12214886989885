import { type FC, useState, useCallback, useEffect } from "react";
import styles from "./AmountInput.module.css";

type IntegerInputProps = {
  value: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
};

export const IntegerInput: FC<IntegerInputProps> = ({
  value,
  onChange,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleDecrementClick = useCallback(() => {
    const newValue = Math.max(min, inputValue - 1);
    setInputValue(newValue);
    onChange(newValue);
  }, [inputValue, min, onChange]);

  const handleIncrementClick = useCallback(() => {
    const newValue = Math.min(max, inputValue + 1);
    setInputValue(newValue);
    onChange(newValue);
  }, [inputValue, max, onChange]);

  useEffect(() => setInputValue(value), [value]);

  return (
    <div className={styles.inputContainer}>
      <button
        type="button"
        className={styles.circleButton}
        onClick={handleDecrementClick}
        disabled={inputValue <= min}
      >
        -
      </button>
      <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        {inputValue}
      </span>
      <button
        type="button"
        className={styles.circleButton}
        onClick={handleIncrementClick}
        disabled={inputValue >= max}
      >
        +
      </button>
    </div>
  );
};

export default IntegerInput;
