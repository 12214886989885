import styles from "./Clients.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../model/client/client";
import ClientCard from "../../client/client-card/ClientCard";
import Arrows from "../../layouts/arrow/Arrows";
import { loadClientsByTypePerPage } from "../../../actions/client/client";

const clientTypeMap = {
  S: "collaborators",
  K: "users",
  P: "clients",
};

function Clients(props: { type: "S" | "K" | "P" }) {
  const { type } = props;
  const dispatch = useDispatch();
  const clients = useSelector<any, Client[]>(
    (state) => state.clients[clientTypeMap[type]]
  );
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const pageSize = 5;

  useEffect(() => {
    let isMounted = true;
    loadClientsByTypePerPage(type, page, pageSize, dispatch).then((pages) => {
      if (isMounted) setLastPage(pages - 1);
    });
    return () => {
      isMounted = false;
    };
  }, [page, type]);

  return (
    <>
      <div className={styles.rowArrows}>
        <div className={styles.colArrows}>
          <Arrows page={page} lastPage={lastPage} changePage={setPage} />
        </div>
      </div>
      <div className={styles.sectionClientsContent}>
        <div className={styles.clientsContent}>
          <div className={styles.clients}>
            {clients.map((client, index) => (
              <ClientCard key={index} item={client} handleDelete={() => {}} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
