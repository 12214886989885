import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import countriesReducer from "./countriesReducer";
import authReducer from "./authReducer";
import clientsReducer from "./clientReducer";
import newsReducer from "./newsReducer";
import productReducer from "./productReducer";
import serviceReducer from "./serviceReducer";
import routeReduces from "./routeReducer";
import clientCategoryReducer from "./clientCategoryReducer";
import clientTypeReducer from "./clientTypeReducer";
import shoppingCartReducer from "./shoppingCartReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  countries: countriesReducer,
  clients: clientsReducer,
  news: newsReducer,
  products: productReducer,
  services: serviceReducer,
  routes: routeReduces,
  clientCategories: clientCategoryReducer,
  clientTypes: clientTypeReducer,
  shoppingCart: shoppingCartReducer,
});

const middleware = composeWithDevTools(applyMiddleware(thunk));

export default createStore(rootReducer, middleware);
