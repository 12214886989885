import styles from "./ServiceCard.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { nameOf } from "../../../utils/i18n";
import { getUsernameFromLocalStorage, userHasRole } from "../../../utils/jwt";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { DeleteModal } from "./DeleteModal";
import { ClientService } from "../../../model/client_service/clientService";
import imageCard from "../../../assets/styles/images/BALON_LOGO.png";
import { STATIC_HOST } from "../../../utils/communication";

function ServiceCard(props: {
  service: ClientService;
  handleDelete: () => void;
}) {
  const { service, handleDelete } = props;
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const navigate = useNavigate();
  const currentUsername = getUsernameFromLocalStorage();

  return (
    <div className={styles.serviceCardContainer}>
      <div className={styles.serviceCard}>
        <Link to={"/service/" + service.id} className={styles.link}>
          <img
            className={styles.imgService}
            src={
              service.files.length > 0
                ? STATIC_HOST + service.files[0].path
                : imageCard
            }
            style={{ borderRadius: "5%" }}
            alt="Service"
          />
        </Link>
        <div className={styles.row}>
          <div className={styles.categoryInTitle}>
            <p className={styles.category}>{nameOf(service.serviceCategory)}</p>
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.titleCol}>
            <p className={styles.serviceTitle}>{nameOf(service)}</p>
          </div>
          {(userHasRole("admin") ||
            service.client.users?.some(
              (user) => user.username === currentUsername
            )) && (
            <>
              <div className={styles.iconCol} style={{ alignSelf: "center" }}>
                <MdEdit
                  className={styles.deleteIcon}
                  onClick={() => navigate("/edit-service/" + service.id)}
                  size={25}
                  style={{ paddingTop: "5%" }}
                />
              </div>
              <div className={styles.iconCol} style={{ alignSelf: "center" }}>
                <TiDelete
                  className={styles.deleteIcon}
                  onClick={() => setDeleteModalShow(true)}
                  size={30}
                  color="red"
                  style={{ paddingTop: "2.5%" }}
                />
              </div>
            </>
          )}
        </div>
        <DeleteModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          serviceId={service.id}
          setClient={() => {}}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
}

export default ServiceCard;
