import DESTILERIJA_IKONICA from "../assets/styles/images/rute/DESTILERIJA_IKONICA.png";
import MED_IKONICA from "../assets/styles/images/rute/MED_IKONICA.png";
import PIVARA_IKONICA from "../assets/styles/images/rute/PIVARA_IKONICA.png";
import SLATKO_IKONICA from "../assets/styles/images/rute/SLATKO_IKONICA.png";
import SUHO_IKONICA from "../assets/styles/images/rute/SUHO_IKONICA.png";
import RESTORAN_IKONICA from "../assets/styles/images/rute/RESTORAN_IKONICA.png";
import BALON_IKONICA from "../assets/styles/icons/BALON_IKONICA.png";

export const getIconByCode = (code: string) => {
  switch (code) {
    case "DEST":
      return DESTILERIJA_IKONICA;
    case "MED":
      return MED_IKONICA;
    case "PIV":
      return PIVARA_IKONICA;
    case "SUHO":
      return SUHO_IKONICA;
    case "SLA":
      return SLATKO_IKONICA;
    case "REST":
      return RESTORAN_IKONICA;
    default:
      return BALON_IKONICA;
  }
};
