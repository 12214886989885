import styles from "./ClientCard.module.css";
import { Link, useNavigate } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import imageCard from "../../../assets/styles/images/BALON_LOGO.png";
import { useState } from "react";
import { DeleteModal } from "./DeleteModal";
import { MdEdit } from "react-icons/md";
import { getUsernameFromLocalStorage, userHasRole } from "../../../utils/jwt";
import { nameOf } from "../../../utils/i18n";
import { Client } from "../../../model/client/client";
import IKONICA_ZVEZDICA from "../../../assets/styles/images/rute/IKONICA_ZVEZDICA.png";
import { STATIC_HOST } from "../../../utils/communication";
import ClientLogo from "./ClientLogo";

function ClientCard(props: { item: Client; handleDelete: () => void }) {
  const { item: client, handleDelete } = props;
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const navigate = useNavigate();
  const url = "/client-details-page/" + client.id;
  const currentUsername = getUsernameFromLocalStorage();
  const isAdmin = userHasRole("admin");

  return (
    <div className={styles.clientCardContainer}>
      <div className={styles.clientCard}>
        <Link to={url} className={styles.link}>
          <ClientLogo
            background={
              client.logo
                ? STATIC_HOST + client.logo
                : client.files.length > 0
                ? STATIC_HOST + client.files[0].path
                : imageCard
            }
            golden={client.type.code === "S"}
          />
        </Link>
        <div className={styles.rowBetween}>
          <div className={styles.categoryContainer}>
            <div className={styles.categoryTag}>
              <p className={styles.category}>{nameOf(client.clientCategory)}</p>
            </div>
          </div>
          <div className={styles.rateContainer}>
            <div className={styles.rateTag}>
              <div className={styles.starCol}>
                <img src={IKONICA_ZVEZDICA} width="100%" height="100%" alt="" />
              </div>
              <div className={styles.rate}>{client.rate}</div>
            </div>
          </div>
        </div>
        <div className={styles.clientTitleContainer}>
          <div className={styles.col}>
            <p className={styles.clientTitle}>{nameOf(client)}</p>
          </div>
          {(isAdmin ||
            client.users?.some(
              (user) => user.username === currentUsername
            )) && (
            <div className={styles.deleteIconCol}>
              <MdEdit
                className={styles.deleteIcon}
                onClick={() => navigate("/edit-client/" + client.id)}
                size={25}
                style={{ paddingTop: "5%" }}
              ></MdEdit>
            </div>
          )}
          {isAdmin && (
            <div className={styles.deleteIconCol}>
              <TiDelete
                className={styles.deleteIcon}
                onClick={() => setDeleteModalShow(true)}
                size={30}
                color="red"
                style={{ paddingTop: "2.5%" }}
              ></TiDelete>
            </div>
          )}
        </div>
      </div>

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        clientId={client.id}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ClientCard;
