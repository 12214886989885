import styles from "./ViewAllButton.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ViewAllButton(props : {page : string}) {
  const { t } = useTranslation("all");
  const navigate = useNavigate();

  const handleViewProducts = () => {
    navigate(props.page);
  };

  return (
    <div className={styles.viewAllContainer}>
        <button name="action" onClick={handleViewProducts} className={styles.btnViewAll}>
            <p className={styles.txtViewAll}>{t("global.viewAll")}</p>
        </button>
    </div>
  );
}
