import styles from "./Title.module.css";
import BALON_ICON from "../../../assets/styles/icons/BALON_IKONICA.png";

function TitleDarkLightMini(props: { titleDark: string; titleLight: string }) {
  const { titleDark, titleLight } = props;
  return (
    <div className={styles.titleMini}>
      <div className={styles.iconMini}>
        <img src={BALON_ICON}></img>
      </div>
      <div className={styles.titleStyleDarkMini}>{titleDark}</div>
      <div className={styles.titleStyleLightMini}>{titleLight}</div>
    </div>
  );
}

export default TitleDarkLightMini;
