import styles from "./SelectedRoute.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect, useState } from "react";
import "../../leaflet-legend.css";
import { useTranslation } from "react-i18next";
import BALON_ICON from "../../../../assets/styles/icons/BALON_IKONICA.png";
import { descriptionOf } from "../../../../utils/i18n";
import DOMPurify from "dompurify";
import IKONICA_ZVEZDICA from "../../../../assets/styles/images/rute/IKONICA_ZVEZDICA.png";
import { routeRateService } from "../../../../rest/route/routeRateService";
import { FacebookIcon, FacebookShareButton } from "react-share";
import Map from "../map/Map";
import { Toast, ToastContainer } from "react-bootstrap";
import Rating from "../../../../Rating/Rating";

export default function SelectedRoute(props: { selectedRoute: Route }) {
  const { t } = useTranslation("all");
  const { selectedRoute } = props;
  const [selectedRate, setSelectedRate] = useState(0);
  const [showRateToast, setShowRateToast] = useState(false);

  const rateRoute = (newRate: number) => {
    if (selectedRoute?.id) {
      routeRateService.rateRoute(selectedRoute.id, newRate).then((resp) => {
        setShowRateToast(true);
      });
    }
  };

  useEffect(() => {
    routeRateService.getMyRate(selectedRoute.id).then((resp) => {
      setSelectedRate(resp);
    });
  }, [selectedRoute.id]);

  return (
    <>
      <Map selectedRoute={selectedRoute}></Map>
      <ToastContainer className="p-3" style={{ marginLeft: "40%" }}>
        <Toast
          show={showRateToast}
          onClose={() => setShowRateToast(false)}
          delay={1500}
          autohide
        >
          <Toast.Body>
            <div>{t("components.routes.rateSuccess")}</div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className={styles.title}>
        <div className={styles.icon}>
          <img src={BALON_ICON} alt="" />
        </div>
        <div className={styles.routeNameTitle}>
          <div className={styles.blueColorDark}>{selectedRoute?.name}</div>
        </div>
        <div className={styles.rateCol}>
          <div className={styles.row}>
            <img
              src={IKONICA_ZVEZDICA}
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
              alt=""
            />
            <div className={styles.rate}>{selectedRoute?.rate}</div>
          </div>
        </div>
        <div className={styles.duration}>
          <div className={styles.blueColorLight}>
            {t("components.routes.duration")} : {selectedRoute?.durationDays}{" "}
            {t("components.routes.days")}
          </div>
        </div>
        <div className={styles.starsCol}>
          {t("components.routes.rate")} :
          {selectedRoute && (
            <Rating
              ratingValue={selectedRate}
              size={25}
              fillColor="orange"
              emptyColor="gray"
              onClick={rateRoute}
            />
          )}
        </div>
        <div className={styles.facebookCol}>
          <FacebookShareButton
            url={
              window.location.href.substring(
                0,
                window.location.href.length - 1
              ) + selectedRoute?.id
            }
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
      </div>
      <div className={styles.descriptionSection}>
        {selectedRoute && (
          <p
            className={styles.descriptionCurrent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(descriptionOf(selectedRoute)),
            }}
          />
        )}
      </div>
    </>
  );
}
