import { authService, LoginRequest } from "../../rest/auth/authService";
import { Dispatch } from "react";
import { AccessTokenContainer } from "../../dto/accessTokenContainer";

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGOUT = "LOGOUT";

interface APiError {
  message: string;
}

export const login = (user: LoginRequest) => (dispatch: Dispatch<any>) => {
  return authService.login(user).then(
    (data: string) => {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { token: data },
      });
      return Promise.resolve();
    },
    (error: any) => {
      const message = error.response.data.message;
      dispatch({
        type: LOGIN_USER_FAIL,
      });

      return Promise.reject(message);
    }
  );
};

export const googleLogin =
  (access: AccessTokenContainer) => (dispatch: Dispatch<any>) => {
    return authService.googleLogin(access).then(
      (data: string) => {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: { token: data },
        });
        return Promise.resolve();
      },
      (error: APiError) => {
        const message = error.message;
        dispatch({
          type: LOGIN_USER_FAIL,
        });

        return Promise.reject(message);
      }
    );
  };

export const facebookLogin =
  (access: AccessTokenContainer) => (dispatch: Dispatch<any>) => {
    return authService.facebookLogin(access).then(
      (data: string) => {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: { token: data },
        });
        return Promise.resolve();
      },
      (error: APiError) => {
        const message = error.message;
        dispatch({
          type: LOGIN_USER_FAIL,
        });

        return Promise.reject(message);
      }
    );
  };

export const logout = () => (dispatch: Dispatch<any>) => {
  authService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const logoutWithDispatch = (dispatch: Dispatch<any>) => {
  authService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const forgotPassword =
  (username: string) => (dispatch: Dispatch<any>) => {
    return authService.forgotPassword(username).then(
      () => {
        return Promise.resolve();
      },
      (error: any) => {
        const message = error.response.data.message;
        return Promise.reject(message);
      }
    );
  };
