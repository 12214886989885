import styles from "./RateAndShare.module.css";
import { Client } from "../../../../model/client/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clientRateService } from "../../../../rest/client/clientRateService";
import Rating from "../../../../Rating/Rating";
import CustomShare from "../../../share/CustomShare";


export default function RateAndShare(props: {
  client: Client;
  handleRateUpdate: (newRate: number) => void;
  setShowRateToast: (show: boolean) => void;
}) {
  const { client, handleRateUpdate, setShowRateToast } = props;
  const { t } = useTranslation("all");
  const [rate, setRate] = useState(0);

  useEffect(() => {
    clientRateService.getMyRate(client.id).then((resp) => {
      setRate(resp);
    });
  }, [client.id]);

  const rateClient = (newRate: number) => {
    clientRateService.rateClient(client.id, newRate).then((resp) => {
      setShowRateToast(true);
      setRate(newRate); 
      handleRateUpdate(resp.rate);
    });
  };

  return (
    <div className={styles.row}>
      <div className={styles.ratingCol}>
        {t("components.clients.client.rate")} :
        <Rating
          ratingValue={rate}
          size={23}
          fillColor="blue"
          emptyColor="gray"
          onClick={rateClient}
        />
      </div>
      <CustomShare></CustomShare>
    </div>
  );
}
