import styles from "./ServicesPage.module.css";
import ServiceCard from "../service-card/ServiceCard";
import News from "../../home/news/News";
import { ClientService } from "../../../model/client_service/clientService";
import { filterServices } from "../../../actions/client_service/clientService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../layouts/header/Header";
import { STATIC_HOST } from "../../../utils/communication";
import FilterAndPagination from "./filter-pagination/FilterAndPagination";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import FilterTitle from "../../layouts/title/FilterTitle";

export default function ServicesPage() {
  const { t } = useTranslation("all");
  const [services, setServices] = useState<ClientService[]>([]);
  const pageSize = 15;
  const [page, setPage] = useState(0);

  const handleDelete = () => {
    filterServices(false, 0, "", page, pageSize).then((resp) => {
      setServices(resp.data);
    });
  };

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/usluge/USLUGE_HEADER_POZADINA.png"}
        height={86.06811145510837}
        gradient={STATIC_HOST + "front/usluge/GRADIENT.png"}
        gradientHeight={24.664602683178536}
      />
      <div className={styles.servicesPage}>
        <div className={styles.sectionServicesNews}>
          <div className={styles.servicesSection}>
            <TitleDarkLight
              titleDark={t("components.services.titleDark")}
              titleLight={t("components.services.titleLight")}
            ></TitleDarkLight>
            <FilterTitle
              title={t("components.services.filter.title")}
            ></FilterTitle>
            <FilterAndPagination
              page={page}
              setPage={setPage}
              setServices={setServices}
            ></FilterAndPagination>
            <div className={styles.services}>
              {services.map((service, index) => (
                <div key={index}>
                  <ServiceCard service={service} handleDelete={handleDelete} />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
