import { userRestService } from "../rest/user/userService";
import I18n from "./i18n";
import { userLoggedIn } from "./jwt";

export function setLanguage(langCode: string) {
  I18n.changeLanguage(langCode);
  localStorage.setItem("languageCode", langCode);
}

export function changeLanguage(langCode: string) {
  if (langCode === localStorage.getItem("languageCode")) return;
  setLanguage(langCode);
  if (userLoggedIn()) userRestService.changeLanguage(langCode);
}
