import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/login/Login";
import ClientDetailsPage from "./components/client/clientDetails/ClientDetailsPage";
import ClientsPage from "./components/client/clients-page/ClientsPage";
import EditClient from "./components/client/edit/EditClient";
import Home from "./components/home/Home";
import ProductsPage from "./components/product/products-page/ProductsPage";
import SingleProduct from "./components/product/productDetails/ProductPage";
import SingleService from "./components/service/serviceDetails/ServicePage";
import ServicesPage from "./components/service/services-page/ServicesPage";
import { PrivateRoute } from "./route/PrivateRoute";
import NewsDetails from "./components/news/news_details/NewsDetails";
import EmailValidated from "./components/auth/email_validated/EmailValidated";
import EditRoute from "./components/route/edit/EditRoute";
import EditProduct from "./components/product/edit/EditProduct";
import EditService from "./components/service/edit/EditService";
import EditNews from "./components/news/edit/EditNews";
import PlacesPage from "./components/administration/place/preview/Places";
import EditPlace from "./components/administration/place/edit/EditPlace";
import UsersPage from "./components/administration/user/preview/Users";
import EditUser from "./components/administration/user/edit/EditUser";
import ResetNewPassword from "./components/auth/resetPassword/reset-new-password/ResetNewPassword";
import ResetForgottenPassword from "./components/auth/resetPassword/reset-forgotten-password/ResetForgottenPassword";
import ReviewsPage from "./components/administration/review/ReviewsPage";
import RoutesPage from "./components/route/routes-page/RoutesPage";
import Countries from "./components/administration/country/preview/Countries";
import EditCountry from "./components/administration/country/edit/EditCountry";
import { isMobile } from "react-device-detect";
import MobilePage from "./components/mobile-page/mobilePage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
      {isMobile && (
        <HashRouter>
          <Routes>
            <Route path="/validation" element={<EmailValidated />} />
            <Route path="*" element={<MobilePage />}></Route>
          </Routes>
        </HashRouter>
      )}
      {!isMobile && (
        <HashRouter>
          <Routes>
            <Route
              path="/countries"
              element={<PrivateRoute roles={["admin"]} />}
            >
              <Route path="/countries" element={<Countries />} />
              <Route path="/countries/edit/:id" element={<EditCountry />} />
              <Route path="/countries/edit" element={<EditCountry />} />
            </Route>
            <Route path="/places" element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/places" element={<PlacesPage />} />
              <Route path="/places/edit/:id" element={<EditPlace />} />
            </Route>
            <Route path="/users" element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/edit/:id" element={<EditUser />} />
            </Route>
            <Route path="/reviews" element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/reviews" element={<ReviewsPage />} />
            </Route>
            <Route
              path="/reset-password"
              element={<PrivateRoute roles={["unreseted_user"]} />}
            >
              <Route path="/reset-password" element={<ResetNewPassword />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />}></Route>
            <Route path="/clients-page" element={<ClientsPage />} />
            <Route path="/edit-client/:id" element={<EditClient />} />
            <Route path="/products-page" element={<ProductsPage />} />
            <Route
              path="/products-page/:clientName"
              element={<ProductsPage />}
            />
            <Route path="/edit-product/:id" element={<EditProduct />} />
            <Route path="/services-page" element={<ServicesPage />} />
            <Route
              path="/services-page/:clientName"
              element={<ServicesPage />}
            />
            <Route path="/edit-news/:id" element={<EditNews />} />
            <Route path="/edit-service/:id" element={<EditService />} />
            <Route path="/routes/:id" element={<RoutesPage />} />
            <Route path="/edit-route/:id" element={<EditRoute />} />
            <Route path="/validation" element={<EmailValidated />} />
            <Route
              path="/client-details-page/:id"
              element={<ClientDetailsPage />}
            />
            <Route path="/product/:id" element={<SingleProduct />} />
            <Route path="/service/:id" element={<SingleService />} />
            <Route path="/news/:id" element={<NewsDetails />} />
            <Route
              path="/reset-forgotten-password"
              element={<ResetForgottenPassword />}
            />
          </Routes>
          <div className="footer">
            <div className="footerContactContainer">
              <p className="footerContact">GOURMET CENTAR VRDNIK</p>
              <p className="footerContact">Mikice Lesjaka 28, Vrdik</p>
              <p className="footerContact">Info@gourmet-vrdnik.com</p>
              <p className="footerContact">www.gourmet-vrdnik.com</p>
            </div>
          </div>
        </HashRouter>
      )}
    </>
  );
}

export default App;
