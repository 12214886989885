import styles from "./Map.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect } from "react";
import "../../leaflet-legend.css";
import { Icon } from "leaflet";
import { getCustomMarker } from "../../../marker/CustomMarker";
import DOMPurify from "dompurify";
import { STATIC_HOST } from "../../../../utils/communication";

var L = require("leaflet");
var map: any;
var polyUtil = require("polyline-encoded");

export default function Map(props: {selectedRoute : Route}) {
    const {selectedRoute} = props;

  function deleteMapLayers() {
    if (map != undefined) {
      map.eachLayer(function (layer: any) {
        map.removeLayer(layer);
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
    }
  }

  function handleSetCurrentRoute(route: Route) {
    deleteMapLayers();

    for (let i = 0; i < route.clientInfo.length; i++) {
      let marker = L.marker(
        [
          route.clientInfo[i].location.latitude,
          route.clientInfo[i].location.longitude,
        ],
        {
          icon: new Icon({
            iconUrl: getCustomMarker(route.clientInfo[i].clientCategory.code),
            iconAnchor: [12, 43],
          }),
        }
      );
      let popupContent = '<div style="height: 20vh; width: 20vh; margin-left:17.5%;"><img width="100%" height="100%" src="' 
      + STATIC_HOST + route.clientInfo[i].logo +
      '"/></div>' + '<p style="font-family: mulish-light; color: #696969; font-size: large; font-weight: bold;"'
      + styles.clientName + '>' + route.clientInfo[i].name + '</p>' + 
      '<div style="overflow: hidden; max-height: 15.63983488132095vh;"><p style="font-family: mulish-light; color: #696969; font-size: large;" dangerouslySetInnerHTML =__html:' 
      + DOMPurify.sanitize(route.clientInfo[i].description) +'>' + '</p></div>'
      marker.bindPopup(popupContent);
      marker.addTo(map);
    }

    var latlngs = [];
    if(route.geoJSON !== ""){
      latlngs = polyUtil.decode(route.geoJSON);
    }else{
      for(let cl of route.clientInfo){
        latlngs.push([cl.location.latitude, cl.location.longitude]);
      }
    }
    let polyline = L.polyline(latlngs).addTo(map);
    let featureGroup = L.featureGroup([polyline]);
    map.fitBounds(featureGroup.getBounds());
  }

  useEffect(() => {
    map = L.map("map", {
      center: [45.2396, 19.8227],
      zoom: 8,
    });
    handleSetCurrentRoute(selectedRoute);
  }, []);

  useEffect(() => {
    handleSetCurrentRoute(selectedRoute);
  }, [selectedRoute]);

  return (
        <div className={styles.mapSection} style={{marginTop:"5vh"}}>
          <div
            className={styles.map}
            id="map"
            style={{ width: "100%", height: "100%" }}
          ></div>
        </div>
  );
}
