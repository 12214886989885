import { NavLink, useNavigate } from "react-router-dom";
import styles from "./NavDesktop.module.css";
import { useDispatch } from "react-redux";
import { Dropdown, Nav, NavDropdown } from "react-bootstrap";
import "./Legend.css";
import { logoutWithDispatch } from "../../../actions/auth/auth";
import { FaShoppingCart, FaUserAlt } from "react-icons/fa";
import {
  getUsernameFromLocalStorage,
  userHasRole,
  userLoggedIn,
} from "../../../utils/jwt";
import { changeLanguage } from "../../../utils/changeLanguage";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ShoppingCartModal } from "../../shopping-cart/ShoppingCartModal";
import { EditProfileModal } from "../../administration/user/profile/EditProfileModal";

export default function NavDesktop() {
  const dispatch = useDispatch();
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const [shoppingCartModalShow, setShoppingCartModalShow] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);

  return (
    <div className={styles.menu}>
      <div className={styles.menuSection1}></div>
      <div className={styles.menuSection2}>
        <span className={styles.menuItem}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? styles.navLinkActive : styles.navLink
            }
          >
            {t("components.navigation.header.home")}
          </NavLink>
        </span>
        <span className={styles.menuItem}>
          <NavLink
            to="/clients-page"
            className={({ isActive }) =>
              isActive ? styles.navLinkActive : styles.navLink
            }
          >
            {t("components.navigation.header.clients")}
          </NavLink>
        </span>
        <span className={styles.menuItem}>
          <NavLink
            to="/products-page"
            className={({ isActive }) =>
              isActive ? styles.navLinkActive : styles.navLink
            }
          >
            {t("components.navigation.header.products")}
          </NavLink>
        </span>
      </div>
      <div className={styles.menuSection3}>
        <div className={styles.logoBox}>
          <div className={styles.logo}></div>
        </div>
      </div>
      <div className={styles.menuSection4}>
        <span className={styles.menuItem2}>
          <NavLink
            to="/services-page"
            className={({ isActive }) =>
              isActive ? styles.navLinkActive : styles.navLink
            }
          >
            {t("components.navigation.header.services")}
          </NavLink>
        </span>
        <span className={styles.menuItem2}>
          <NavLink
            to="/routes/0"
            className={({ isActive }) =>
              isActive ? styles.navLinkActive : styles.navLink
            }
          >
            {t("components.navigation.header.routes")}
          </NavLink>
        </span>
        {userHasRole("admin") && (
          <span className={styles.menuItem2} style={{ marginLeft: "1vw" }}>
            <NavDropdown
              id="basic-nav-dropdown"
              className={styles.navDropdown}
              title={t("components.navigation.header.administration")}
            >
              <Dropdown.Item eventKey={1.1} onClick={() => navigate("/users")}>
                {" "}
                {t("components.navigation.header.users")}
              </Dropdown.Item>
              <Dropdown.Item eventKey={1.2} onClick={() => navigate("/places")}>
                {" "}
                {t("components.navigation.header.places")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={1.3}
                onClick={() => navigate("/countries")}
              >
                {" "}
                {t("components.navigation.header.countries")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={1.4}
                onClick={() => navigate("/reviews")}
              >
                {" "}
                {t("components.navigation.header.reviews")}
              </Dropdown.Item>
            </NavDropdown>
          </span>
        )}
      </div>
      <div className={styles.menuSection5}>
        <div className={styles.langMenuItem} style={{ marginRight: 10 }}>
          <div
            className={styles.flagSR}
            onClick={() => changeLanguage("sr")}
          ></div>
          <div
            className={styles.flagHR}
            onClick={() => changeLanguage("hr")}
          ></div>
          <div
            className={styles.flagEN}
            onClick={() => changeLanguage("en")}
          ></div>
        </div>
        {userLoggedIn() ? (
          <span
            className={styles.accountMenuItem}
            style={{ justifyContent: "center" }}
          >
            <Nav className={styles.dropdown}>
              <NavDropdown
                id="basic-nav-dropdown"
                title={<FaUserAlt color="#696969" size="3.5vh" />}
              >
                <Dropdown.Item
                  eventKey={1.1}
                  href="/#"
                  onClick={() => logoutWithDispatch(dispatch)}
                >
                  {t("components.navigation.header.logout")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setProfileModalShow(true)}>
                  {t("components.navigation.header.profile")}
                </Dropdown.Item>
              </NavDropdown>
            </Nav>
            <div className={styles.usernameContainer}>
              <p className={styles.username}>{getUsernameFromLocalStorage()}</p>
            </div>
            <EditProfileModal
              show={profileModalShow}
              onHide={() => setProfileModalShow(false)}
            />
          </span>
        ) : (
          <div className={styles.loginContainer}>
            <button
              name="action"
              value="login"
              onClick={() => navigate("/login")}
              className={styles.btnLogin}
            >
              <p className={styles.txtLogin}>
                {t("components.navigation.header.login")}
              </p>
            </button>
          </div>
        )}
        <span
          className={styles.accountMenuItem}
          style={{ justifyContent: "center" }}
        >
          <button
            onClick={() => setShoppingCartModalShow(true)}
            style={{ border: 0, marginLeft: 5 }}
          >
            <FaShoppingCart color="#696969" size="3vh" />
          </button>
          <ShoppingCartModal
            show={shoppingCartModalShow}
            onHide={() => setShoppingCartModalShow(false)}
          />
        </span>
      </div>
    </div>
  );
}
