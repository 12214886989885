import { useState } from "react";
import { BsX, BsClipboard, BsCheck } from "react-icons/bs";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import styles from "./CustomShare.module.css";
import ICONICA_SHARE from "../../assets/styles/icons/IKONICA_SHARE.png";

export default function CustomShare() {
  const { t } = useTranslation("all");

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
  };

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  return (
    <>
      <div className={styles.shareButtton} onClick={() => setModalIsOpen(true)}>
        {t("components.clients.client.share")}
        <img
          alt=""
          width={16}
          height={21}
          src={ICONICA_SHARE}
          style={{ marginLeft: " 0.5208333333333334vw" }}
        ></img>
      </div>
      <Modal
        isOpen={modalIsOpen}
        className={styles.modal}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className={styles.modalContent}>
          {t("components.clients.client.share")}

          <div className={styles.shareButtons}>
            <div className={styles.shareButtonSocial}>
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
            </div>

            <div className={styles.shareButtonSocial}>
              <FacebookMessengerShareButton
                url={window.location.href}
                appId={process.env.REACT_APP_FACEBOOK_ID ?? ""}
              >
                <FacebookMessengerIcon size={40} round />
              </FacebookMessengerShareButton>
            </div>
            <div className={styles.shareButtonSocial}>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </div>

            <div className={styles.shareButtonSocial}>
              <WhatsappShareButton url={window.location.href} separator=":: ">
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
            </div>

            <div className={styles.shareButtonSocial}>
              <ViberShareButton url={window.location.href}>
                <ViberIcon size={40} round />
              </ViberShareButton>
            </div>
          </div>
          <div className={styles.linkContainer}>
            <input
              type="text"
              className={styles.linkInput}
              value={window.location.href}
              readOnly
            />
            <button className={styles.copyLink} onClick={copyToClipboard}>
              {!isCopied ? <BsClipboard /> : <BsCheck />}
            </button>
          </div>
          <BsX
            className={styles.close}
            onClick={() => setModalIsOpen(false)}
            size={30}
          ></BsX>
        </div>
      </Modal>
    </>
  );
}
