import {DefaultModel} from '../defaultModel'

export class ClientCategory extends DefaultModel {
    name: string;
    nameSrb: string;
    nameCro: string;
    description: string;
    descriptionSrb: string;
    descriptionCro: string;
    code: string;
    constructor(){
        super();
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
        this.description = "";
        this.descriptionSrb = "";
        this.descriptionCro = "";
        this.code = "";
    }
}