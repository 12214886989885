import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { User } from "../../../../model/user/user";
import styles from "./Users.module.css";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import {IoMdAddCircle} from "react-icons/io";

function UsersTable(props : {users : User[], setSelectedUserId : (userId : number) => void, setDeleteModalShow : (modalShow : boolean) => void}) {
    const {users, setSelectedUserId, setDeleteModalShow} = props;
    const navigate = useNavigate();
    const { t }  = useTranslation('all');

    return (
            <div className={styles.tableRow}>
                <Table bordered hover className={styles.table}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>{t("components.users.username")}</th>
                            <th>{t("components.users.email")}</th>
                            <th>{t("components.users.firstName")}</th>
                            <th>{t("components.users.lastName")}</th>
                            <th>{t("components.users.role")}</th>
                            <th style={{textAlign:"center"}}><IoMdAddCircle className={styles.icon} onClick={() => navigate("/users/edit/0")} size={25}></IoMdAddCircle></th>
                        </tr>
                    </thead>
                    {users.length > 0 &&
                    <tbody>
                    {users.map((item: User) => {
                        return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.role && item.role.name}</td>
                            <td style={{textAlign:"center"}}>
                            <MdEdit className={styles.icon} onClick={() => navigate("/users/edit/" + item.id)} size={25}></MdEdit>
                            <TiDelete className={styles.icon} onClick={() => {setSelectedUserId(item.id);setDeleteModalShow(true)}} size={30} color="red" ></TiDelete>
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>}
                </Table>
            </div>
    )
}

export default UsersTable;