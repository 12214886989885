import styles from "./PhotoGallery.module.css";
import { PhotoCarousel } from "./PhotoCarousel";
import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { STATIC_HOST } from "../../../../utils/communication";
import { File } from "../../../../model/file/file";

function PhotoGallery(props: {
  picturePaths: File[];
}) {
  const { picturePaths} = props;

  useEffect(() => {
  }, []);

  const carouselPrevRef = useRef(null);
  const carouselNextRef = useRef(null);

  const onPrevClick = () => {
    //@ts-ignore
    carouselPrevRef.current.click();
  }

  const onNexClick = () => {
    //@ts-ignore
    carouselNextRef.current.click();
  }

  return (
    <div className={styles.pictureGallery}>
      <div className={styles.container}>
        {picturePaths.length > 3 && <div className={styles.row}>
          <div className={styles.paginationSection}>
              <div className={styles.arrowCol}>
                  <IoIosArrowBack
                  style={{paddingRight:"2px"}}
                  color="#FFFFFF"
                  opacity="0.5019607843137255"
                  size={25}
                  onClick={() => onPrevClick()}
                  />
              </div>
              <div className={styles.arrowCol} >
                  <IoIosArrowForward
                  style={{paddingLeft:"2px"}}
                  color="#FFFFFF"
                  opacity="0.5019607843137255"
                  size={25}
                  onClick={() => onNexClick()}
                  />
              </div>
          </div>
        </div>}
        <PhotoCarousel
          centerMode
          centerSlidePercentage={100 / 3}
          renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
            <div className={hasPrev? styles.arrowCol : styles.arrowColWithOpacity} ref={carouselPrevRef} onClick={() => {clickHandler()}}>
              <IoIosArrowBack
              style={{display:"none"}}
              />
            </div>
          )}
          renderArrowNext={(clickHandler, hasNext, labelPrev) => (
            <div className={hasNext? styles.arrowCol : styles.arrowColWithOpacity} ref={carouselNextRef} onClick={() => {clickHandler()}}>
              <IoIosArrowForward
                style={{display:"none"}}
                onClick={clickHandler}
              />
            </div>
          )}
        >
          {picturePaths.map((item: File, index: number) => {
            return (
                <div key={index} className={styles.photoCard}>
                  <img src={STATIC_HOST + item.path} className={styles.photoImg} width="100%" height="100%"></img>
                </div>
            );
          })}
        </PhotoCarousel>
      </div>
    </div>
  );
}

export default PhotoGallery;
