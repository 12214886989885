import { Dispatch } from "redux";
import { clientServicesService } from "../../rest/client_service/clientServicesService";

export const LOAD_CLIENT_SERVICES = "LOAD_CLIENT_SERVICES";
export const SELECT_SERVICE = "SELECT_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const loadServices = (dispatch: Dispatch<any>) => {
  clientServicesService.findAll().then((response) => {
    dispatch({ type: LOAD_CLIENT_SERVICES, payload: response.data });
  });
};

export const loadServicesPerPage = async (
  page: number,
  size: number,
  dispatch: Dispatch<any>
) => {
  const response = await clientServicesService.findByPage(page, size);
  dispatch({ type: LOAD_CLIENT_SERVICES, payload: response.data });
  return Number(response.headers["x-total-page"]);
};

export const filterServices = (
  onlyMy: boolean,
  categoryId: number | null,
  clientName: string,
  page: number,
  size: number
) => {
  return clientServicesService.filterServices(
    onlyMy,
    categoryId,
    clientName,
    page,
    size
  );
};

export const findServiceById = (id: number, dispatch: Dispatch<any>) => {
  clientServicesService.findById(id).then((response) => {
    dispatch({ type: SELECT_SERVICE, payload: response.data });
  });
};

export const findServicesByClientId = (id: number) => {
  return clientServicesService.findByIdClientId(id);
};

export const loadClientServicesPerPage =
  (id: number, page: number, size: number) => (dispatch: Dispatch<any>) => {
    return clientServicesService.findByClientAndPage(id, page, size);
  };

export const existServicesForClient = (id: number) => {
  return clientServicesService.existServicesForClient(id);
};

export const getServiceById = (id: number) => {
  return clientServicesService.findById(id);
};

export const create = (data: FormData) => {
  return clientServicesService.createService(data);
};

export const update = (data: FormData) => {
  return clientServicesService.updateService(data);
};

export const deleteService = (id: number, dispatch: Dispatch<any>) => {
  dispatch({ type: DELETE_SERVICE, payload: id });
};

export const deleteClientService = (id: number) => {
  return clientServicesService.delete(id);
};
