import styles from "./TitleWithShareButton.module.css";
import { useTranslation } from "react-i18next";
import IKONICA_SHARE from "../../../assets/styles/images/vesti/IKONICA_SHARE.png";
import { FacebookShareButton } from "react-share";
import { getIconByCode } from "../../../utils/getIcon";

function TitleWithShareButton(props: { title: string; iconPath: string }) {
  const { title, iconPath } = props;
  const { t } = useTranslation("all");

  return (
    <div
      className={styles.titleRow}
      style={{ marginTop: "5.0959752321981426vh" }}
    >
      <div className={styles.icon}>
        <img src={getIconByCode(iconPath)} alt="" />
      </div>
      <div className={styles.title}>
        <div className={styles.blueColorDark}>{title}</div>
      </div>
      <div className={styles.shareBtn}>
        <div className={styles.row}>
          <div>
            <FacebookShareButton
              style={{}}
              url={window.location.href}
              className="Demo__some-network__share-button"
            >
              {" "}
              {t("components.news.edit.share")}
              <img
                width={16}
                height={21}
                src={IKONICA_SHARE}
                style={{ marginLeft: "10px" }}
                alt=""
              />
            </FacebookShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleWithShareButton;
