import styles from "./NewComment.module.css";
import { useState } from "react";
import { Review } from "../../../../../model/client/review";
import { addReview } from "../../../../../actions/client/review";
import { useTranslation } from "react-i18next";
import { Toast, ToastContainer } from "react-bootstrap";

export default function NewComment(props: { id: number }) {
  const { t }  = useTranslation('all');
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleChange = (e: any) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    switch (inputName) {
      case "name":
        setName(inputValue);
        break;
      case "description":
        setDescription(inputValue);
        break;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let newComment = new Review(name, description, new Date());
    addReview(props.id, newComment).then(() => {
      setName("");
      setDescription("");
      setShowToast(true);
    });
  };

  return (
    <>
      <ToastContainer className="toastComment">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={1500} autohide>
          <Toast.Body><div>{t("components.clients.client.commentSuccess")}</div></Toast.Body>
        </Toast>
      </ToastContainer>
      <form onSubmit={handleSubmit}>
        <label className={styles.label}>{t("components.clients.client.nameAndSurname")}</label>
        <input type="text" name="name" value={name} className={styles.inputName} onChange={handleChange}/>
        <label className={styles.label} style={{marginTop:"3.159958720330238vh"}}>{t("components.clients.client.comment")} </label>
        <div className={styles.row}>
          <textarea name="description" value={description} className={styles.inputDescription} onChange={handleChange}/>
          <div className={styles.btnContainer}>
            <button type="submit" className={styles.btnSubmitComment}>
              {t("components.clients.client.submitComment")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
