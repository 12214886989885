import { Dispatch } from "redux";
import { productCategoryService } from "../../rest/product/productCategoryService";

export const LOAD_PRODUCT_CATEGORIES = 'LOAD_PRODUCT_CATEGORIES'

export const loadProductCategories = (dispatch: Dispatch<any>) => {
    productCategoryService.findAll()
    .then(response => {
        dispatch({ type: LOAD_PRODUCT_CATEGORIES, payload : response.data })})
}  

export const loadCategories = () => {
    return productCategoryService.findAll();
}