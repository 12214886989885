import { MapContainer, TileLayer, Popup } from "react-leaflet";
import { Client } from "../../../model/client/client";
import image from "../../../assets/styles/images/BALON_LOGO.png";
import "../../../assets/styles/leaflet.css";
import styles from "./Map.module.css";
import { CustomMarker } from "../../marker/CustomMarker";
import { Link } from "react-router-dom";
import { STATIC_HOST } from "../../../utils/communication";
import DOMPurify from "dompurify";
import { descriptionOf, nameOf } from "../../../utils/i18n";

export default function Map(props: { clients: Client[] }) {
  const { clients } = props;

  return (
    <div className={styles.map}>
      <MapContainer
        className={styles.mapContainer}
        center={[45.258985559754315, 19.831586334710508]}
        zoom={8}
        scrollWheelZoom={true}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {clients?.map(
          (client, index) =>
            client.location?.latitude &&
            client.location?.longitude && (
              <CustomMarker
                key={index}
                position={{
                  lat: client.location.latitude,
                  lng: client.location.longitude,
                }}
                draggable={false}
                code={client.clientCategory.code}
              >
                <Link
                  to={"/client-details-page/" + client.id}
                  className={styles.link}
                >
                  <Popup className={styles.clientPopUp}>
                    <img
                      className={styles.imgClient}
                      src={
                        client.logo
                          ? STATIC_HOST + client.logo
                          : client.files && client.files.length > 0
                          ? STATIC_HOST + client.files[0].path
                          : image
                      }
                      alt="Partner"
                    />
                    <p className={styles.clientName}>{nameOf(client)}</p>
                    <div className={styles.descriptionBox}>
                      <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(descriptionOf(client)),
                        }}
                      />
                    </div>
                  </Popup>
                </Link>
              </CustomMarker>
            )
        )}
      </MapContainer>
    </div>
  );
}
