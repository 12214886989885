import {
  ADD_SHOPPING_CART_ITEM,
  DELETE_SHOPPING_CART_ITEM,
  GET_SHOPPING_CART,
  ORDER_SUCCESS,
  ORDER_FAIL,
  UPDATE_SHOPPING_CART_ITEM,
  UPDATE_WITH_LOCAL_CART,
} from "../actions/user/shoppingCart";
import { ShoppingCart } from "../model/user/shoppingCart";

export interface ApplicationState {
  shoppingCart: ShoppingCart;
}

const initialState: ApplicationState = {
  shoppingCart: new ShoppingCart(),
};

const shoppingCartReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): ApplicationState => {
  switch (type) {
    case ADD_SHOPPING_CART_ITEM:
      return {
        ...state,
        shoppingCart: payload,
      };
    case UPDATE_SHOPPING_CART_ITEM:
      return {
        ...state,
        shoppingCart: payload,
      };
    case DELETE_SHOPPING_CART_ITEM:
      return {
        ...state,
        shoppingCart: payload,
      };
    case GET_SHOPPING_CART:
      return {
        ...state,
        shoppingCart: payload,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        shoppingCart: new ShoppingCart(),
      };
    case ORDER_FAIL:
      return {
        ...state,
        shoppingCart: payload,
      };
    case UPDATE_WITH_LOCAL_CART:
      return {
        ...state,
        shoppingCart: payload,
      };
    default:
      return state;
  }
};

export default shoppingCartReducer;
