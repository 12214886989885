import { AxiosResponse } from "axios";
import http from "../../http-common";
import { ShoppingCart, ShoppingCartItem } from "../../model/user/shoppingCart";

export class ShoppingCartService {
  addItem(item: ShoppingCartItem): Promise<AxiosResponse<ShoppingCart>> {
    return http.post("/api/shopping-carts/item", item);
  }

  updateItem(item: ShoppingCartItem): Promise<AxiosResponse<ShoppingCart>> {
    return http.put("/api/shopping-carts/item", item);
  }

  removeItem(id: number): Promise<AxiosResponse<ShoppingCart>> {
    return http.delete("/api/shopping-carts/item/" + id);
  }

  getActiveCart(): Promise<AxiosResponse<ShoppingCart>> {
    return http.get("/api/shopping-carts/my/in-progress/");
  }

  makeOrder(id: number): Promise<AxiosResponse<ShoppingCart>> {
    return http.put(`/api/shopping-carts/${id}/order`);
  }

  updateWithLocalCart(
    cart: ShoppingCart
  ): Promise<AxiosResponse<ShoppingCart>> {
    return http.post("/api/shopping-carts/local-cart", cart);
  }
}

export const shoppingCartService = new ShoppingCartService();
