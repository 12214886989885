export class RouteFilter{
    rate : number;
    category : number;
    duration : number;
    clientIds : number[];
    constructor(){
        this.rate = 0;
        this.category = 0;
        this.duration = 0;
        this.clientIds = [];
    }
}