import { useTranslation } from "react-i18next";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import styles from "./AboutProject.module.css";

export default function AboutProject() {
  const { t } = useTranslation("all");

  return (
    <>
      <TitleDarkLight
        titleDark={t("aboutProject.about")}
        titleLight={t("aboutProject.project")}
      ></TitleDarkLight>
      <div className={styles.projectListInfo}>
        <ul>
          <li>
            <b>{t("aboutProject.programKey")}:</b>{" "}
            {t("aboutProject.programValue")}
          </li>
          <li>
            <b>{t("aboutProject.titleKey")}:</b> {t("aboutProject.titleValue")}
          </li>
          <li>
            <b>{t("aboutProject.acronymKey")}:</b>{" "}
            {t("aboutProject.acronymValue")}
          </li>
          <li>
            <b>{t("aboutProject.startKey")}:</b> {t("aboutProject.startValue")}
          </li>
          <li>
            <b>{t("aboutProject.endKey")}:</b> {t("aboutProject.endValue")}
          </li>
          <li>
            <b>{t("aboutProject.leadPartnerKey")}:</b>{" "}
            {t("aboutProject.leadPartnerValue")}
          </li>
          <li>
            <b>{t("aboutProject.partnersKey")}:</b>{" "}
            {t("aboutProject.partnersValue")}
          </li>
          <li>
            <b>{t("aboutProject.goalKey")}:</b> {t("aboutProject.goalValue")}
          </li>
        </ul>
        <p className={styles.projectDescription}>
          {t("aboutProject.description")}
        </p>
        <p className={styles.projectDescription}>
          {t("aboutProject.disclaimer")}
        </p>
      </div>
    </>
  );
}
