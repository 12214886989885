import http from "../../http-common";

export class NominatimApi {
    
  url: string;
  constructor(){
      this.url = "https://nominatim.openstreetmap.org"
  };

  async getCoordinates(address : string, cityName : string){
    let resp = await http.get(this.url + "/search?street=" +  address + "&city=" + cityName + "&countrycodes=rs,hr" + '&format=json');
    return resp;
  }

  async validateLocation (latitude: number, longitude: number) {
    try {
      const response = await http.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
      if (response.data.error) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

export const nominatimApi = new NominatimApi();