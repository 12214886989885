import {DefaultModel} from '../defaultModel'

export class Role extends DefaultModel {
    name : string
    constructor(){
        super();
        this.name = "";
    }
}

