import styles from "./ClientDetails.module.css";
import { Client } from "../../../../model/client/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast, ToastContainer } from "react-bootstrap";
import DangerousDescription from "../../../layouts/dangerous_description/DangerousDescription";
import Categories from "../categories/Categories";
import Logo from "../../../layouts/logo/Logo";
import ContactInfo from "../contact-info/ContactInfo";
import RateAndShare from "../rate-and-share/RateAndShare";
import ProductsAndServices from "../products-and-services/ProductsAndServices";
import Title from "../title/Title";
import { descriptionOf } from "../../../../utils/i18n";

export default function ClientDetails(props: { client: Client }) {
  const [client, setClient] = useState(props.client);
  const { t } = useTranslation("all");
  const [showRateToast, setShowRateToast] = useState(false);

  const handleRateUpdate = (newRate: number) => {
    setClient((prevClient) => ({ ...prevClient, rate: newRate }));
  };

  return (
    <>
      <Title client={client} />
      <div className={styles.descriptionLogoSection}>
        <div className={styles.descriptionContainer}>
          <div className={styles.clientDescription}>
            <DangerousDescription description={descriptionOf(client)} />
            <Categories client={client} />
          </div>
        </div>
        <Logo client={client} />
      </div>
      <ContactInfo client={client} />
      <ToastContainer className="p-3" style={{ marginLeft: "10vw" }}>
        <Toast
          show={showRateToast}
          onClose={() => setShowRateToast(false)}
          delay={1500}
          autohide
        >
          <Toast.Body>
            <div className={styles.toastMessage}>
              {t("components.clients.client.rateSuccess")}
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <RateAndShare
        client={client}
        handleRateUpdate={handleRateUpdate}
        setShowRateToast={setShowRateToast}
      />
      <ProductsAndServices client={client} />
    </>
  );
}
