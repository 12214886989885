import { Dispatch } from "react";
import {
  ShoppingCart,
  ShoppingCartItem,
  ShoppingCartStatus,
} from "../../model/user/shoppingCart";
import { shoppingCartService } from "../../rest/user/shoppingCartService";
import { AnyAction } from "redux";
import { userLoggedIn } from "../../utils/jwt";

export const ADD_SHOPPING_CART_ITEM = "ADD_SHOPPING_CART_ITEM";
export const DELETE_SHOPPING_CART_ITEM = "DELETE_SHOPPING_CART_ITEM";
export const UPDATE_SHOPPING_CART_ITEM = "UPDATE_SHOPPING_CART_ITEM";
export const GET_SHOPPING_CART = "GET_SHOPPING_CART";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";
export const UPDATE_WITH_LOCAL_CART = "UPDATE_WITH_LOCAL_CART";

const localCartWrapper = (
  action: (cart: ShoppingCart) => void,
  modify = true
) => {
  const shoppingCartStr = localStorage.getItem("shoppingCart");
  const shoppingCart: ShoppingCart = shoppingCartStr
    ? JSON.parse(shoppingCartStr)
    : new ShoppingCart();
  action(shoppingCart);
  if (modify)
    localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
  return { data: shoppingCart };
};

const localAddItem = (item: ShoppingCartItem) => {
  return localCartWrapper((shoppingCart) => {
    item.id = +new Date();
    shoppingCart.items.push(item);
  });
};

const localUpdateItem = (item: ShoppingCartItem) => {
  return localCartWrapper((shoppingCart) => {
    const i = shoppingCart.items.findIndex((it) => it.id === item.id);
    shoppingCart.items[i] = item;
  });
};

const localRemoveItem = (id: number) => {
  return localCartWrapper((shoppingCart) => {
    const i = shoppingCart.items.findIndex((it) => it.id === id);
    shoppingCart.items.splice(i, 1);
  });
};

const localGetActiveCart = () => {
  return localCartWrapper(() => {}, false);
};

export const addItem = async (
  item: ShoppingCartItem,
  dispatch: Dispatch<AnyAction>
) => {
  const res = userLoggedIn()
    ? await shoppingCartService.addItem(item)
    : localAddItem(item);
  return dispatch({ type: ADD_SHOPPING_CART_ITEM, payload: res.data });
};

export const updateItem = async (
  item: ShoppingCartItem,
  dispatch: Dispatch<AnyAction>
) => {
  const res = userLoggedIn()
    ? await shoppingCartService.updateItem(item)
    : localUpdateItem(item);
  return dispatch({ type: UPDATE_SHOPPING_CART_ITEM, payload: res.data });
};

export const removeItem = async (id: number, dispatch: Dispatch<AnyAction>) => {
  const res = userLoggedIn()
    ? await shoppingCartService.removeItem(id)
    : localRemoveItem(id);
  return dispatch({ type: DELETE_SHOPPING_CART_ITEM, payload: res.data });
};

export const getActiveCart = async (dispatch: Dispatch<AnyAction>) => {
  const res = userLoggedIn()
    ? await shoppingCartService.getActiveCart()
    : localGetActiveCart();
  return dispatch({ type: GET_SHOPPING_CART, payload: res.data });
};

export const makeOrder = async (id: number, dispatch: Dispatch<AnyAction>) => {
  const res = await shoppingCartService.makeOrder(id);
  const shoppingCart = res.data;
  if (shoppingCart.status === ShoppingCartStatus.IN_PROGRESS) {
    dispatch({ type: ORDER_FAIL, payload: shoppingCart });
    return false;
  }
  dispatch({ type: ORDER_SUCCESS, payload: shoppingCart });
  return true;
};

export const updateWithLocalCart = async (dispatch: Dispatch<AnyAction>) => {
  const localCart = localGetActiveCart().data;
  if (localCart.items.length === 0) return;

  const res = await shoppingCartService.updateWithLocalCart(localCart);
  localCartWrapper((shoppingCart) => (shoppingCart.items = []));
  return dispatch({ type: GET_SHOPPING_CART, payload: res.data });
};
