import { Dispatch } from "redux";
import { clientTypeService } from "../../rest/client/clientTypeService";

export const LOAD_CLIENT_TYPES = "LOAD_CLIENT_TYPES";

export const loadTypes = () => {
  return clientTypeService.findAll()
}

export const loadClientTypes = (dispatch: Dispatch<any>) => {
  clientTypeService.findAll().then((response) => {
    dispatch({ type: LOAD_CLIENT_TYPES, payload: response.data });
  });
};
