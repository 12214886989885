import styles from "./VideoGallery.module.css";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { File } from "../../../model/file/file";
import { ReactSortable } from "react-sortablejs";
import { HiOutlinePlusSm } from "react-icons/hi";
import { AiOutlineReload } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import http from "../../../http-common";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";

export default function VideoGallery(props: {
  videos: File[];
  setVideos: (videos: File[]) => void;
  videoPaths: File[];
  setVideoPaths: (videoPaths: File[]) => void;
}) {
  const { videos, setVideos, videoPaths, setVideoPaths } = props;
  const { t } = useTranslation("all");

  const handleDragVideo = (newVideos: File[]) => {
    const oldVideosPaths = videos.map((video) => video.path);
    const newVideosPaths = newVideos.map((video) => video.path);
    if (JSON.stringify(oldVideosPaths) === JSON.stringify(newVideosPaths)) {
      return;
    }
    setVideos(newVideos);
    setVideoPaths(newVideos);
  };

  const removeVideo = (index: number) => {
    let newVideos = videos;
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const addNewVideo = () => {
    let newVideos = videos;
    let newFile = new File("", "", "VIDEO", 0);
    newFile.id = 0;
    newVideos.push(newFile);
    setVideos(newVideos);
    setVideoPaths(newVideos);
  };

  const reloadVideo = (index: number) => {
    const videoPath = videoPaths[index].path;
    const i = videoPath.indexOf("?v=");
    http
      .get(
        `/api/youtube?thumbUrl=https://img.youtube.com/vi/"${videoPath.substring(
          i + 3,
          i + 14
        )}/hqdefault.jpg`
      )
      .then(() => {
        let newVideos = videos;
        newVideos[index].path = videoPaths[index].path;
        setVideos(newVideos);
      })
      .catch(() => {
        let newVideos = videos;
        newVideos[index].path = "";
        setVideos(newVideos);
      });
  };

  const handleSetVideoPaths = (newPath: string, index: number) => {
    let newVideos: File[] = [];
    for (let i = 0; i < videoPaths.length; i++) {
      let newFile = new File(
        videoPaths[i].name,
        videoPaths[i].path,
        "VIDEO",
        0
      );
      newVideos.push(newFile);
      if (i === index) {
        newVideos[i].path = newPath;
      }
    }
    setVideoPaths(newVideos);
  };

  return (
    <>
      <TitleDarkLight
        titleDark={t("components.products.edit.videoGalleryFirst")}
        titleLight={t("components.products.edit.videoGallerySecond")}
      ></TitleDarkLight>
      <div className={styles.upload}>
        <div
          className="upload__image-wrapper"
          style={{ paddingTop: "0.7%", width: "100%" }}
        >
          <button
            onClick={() => addNewVideo()}
            type="button"
            className={styles.buttonTitle}
            style={{ width: "100%", height: "120px" }}
          >
            <HiOutlinePlusSm
              style={{ color: "gray", width: "20%", height: "100%" }}
            ></HiOutlinePlusSm>
            {t("components.news.edit.addVideo")}
          </button>
        </div>
      </div>
      <div className={styles.sortableContainer}>
        <div className={styles.row1}>
          {videos && (
            <ReactSortable
              className={styles.sortable}
              list={videos}
              setList={(newState) => handleDragVideo(newState)}
            >
              {videos.map((file, index) => (
                <div key={index}>
                  <div className={styles.imgVideoContainer}>
                    <img
                      src={
                        "https://img.youtube.com/vi/" +
                        file.path.substring(
                          file.path.indexOf("=") + 1,
                          file.path.indexOf("=") + 12
                        ) +
                        "/hqdefault.jpg"
                      }
                      className={styles.img}
                      alt="No video"
                    />
                    <TiDelete
                      size={30}
                      color="#8b0000"
                      className={styles.deleteIcon}
                      onClick={() => removeVideo(index)}
                    ></TiDelete>
                    {videoPaths.length > 0 && (
                      <Form.Control
                        className={styles.inputVideoPath}
                        onChange={(e) => {
                          handleSetVideoPaths(e.target.value, index);
                        }}
                        type="text"
                        placeholder="Path.."
                        style={{ width: "76%" }}
                        value={videoPaths[index].path}
                      />
                    )}
                    <Button
                      variant="success"
                      className={styles.reloadIcon}
                      onClick={() => reloadVideo(index)}
                    >
                      <AiOutlineReload size={20}></AiOutlineReload>
                    </Button>
                  </div>
                  {!file.path.includes("https://www.youtube.com/") && (
                    <div
                      className={styles.errorMessage}
                      style={{ marginLeft: "2.5vw" }}
                    >
                      <span>{t("components.clients.edit.notYoutube")}</span>
                    </div>
                  )}
                </div>
              ))}
            </ReactSortable>
          )}
        </div>
      </div>
    </>
  );
}
