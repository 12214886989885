import { Dispatch } from "react";
import { RegistrationDto } from "../../dto/registrationDto";
import { userRestService } from "../../rest/user/userService";
import { AnyAction } from "redux";
import { User } from "../../model/user/user";

export const UPDATE_PROFILE_INFO = "UPDATE_PROFILE_INFO";
export const GET_CURRENT_USER = "GET_CURRENT_USER";

export const registration = (newUser: RegistrationDto) => {
  return userRestService.register(newUser).then(
    () => Promise.resolve(),
    (error) => Promise.reject(error.response.data.message)
  );
};

export const getCurrentUser = async (dispatch: Dispatch<AnyAction>) => {
  const res = await userRestService.getCurrentUser();
  return dispatch({ type: GET_CURRENT_USER, payload: res.data });
};

export const updateProfileInfo = async (
  user: User,
  dispatch: Dispatch<AnyAction>
) => {
  const res = await userRestService.updateProfileInfo(user);
  return dispatch({ type: UPDATE_PROFILE_INFO, payload: res.data });
};
