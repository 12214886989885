import { Navigate, Outlet } from "react-router-dom";
import { getRolesFromLocalStorage } from "../utils/jwt";

export const PrivateRoute = (props: { roles: string[] }) => {
  const { roles } = props;
  const currentUserRoles = getRolesFromLocalStorage();

  return currentUserRoles.length &&
    roles.some((r) => currentUserRoles.includes(r)) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};
