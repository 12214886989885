import React from "react";
import { isMobile } from "react-device-detect";
import Nav from "../nav/Nav";
import styles from "./Header.module.css";

export default function Header(props: {
  background: any;
  height: number;
  gradient: any;
  gradientHeight: number;
}) {
  const { background, height, gradient, gradientHeight } = props;
  return (
    <>
      <div className={styles.headerTopLine}></div>
      <div className={styles.wrapper}>
        {!isMobile && <Nav gradient={gradient} height={gradientHeight} />}
      </div>
      <header
        className={styles.header}
        style={{
          backgroundImage: `url(${background})`,
          height: `${height}vh`,
        }}
      ></header>
    </>
  );
}
