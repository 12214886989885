import React from "react";
import { FaAward } from "react-icons/fa";
import styles from "./Header.module.css";

export default function HeaderImage(props: {
  background: any;
  height: number;
  title: string;
  golden: boolean;
}) {
  const { background, height, title, golden } = props;
  return (
    <>
      <div
        className={styles.headerImage}
        style={{
          backgroundImage: `url(${background})`,
          height: `${height}vh`,
        }}
      >
        <div className={styles.headerTitleContainer}>
          <div className={styles.headerTitle}>
            {golden ? <FaAward size={"5vh"} color={"#ffcc00"}></FaAward> : null}
            {title}
          </div>
        </div>
      </div>
    </>
  );
}
