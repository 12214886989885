import styles from "./FilterAndPagination.module.css";
import { Product } from "../../../../model/product/product";
import { filterProducts } from "../../../../actions/product/product";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadCategories } from "../../../../actions/product/productCategories";
import { ProductCategory } from "../../../../model/product/productCategory";
import { ProductFilter } from "../../../../filter/productFilter";
import Select from "react-select";
import { nameOf } from "../../../../utils/i18n";
import { selectStyles } from "../../../../utils/customSelectStyle";
import PRODUCT_CATEGORY_ICON from "../../../../assets/styles/icons/PRODUCT_CATEGORY_ICON.png";
import DOTS from "../../../../assets/styles/icons/DOTS.png";
import DIVIDER from "../../../../assets/styles/icons/DIVIDER.png";
import { Form } from "react-bootstrap";
import { userHasRole } from "../../../../utils/jwt";
import { Link, useParams } from "react-router-dom";
import Arrows from "../../../layouts/arrow/Arrows";

export default function FilterAndPagination(props: {
  setProducts: (products: Product[]) => void;
  page: number;
  setPage: (page: number) => void;
}) {
  const { t } = useTranslation("all");
  const { setProducts, page, setPage } = props;
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );
  const [productFilter, setProductFilter] = useState(new ProductFilter());
  const pageSize = 15;
  const [lastPage, setLastPage] = useState(1);
  const { clientName } = useParams();
  const isClient = userHasRole("client");
  const isAdmin = userHasRole("admin");

  useEffect(() => {
    loadCategories().then((resp) => {
      setProductCategories(resp.data);
    });
    setProductFilter({ ...productFilter, clientName: clientName ?? "" });
    filter();
  }, [page, clientName]);

  const filter = () => {
    filterProducts(
      productFilter.onlyMy,
      productFilter.category,
      productFilter.clientName,
      page,
      pageSize
    ).then((resp) => {
      setProducts(resp.data);
      setLastPage(Number(resp.headers["x-total-page"]) - 1);
    });
  };

  return (
    <div className={styles.filterAndPaginationSection}>
      <div className={styles.plusLink}>
        {(isAdmin || isClient) && (
          <Link className={styles.plusLinkDesign} to="/edit-product/0">
            + {t("components.products.addProduct")}
          </Link>
        )}
      </div>
      <div className={styles.filterCol}>
        <div className={styles.filterSection}>
          {isClient && (
            <>
              <div className={styles.filterOnlyMy}>
                <div className={styles.row} style={{ marginLeft: "1vw" }}>
                  <div className={styles.marginTop}>
                    <div className={styles.filterTitlesColor}>
                      {t("components.products.filter.onlyMy")}
                    </div>
                  </div>
                </div>
                <Form style={{ marginTop: "1vh", marginLeft: "1vw" }}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={productFilter.onlyMy}
                    onChange={() =>
                      setProductFilter({
                        ...productFilter,
                        onlyMy: !productFilter.onlyMy,
                      })
                    }
                  />
                </Form>
              </div>
              <div
                style={{
                  width: "0.1vw",
                  height: "5.5vh",
                  marginTop: "2vh",
                  marginLeft: "1vw",
                }}
              >
                <img src={DIVIDER} width="100%" height="100%" alt="" />
              </div>
            </>
          )}
          <div
            className={
              isClient ? styles.filterCategory : styles.filterCategoryWide
            }
          >
            <div className={styles.row}>
              <div className={styles.marginTop}>
                <div className={styles.filterTitlesColor}>
                  {t("components.clients.filter.category")}
                </div>
              </div>
              <div className={styles.filterCategoryIcon}>
                <img
                  src={PRODUCT_CATEGORY_ICON}
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.categories}>
                <Select
                  options={productCategories}
                  onChange={(item) => {
                    if (item)
                      setProductFilter({
                        ...productFilter,
                        category: item.id,
                      });
                  }}
                  getOptionLabel={(productCategory) => nameOf(productCategory)}
                  placeholder={t("components.clients.filter.category")}
                  styles={selectStyles}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "0.1vw",
              height: "5.5vh",
              marginTop: "2vh",
            }}
          >
            <img src={DIVIDER} width="100%" height="100%" alt="" />
          </div>
          <div className={isClient ? styles.filterName : styles.filterNameWide}>
            <div className={styles.filterCategoryRow}>
              <div className={styles.marginTop}>
                <div className={styles.filterTitlesColor}>
                  {t("components.clients.filter.clientName")}
                </div>
              </div>
              <div className={styles.dotsIcon}>
                <img src={DOTS} width="100%" height="100%" alt="" />
              </div>
            </div>
            <div className={styles.row} style={{ marginTop: "5px" }}>
              <div className={styles.categories}>
                <input
                  type="text"
                  value={productFilter.clientName}
                  className={styles.inputClientName}
                  onChange={(e) =>
                    setProductFilter({
                      ...productFilter,
                      clientName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.filterSearch} onClick={filter}>
            <div className={styles.filterIcon}></div>
          </div>
        </div>
      </div>
      <Arrows page={page} lastPage={lastPage} changePage={setPage} />
    </div>
  );
}
