import axios from "axios";
import { FRONT_HOST } from "./utils/communication";

const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token && config?.headers) {
    config.headers["X-Auth-Token"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.href = FRONT_HOST + "/#/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
