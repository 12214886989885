import styles from "./ClientCategories.module.css";
import { useEffect, useState } from "react";
import { nameOf } from "../../../../utils/i18n";
import { Route } from "../../../../model/route/route";
import { getIconByCode } from "../../../../utils/getIcon";

export default function ClientCategories(props: { route: Route }) {
  const { route } = props;
  const [clientMap, setClientMap] = useState<{ [code: string]: number }>({});

  useEffect(() => {
    const newClientMap: { [code: string]: number } = {};
    for (const client of route.clientInfo)
      newClientMap[client.clientCategory.code] =
        newClientMap[client.clientCategory.code] + 1 || 1;
    setClientMap(newClientMap);
  }, [route.clientInfo]);

  return (
    <div className={styles.clientCategoriesSection}>
      {Object.entries(clientMap).map(([code, count]) => (
        <div className={styles.clientCol}>
          <div className={styles.rowCategories}>
            <div className={styles.categoryIcon}>
              <img
                src={getIconByCode(code)}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className={styles.categoryName}>
              {nameOf(
                route.clientInfo.find((ci) => ci?.clientCategory.code === code)
                  ?.clientCategory
              )}
            </div>
            <div className={styles.number}>{count}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
