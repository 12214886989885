import styles from "./Clients.module.css";
import { useEffect, useState } from "react";
import { Client } from "../../../../model/client/client";
import ClientCard from "../../client-card/ClientCard";
import Arrows from "../../../layouts/arrow/Arrows";
import { filterClientsPerPage } from "../../../../actions/client/client";
import TitleDarkLightMini from "../../../layouts/title/TitleDarkLightMini";
import { ClientFilter } from "../../../../filter/clientFilter";

export default function Clients(props: {
  type: "S" | "K" | "P";
  filter: ClientFilter;
  titleDark: string;
  titleLight: string;
}) {
  const { type, filter, titleDark, titleLight } = props;
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const pageSize = 5;

  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    handleFilter();
  }, [page]);

  useEffect(() => {
    if (page === 0) handleFilter();
    setPage(0);
  }, [filter]);

  const handleFilter = async () => {
    filterClientsPerPage(
      type,
      filter.category,
      filter.name,
      filter.rate,
      page,
      pageSize
    ).then((resp) => {
      setClients(resp.data);
      setLastPage(Number(resp.headers["x-total-page"]) - 1);
    });
  };

  return (
    <>
      <div className={styles.titlePagination}>
        <TitleDarkLightMini titleDark={titleDark} titleLight={titleLight} />
        <div className={styles.rowArrows}>
          <div className={styles.colArrows}>
            <Arrows page={page} lastPage={lastPage} changePage={setPage} />
          </div>
        </div>
      </div>
      <div className={styles.sectionClientsContent}>
        <div className={styles.clientsContent}>
          <div className={styles.clients}>
            {clients.map((client, index) => (
              <ClientCard key={index} item={client} handleDelete={() => {}} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
