import styles from "./AnnouncmentAndSalesCategory.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { AnnouncementCategory } from "../../../../model/client/announcmentCategory";
import { SalesCategory } from "../../../../model/client/salesCategory";
import { salesCategoryService } from "../../../../rest/client/salesCategoryService";
import { announcmentCategoryService } from "../../../../rest/client/announcmentCategoryService";
import Multiselect from "multiselect-react-dropdown";
import { Client } from "../../../../model/client/client";

export default function AnnouncmentAndSalesCategory(props: {
  client: Client;
  setClient: (client: Client) => void;
}) {
  const { client, setClient } = props;
  const [announcmentCategories, setAnnouncmentCategories] = useState<
    AnnouncementCategory[]
  >([]);
  const [salesCategories, setSalesCategories] = useState<SalesCategory[]>([]);
  const { t } = useTranslation("all");

  const onSelectAnnouncmentCategory = (
    selectedList: AnnouncementCategory[],
    selectedItem: AnnouncementCategory
  ) => {
    client.announcementCategories.push(selectedItem);
  };

  const onRemoveAnnouncmentCategory = (
    selectedList: AnnouncementCategory[],
    selectedItem: AnnouncementCategory
  ) => {
    setClient({
      ...client,
      announcementCategories: client.announcementCategories.filter(
        (item: AnnouncementCategory) => {
          return item.id !== selectedItem.id;
        }
      ),
    });
  };

  const onSelectSalesCategory = (
    selectedList: SalesCategory[],
    selectedItem: SalesCategory
  ) => {
    client.salesCategories.push(selectedItem);
  };

  const onRemoveSalesCategory = (
    selectedList: SalesCategory[],
    selectedItem: SalesCategory
  ) => {
    setClient({
      ...client,
      salesCategories: client.salesCategories.filter((item: SalesCategory) => {
        return item.id !== selectedItem.id;
      }),
    });
  };

  useEffect(() => {
    salesCategoryService.findAll().then((resp) => {
      setSalesCategories(resp.data);
    });
    announcmentCategoryService.findAll().then((resp) => {
      setAnnouncmentCategories(resp.data);
    });
  }, []);

  return (
    <>
      <Form style={{ width: "100%", marginTop: "5vh" }}>
        <Form.Group className="mb-3">
          <Form.Label className={styles.title}>
            {t("components.clients.edit.announcmentCategory")}
          </Form.Label>
        </Form.Group>
      </Form>
      <div className={styles.row}>
        <div className={styles.col}>
          <Multiselect
            selectedValues={client.announcementCategories}
            options={announcmentCategories} // Options to display in the dropdown
            onSelect={onSelectAnnouncmentCategory} // Function will trigger on select event
            onRemove={onRemoveAnnouncmentCategory} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            placeholder="Izaberite"
          />
        </div>
      </div>
      <Form style={{ width: "100%", marginTop: "5vh" }}>
        <Form.Group className="mb-3">
          <Form.Label className={styles.title}>
            {t("components.clients.edit.salesCategory")}
          </Form.Label>
        </Form.Group>
      </Form>
      <div className={styles.row}>
        <div className={styles.col}>
          <Multiselect
            selectedValues={client.salesCategories}
            options={salesCategories} // Options to display in the dropdown
            onSelect={onSelectSalesCategory} // Function will trigger on select event
            onRemove={onRemoveSalesCategory} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            placeholder="Izaberite"
          />
        </div>
      </div>
    </>
  );
}
