import { useEffect, useState } from "react";
import NavDesktop from "./NavDesktop";
import styles from "./NavDesktop.module.css";

export default function Nav(props: { gradient: any; height: number }) {
  const { gradient, height } = props;
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY > 0) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  return (
    <div className={colorChange ? styles.navColor : styles.nav}>
      <NavDesktop />
      <span className="line"></span>
    </div>
  );
}
