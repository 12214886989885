import { AbstractRestService } from "../abstractRestService";
import { AxiosResponse } from "axios";
import http from "../../http-common"
import { News } from "../../model/admin/news";

export class NewsService extends AbstractRestService<News> {
    constructor() {
      super('/api/news');
    }  

    searchByPage(text: string, page: number, size: number) : Promise<AxiosResponse<News[]>>{
      return http.get(this.resourceUrl + "?search=" + text, {params:{page:page, size:size}});
    }

    createNews(data: FormData){
      return http.post(this.resourceUrl, data);
    }

    updateNews(data: FormData){
      return http.put(this.resourceUrl, data);
    }
}

export const newsService = new NewsService();