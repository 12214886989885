import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditProduct.module.css";
import News from "../../home/news/News";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../model/file/file";
import { Product } from "../../../model/product/product";
import {
  create,
  getProductById,
  update,
} from "../../../actions/product/product";
import { ProductCategory } from "../../../model/product/productCategory";
import { Client } from "../../../model/client/client";
import { userHasRole } from "../../../utils/jwt";
import Header from "../../layouts/header/Header";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { STATIC_HOST } from "../../../utils/communication";
import VideoGallery from "../../edit/video-gallery/VideoGallery";
import PhotoGallery from "../../edit/photo-gallery/PhotoGallery";
import EditName from "../../edit/edit-name/EditName";
import EditDescription from "../../edit/edit-description/EditDescription";
import CategoryAndClient from "./CategoryAndClient/CategoryAndClient";
import PriceAndAmount from "./PriceAndAmount/PriceAndAmount";

export default function EditProduct() {
  const { id } = useParams();
  const [product, setProduct] = useState<Product>({} as Product);
  const [images, setImages] = useState<any[]>([]);
  const [videoPaths, setVideoPaths] = useState<File[]>([]);
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [descriptionSrb, setDescriptionSrb] = useState("");
  const [descriptionCro, setDescriptionCro] = useState("");
  const isClient = userHasRole("client");

  const formSchema = Yup.object().shape({
    name: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!product.name
    ),
    nameSrb: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!product.nameSrb
    ),
    nameCro: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!product.nameCro
    ),
    category: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!product.productCategory.id
    ),
    client: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!product.client.id || isClient
    ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOpt);
  const { errors } = formState;

  const saveProduct = () => {
    const formData = new FormData();
    for (const video of product.videos) {
      if (!video.path.includes("https://www.youtube.com/")) {
        return;
      }
    }
    product.description = description;
    product.descriptionSrb = descriptionSrb;
    product.descriptionCro = descriptionCro;
    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }
    const blob = new Blob([JSON.stringify(product)], {
      type: "application/json",
    });
    formData.append("dto", blob);
    if (!product.id) {
      create(formData).then(() => {
        navigate("/products-page");
      });
    } else {
      update(formData).then(() => {
        navigate("/products-page");
      });
    }
  };

  const setProductById = () => {
    getProductById(Number(id)).then((resp) => {
      setProduct(resp.data);
      setVideoPaths(resp.data.videos);
      setDescription(resp.data.description);
      setDescriptionSrb(resp.data.descriptionSrb);
      setDescriptionCro(resp.data.descriptionCro);
    });
  };

  const setNewProduct = () => {
    let newProduct = new Product();
    newProduct.productCategory = new ProductCategory();
    newProduct.client = new Client();
    setProduct(newProduct);
    setDescription("");
    setDescriptionSrb("");
    setDescriptionCro("");
  };

  useEffect(() => {
    if (Number(id)) {
      setProductById();
    } else {
      setNewProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Header
        background={
          STATIC_HOST + "/front/proizvodi/PROIZVODI_HEADER_POZADINA.png"
        }
        height={84}
        gradient={STATIC_HOST + "front/proizvodi/GRADIENT.png"}
        gradientHeight={25}
      />
      <form onSubmit={handleSubmit(saveProduct)}>
        <div className={styles.sectionProductNews}>
          <div className={styles.productSection}>
            <div className={styles.saveRow}>
              <button type="submit" className={styles.btnSave}>
                {t("components.products.edit.save")}
              </button>
            </div>
            <EditName
              name={product.name}
              nameSr={product.nameSrb}
              nameHr={product.nameCro}
              setName={(newName) => setProduct({ ...product, name: newName })}
              setNameSr={(newName) =>
                setProduct({ ...product, nameSrb: newName })
              }
              setNameHr={(newName) =>
                setProduct({ ...product, nameCro: newName })
              }
              title={t("components.products.edit.name")}
              register={register}
              errors={errors}
            />
            <EditDescription
              description={description}
              descriptionSr={descriptionSrb}
              descriptionHr={descriptionCro}
              setDescription={setDescription}
              setDescriptionSr={setDescriptionSrb}
              setDescriptionHr={setDescriptionCro}
              title={t("components.products.edit.description")}
            />
            <PriceAndAmount
              price={product.unitPrice}
              setPrice={(newPrice) =>
                setProduct({ ...product, unitPrice: newPrice })
              }
              amount={product.availableAmount}
              setAmount={(newAmount) =>
                setProduct({ ...product, availableAmount: newAmount })
              }
              unit={product.unit}
              setUnit={(newUnit) => setProduct({ ...product, unit: newUnit })}
              register={register}
              errors={errors}
            />
            <CategoryAndClient
              product={product}
              setProduct={setProduct}
              register={register}
              errors={errors}
            />
            <PhotoGallery
              files={product.files}
              setFiles={(newFiles) => {
                setProduct({ ...product, files: newFiles });
              }}
              images={images}
              setImages={setImages}
            />
            <VideoGallery
              videos={product.videos}
              setVideos={(newVideos) =>
                setProduct({ ...product, videos: newVideos })
              }
              videoPaths={videoPaths}
              setVideoPaths={setVideoPaths}
            />
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </form>
    </>
  );
}
