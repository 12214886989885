import { AbstractRestService } from "../abstractRestService";
import http from "../../http-common";
import { ClientRate } from "../../model/client/clientRate";
import { Client } from "../../model/client/client";

export class ClientRateService extends AbstractRestService<ClientRate> {
    constructor() {
      super('/api/client-rate');
    }  

    async rateClient(clientId: number, rate : number): Promise<Client>{
        const res = await http.post(this.resourceUrl + "/" + clientId + "/" + rate);
        return res.data;
    }

    async getMyRate(clientId: number): Promise<number>{
      const res = await http.get(this.resourceUrl + "/" + clientId);
      return res.data;
    }
}
export const clientRateService = new ClientRateService();