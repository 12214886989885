import styles from "./ServicePage.module.css";
import News from "../../home/news/News";
import { ClientService } from "../../../model/client_service/clientService";
import { STATIC_HOST } from "../../../utils/communication";
import PhotoGallery from "../../layouts/gallery/photo-gallery/PhotoGallery";
import TitleWithShareButton from "../../layouts/title/TitleWithShareButton";
import DangerousDescription from "../../layouts/dangerous_description/DangerousDescription";
import Logo from "../../layouts/logo/Logo";
import { descriptionOf, nameOf } from "../../../utils/i18n";

function ServiceDetails(props: { service: ClientService }) {
  const { service } = props;

  return (
    <div className={styles.sectionServiceNews}>
      <div className={styles.serviceSection}>
        <TitleWithShareButton
          title={nameOf(service)}
          iconPath={service.client.clientCategory.code}
        />
        <div className={styles.descriptionLogoSection}>
          <div className={styles.descriptionContainer}>
            <DangerousDescription description={descriptionOf(service)} />
          </div>
          <Logo client={service.client} />
        </div>
        {service.files.length > 0 && (
          <div className={styles.mainPicture}>
            <img
              src={STATIC_HOST + service.files[0].path}
              alt="service"
              className={styles.mainImg}
              width="100%"
              height="100%"
            />
          </div>
        )}
        {service.files.length > 1 && (
          <PhotoGallery picturePaths={service.files.slice(1)} />
        )}
      </div>
      <div className={styles.sectionNews}>
        <News size={6} />
      </div>
    </div>
  );
}

export default ServiceDetails;
