import styles from "./UploadImage.module.css";
import { Route } from "../../../../model/route/route";
import { useTranslation } from "react-i18next";
import { HiOutlinePlusSm } from "react-icons/hi";
import ImageUploading from "react-images-uploading";
import { MAX_PICTURE_SIZE } from "../../../../utils/constants";
import { STATIC_HOST } from "../../../../utils/communication";

export default function UploadImage(props : {route : Route, setRoute : (route : Route) => void, images : any[], setImages : (images : any[]) => void}) {
  const { t } = useTranslation("all");
  const {route, setRoute, images, setImages} = props;
  const maxNumber = 20;

  const onImageChange = (imageList: any[]) => {
    let newImages = [];
    let image = imageList[imageList.length - 1];
    newImages.push(image);
    setImages(newImages);
    setRoute({ ...route, picturePath: image["data_url"] });
  };

  return (
    <>
        <ImageUploading
            multiple={false}
            value={images}
            onChange={onImageChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            maxFileSize={MAX_PICTURE_SIZE}
            acceptType={['png', 'jpg', 'jpeg', 'gif']}
        >
            {({ onImageUpload, isDragging, dragProps, errors }) => (
            <div className="upload__image-wrapper" style={{ width: "100%" }}>
                <button
                    type="button"
                    onClick={onImageUpload}
                    className={styles.buttonTitle}
                    {...dragProps}
                >
                <HiOutlinePlusSm className={styles.plus}></HiOutlinePlusSm>
                {route.picturePath === "" ? t("components.routes.edit.addPicture") : t("components.routes.edit.editPicture")}
                </button>
                <div className={styles.errorMessage}>
                                    {errors?.acceptType && <span>{t("components.clients.edit.imageWrongType")}</span>}
                                    {errors?.maxFileSize && <span>{t("components.clients.edit.imageTooBig")}</span>}
                </div>
                <div className="image-item">
                    <img src={route.picturePath.includes("data") ? route.picturePath : STATIC_HOST + route.picturePath} className={styles.img} alt=""/>
                    <div className="image-item__btn-wrapper"></div>
                </div>
            </div>
            )}
        </ImageUploading>
    </>
  );
}
