import styles from "./ProductsPage.module.css";
import ProductCard from "../product-card/ProductCard";
import News from "../../home/news/News";
import { Product } from "../../../model/product/product";
import { filterProducts } from "../../../actions/product/product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../layouts/header/Header";
import { STATIC_HOST } from "../../../utils/communication";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import FilterAndPagination from "./filter-pagination/FilterAndPagination";
import FilterTitle from "../../layouts/title/FilterTitle";

export default function ProductsPage() {
  const { t } = useTranslation("all");
  const [products, setProducts] = useState<Product[]>([]);
  const pageSize = 15;
  const [page, setPage] = useState(0);

  function filter() {
    filterProducts(false, 0, "", page, pageSize).then((resp) => {
      setProducts(resp.data);
    });
  }

  return (
    <div>
      <Header
        background={
          STATIC_HOST + "front/proizvodi/PROIZVODI_HEADER_POZADINA.png"
        }
        height={84.10732714138287}
        gradient={STATIC_HOST + "front/proizvodi/GRADIENT.png"}
        gradientHeight={24.664602683178536}
      />
      <div className={styles.productsPage}>
        <div className={styles.sectionProductsNews}>
          <div className={styles.productsSection}>
            <TitleDarkLight
              titleDark={t("components.products.titleDark")}
              titleLight={t("components.products.titleLight")}
            ></TitleDarkLight>
            <FilterTitle
              title={t("components.products.filter.title")}
            ></FilterTitle>
            <FilterAndPagination
              setProducts={setProducts}
              page={page}
              setPage={setPage}
            ></FilterAndPagination>
            <div className={styles.products}>
              {products.map((product, index) => (
                <div key={index}>
                  <ProductCard product={product} handleDelete={filter} />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
