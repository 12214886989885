import jwt_decode from "jwt-decode";

export interface TokenUser {
  sub: string;
  created: number;
  roles: string[];
  exp: number;
}

function getToken() {
  return localStorage.getItem("token");
}

export function getUserFromToken(token: string): TokenUser {
  return jwt_decode(token);
}

export function getUsernameFromToken(token: string) {
  return getUserFromToken(token).sub ?? "";
}

export function getRolesFromToken(token: string) {
  return getUserFromToken(token).roles ?? [];
}

export function getUsernameFromLocalStorage() {
  const token = getToken();
  return token ? getUsernameFromToken(token) : "";
}

export function getRolesFromLocalStorage() {
  const token = getToken();
  return token ? getRolesFromToken(token) : [];
}

export function getUserFromLocalStorage() {
  const token = getToken();
  return token ? getUserFromToken(token) : null;
}

export function userHasRole(...roles: string[]) {
  return getRolesFromLocalStorage().some((role) => roles.includes(role));
}

export function userLoggedIn() {
  return !!getToken();
}
