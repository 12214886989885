import { ClientService } from "../client_service/clientService";
import { DefaultModel } from "../defaultModel";
import { Location } from "./location";
import { File } from "../file/file";
import { Place } from "../admin/place";
import { User } from "../user/user";
import { Product } from "../product/product";
import { AnnouncementCategory } from "./announcmentCategory";
import { SalesCategory } from "./salesCategory";
import { ClientCategory } from "./clientCategory";
import { ClientType } from "./clientType";

export class Client extends DefaultModel {
  name: string;
  nameSrb: string;
  nameCro: string;
  sitePath: string;
  logo: string;
  description: string;
  descriptionSrb: string;
  descriptionCro: string;
  numberOfLikes: number;
  clientServices: ClientService[];
  products: Product[];
  announcementCategories: AnnouncementCategory[];
  salesCategories: SalesCategory[];
  location: Location;
  address: string;
  phoneNumber: string;
  email: string;
  files: File[];
  videos: File[];
  color: string;
  place?: Place;
  clientCategory: ClientCategory;
  users: User[];
  rate: number;
  numOfReviews: number;
  facebookPath: string;
  instagramPath: string;
  responsiblePerson: string;
  identificationNumber: string;
  type: ClientType;
  isRoutePoint: Boolean;

  constructor() {
    super();
    this.name = "";
    this.nameSrb = "";
    this.nameCro = "";
    this.sitePath = "";
    this.description = "";
    this.descriptionSrb = "";
    this.descriptionCro = "";
    this.logo = "";
    this.numberOfLikes = 0;
    this.clientServices = [];
    this.products = [];
    this.announcementCategories = [];
    this.salesCategories = [];
    this.location = {} as Location;
    this.address = "";
    this.phoneNumber = "";
    this.email = "";
    this.files = [];
    this.videos = [];
    this.color = "";
    this.users = [];
    this.rate = 0;
    this.clientCategory = {} as ClientCategory;
    this.numOfReviews = 0;
    this.facebookPath = "";
    this.instagramPath = "";
    this.responsiblePerson = "";
    this.identificationNumber = "";
    this.type = {} as ClientType;
    this.isRoutePoint = false;
  }
}
