import { AbstractRestService } from "../abstractRestService";
import { Client } from "../../model/client/client";
import { AxiosResponse } from "axios";
import http from "../../http-common";

export class ClientsRestService extends AbstractRestService<Client> {
  constructor() {
    super("/api/clients");
  }

  findByPageAndType(
    type: string,
    page: number,
    size: number
  ): Promise<AxiosResponse<Client[]>> {
    return http.get(this.resourceUrl + "/page/" + type, {
      params: { page: page, size: size },
    });
  }

  filterByPage(
    productCategoryId: number,
    clientServiceId: number,
    page: number,
    size: number
  ): Promise<AxiosResponse<Client[]>> {
    return http.get(
      this.resourceUrl + "/filter/" + productCategoryId + "/" + clientServiceId,
      { params: { size: size, page: page } }
    );
  }

  filterClientsPerPage(
    type: string,
    categoryId: number | null,
    name: string,
    rate: number,
    page: number,
    size: number
  ): Promise<AxiosResponse<Client[]>> {
    return http.get(this.resourceUrl + "/filter-per-page/" + type, {
      params: {
        clientCategoryId: categoryId,
        name: name,
        minRate: rate,
        page: page,
        size: size,
      },
    });
  }

  async filter(by: String, id: number): Promise<Client[]> {
    const res = await http.get(`${this.resourceUrl}/all/${by}/${id}`);
    return res.data;
  }

  updateClient(formData: FormData) {
    return http.put(this.resourceUrl, formData);
  }

  createClient(formData: FormData) {
    return http.post(this.resourceUrl, formData);
  }

  findWithNumOfComments(
    page: number,
    size: number
  ): Promise<AxiosResponse<Client[]>> {
    return http.get(this.resourceUrl + "/with-reviews", {
      params: { size: size, page: page },
    });
  }

  async filterClients(filters: {
    clientCategories: number[];
    productCategories: number[];
    serviceCategories: number[];
  }): Promise<AxiosResponse<Client[]>> {
    return http.get(this.resourceUrl + "/filter", {
      params: {
        clientCategoryIds: filters.clientCategories.join(","),
        productCategoryIds: filters.productCategories.join(","),
        serviceCategoryIds: filters.serviceCategories.join(","),
      },
    });
  }
}

export const clientsRestService = new ClientsRestService();
