import { useRef } from "react";
import Nav from "../nav/Nav";
import styles from "./Header.module.css";
import Slider from "react-slick";
import { STATIC_HOST } from "../../../utils/communication";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeaderSlick(props: {
  photos: string[];
  height: number;
  gradient: any;
  gradientHeight: number;
}) {
  const { photos, height, gradient, gradientHeight } = props;

  const customSlider = useRef<any>();

  return (
    <>
      <div className={styles.headerTopLine}></div>
      <div className={styles.wrapper}>
        <Nav gradient={gradient} height={gradientHeight} />
      </div>
      <div>
        <Slider
          ref={customSlider}
          className={styles.sliderContainer}
          dots={false}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          speed={1500}
          autoplaySpeed={2500}
          arrows={false}
          vertical={false}
          centerMode={false}
        >
          {photos.map((item, index) => (
            <div key={index}>
              <div
                className={styles.headerImage}
                style={{
                  backgroundImage: `url(${STATIC_HOST}front/home/header/headerPhotos/${item})`,
                  height: `${height}vh`,
                }}
              ></div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
