import styles from "./NewsCard.module.css";
import { useNavigate } from "react-router-dom";
import { descriptionOf, titleOf } from "../../../../utils/i18n";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { useState } from "react";
import { DeleteModal } from "./DeleteModal";
import image from "../../../../assets/styles/images/BALON_LOGO.png";
import DOMPurify from "dompurify";
import { STATIC_HOST } from "../../../../utils/communication";
import { News } from "../../../../model/admin/news";

export default function NewsCard(props: { news: News; canEdit: boolean }) {
  const { news, canEdit } = props;
  const navigate = useNavigate();
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  return (
    <div className={styles.newsCard}>
      <div className={styles.newsCardSection1}>
        <div className={styles.imgNewsContainer}>
          <img
            className={
              news.files.length > 0
                ? styles.imgNewsBorderRadius
                : styles.imgNews
            }
            src={
              news.files.length > 0 ? STATIC_HOST + news.files[0].path : image
            }
            alt="News"
          />
        </div>
      </div>
      <div className={styles.newsCardSection2}>
        <div className={styles.titleRow}>
          <div className={styles.newsTitleContainer}>
            <p className={styles.newsTitle}>{titleOf(news)}</p>
          </div>
          {canEdit && (
            <>
              <div className={styles.iconCol}>
                <MdEdit
                  className={styles.deleteIcon}
                  onClick={() => navigate("/edit-news/" + news.id)}
                  size={25}
                  style={{ paddingTop: "5%" }}
                />
              </div>
              <div className={styles.iconCol}>
                <TiDelete
                  className={styles.deleteIcon}
                  onClick={() => setDeleteModalShow(true)}
                  size={30}
                  color="red"
                  style={{ paddingTop: "2.5%" }}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.newsDescriptionContainer}>
          <p
            className={styles.newsDescription}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(descriptionOf(news)),
            }}
          />
        </div>
        <div
          className={styles.detailsBtnContainer}
          onClick={() => navigate("/news/" + news.id)}
        >
          <div className={styles.detailsBtn}>
            <div className={styles.detailsArrow}></div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        newsId={news.id}
      />
    </div>
  );
}
