import { Dispatch } from "redux";
import { Country } from "../../model/admin/country";
import { countryService } from "../../rest/admin/countryService";

export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const SELECT_COUNTRY = "SELECT_COUNTRY";

export const createCountry = (data: Country, dispatch: Dispatch<any>) => {
  countryService.create(data).then((response) => {
    dispatch({ type: CREATE_COUNTRY, payload: response.data });
  });
};

export const selectCountry = (element: Country) => {
  return {
    type: SELECT_COUNTRY,
    payload: element,
  };
};

export const loadCountries = (dispatch: Dispatch<any>) => {
  countryService.findAll().then((response) => {
    dispatch({ type: LOAD_COUNTRIES, payload: response.data });
  });
};

export const deleteCountry = (id: number, dispatch: Dispatch<any>) => {
  countryService.delete(id).then((response) => {
    dispatch({ type: DELETE_COUNTRY, payload: id });
  });
};

export const updateCountry = (data: Country, dispatch: Dispatch<any>) => {
  countryService.update(data.id, data).then((response) => {
    dispatch({ type: UPDATE_COUNTRY, payload: data });
  });
};
