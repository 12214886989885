import { AccessTokenContainer } from "../../dto/accessTokenContainer";
import http from "../../http-common";
import { setLanguage } from "../../utils/changeLanguage";

export interface LoginRequest {
  username: string;
  password: string;
}

export class AuthService {
  resourceUrl = "/api/auth";

  async login(user: LoginRequest): Promise<any> {
    const response = await http.post(`${this.resourceUrl}/login`, user);
    const token = response.headers["x-auth-token"];
    const lang = response.headers["language"];
    if (lang) setLanguage(lang);
    if (token) {
      localStorage.setItem("token", token);
      return token;
    }
    return response;
  }

  async resetPassword(oldPassword: string, password: string): Promise<any> {
    const response = await http.put(
      `${this.resourceUrl}/reset-password/${oldPassword}/${password}`
    );
    const token = response.headers["x-auth-token"];
    const lang = response.headers["language"];
    if (lang) setLanguage(lang);
    if (token) {
      localStorage.setItem("token", token);
      return token;
    }
    return response;
  }

  async googleLogin(access: AccessTokenContainer): Promise<any> {
    const response = await http.post(`${this.resourceUrl}/googleLogin`, access);
    const token = response.headers["x-auth-token"];
    const lang = response.headers["language"];
    if (lang) setLanguage(lang);
    if (token) {
      localStorage.setItem("token", JSON.stringify(token));
      return token;
    }
    return response;
  }

  async facebookLogin(access: AccessTokenContainer): Promise<any> {
    const response = await http.post(`${this.resourceUrl}/fbLogin`, access);
    const token = response.headers["x-auth-token"];
    const lang = response.headers["language"];
    if (lang) setLanguage(lang);
    if (token) {
      localStorage.setItem("token", JSON.stringify(token));
      return token;
    }
    return response;
  }

  logout = () => {
    localStorage.removeItem("token");
  };

  validateAccount = (token: string) => {
    return http.get(this.resourceUrl + "/validation", {
      headers: { "x-auth-token": token },
    });
  };

  forgotPassword(username: string): Promise<any> {
    return http.get(`${this.resourceUrl}/forgot-password/${username}`);
  }

  async resetForgottenPassword(password: string): Promise<any> {
    const response = await http.put(
      `${this.resourceUrl}/reset-forgotten-password/${password}`
    );
    const token = response.headers["x-auth-token"];
    const lang = response.headers["language"];
    if (lang) setLanguage(lang);
    if (token) {
      localStorage.setItem("token", token);
      return token;
    }
    return response;
  }
}

export const authService = new AuthService();
