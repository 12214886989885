export const selectStyles = {
    menuList: (styles: any) => ({
      ...styles,
      background: 'white'
    }),
    option: (styles: any, state: any) => ({
      backgroundColor: state.isSelected ? 'white' : 'white',
      padding: 20,
      color : "#696969"
    }),
    indicatorSeparator: (base:any) => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (base:any) => ({
      ...base,
      color: "#1C8693" 
    }),
    control: (base: any, state: any) => ({
        ...base,
        border: '0px',
        boxShadow: 'none',
        backgroundColor : "transparent",
        width: "9vw",
    })
  }