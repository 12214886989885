import { AbstractRestService } from "../abstractRestService";
import { Place } from "../../model/admin/place";
import { AxiosResponse } from "axios";
import http from "../../http-common";

export class PlaceService extends AbstractRestService<Place> {
    constructor() {
      super('/api/places');
    } 

    findByPage(page: number, size: number) : Promise<AxiosResponse<Place[]>>{
      return http.get(this.resourceUrl + "/page", {params:{page:page, size:size}});
    }
}

export const placeService = new PlaceService();