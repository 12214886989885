import { ClientLike } from "../../model/client/clientLike";
import { AbstractRestService } from "../abstractRestService";
import http from "../../http-common";

export class ClientLikeService extends AbstractRestService<ClientLike> {
    constructor() {
      super('/api/client-like');
    }  

    async addLikeForClient(id: number): Promise<number>{
        const res = await http.post(`${this.resourceUrl}/${id}`)
        return res.data;
    }

    async haveILiked(id : number) : Promise<boolean>{
      const resp = await http.get(this.resourceUrl + "/" + id);
      return resp.data;
    }
}
export const clientLikeService = new ClientLikeService();