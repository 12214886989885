import { Form, InputGroup } from "react-bootstrap"
import styles from "./Map.module.css"
import { Client } from "../../../../model/client/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MARKER_ICON from "../../../../assets/styles/images/marker-icon.png";
import { LatLng } from "leaflet";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { nominatimApi } from "../../../../rest/external_api/nominatimApi";
import { Location } from "../../../../model/client/location";

var L = require('leaflet');
var marker: L.Marker = {} as L.Marker;
var map: any;
var iconOptions = {
    iconUrl: MARKER_ICON,
    iconSize: [25, 35]
}
var customIcon = L.icon(iconOptions);
var markerOptions = {
    clickable: true,
    draggable: true,
    icon: customIcon
}

export default function Map(props : {client: Client, setClient : React.Dispatch<React.SetStateAction<Client>>,
    register : UseFormRegister<FieldValues>, errors : any}){
    const { t } = useTranslation('all');
    const [position, setPosition] = useState<LatLng>(new LatLng(0,0));
    const {client, setClient, register, errors} = props;
    const [locations, setLocations] = useState<any[]>([]);

    const setNewPosition = (position : LatLng) => {
        setPosition(position);
        setClient((cl: Client) => ({...cl, location : new Location(position.lat, position.lng)}));
    }

    const setMap = () => {
        var newMap = L.map('map', { center: [45.2396, 19.8227], zoom: 8});
        map = newMap;
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    }
    
    const setMapEvents = () => {
        map.on("click", (e: any) => {
            setNewPosition(new LatLng(e.latlng.lat, e.latlng.lng));
            if(Object.keys(marker).length > 0){
                marker.setLatLng(new LatLng(e.latlng.lat, e.latlng.lng));
            }else{
                marker = new L.Marker([e.latlng.lat, e.latlng.lng], markerOptions).addTo(map);
                setMarkerEvents();
            }
        });
    }
    
    const setMarkerEvents = () => {
        marker.on("dragend", (e: any) => {
            marker.setLatLng(new LatLng(e.target._latlng.lat, e.target._latlng.lng));
            setNewPosition(new LatLng(e.target._latlng.lat, e.target._latlng.lng));
        });
    }

    const changeLocation = (indx : number) => {
        let selectedLocation = locations.filter((item : any, index : number) => {return indx === index})[0];
        changeLatLngInput(new LatLng(selectedLocation.lat, selectedLocation.lon));
    }

    const findLocationByAddress = () => {
        if(client.place)
            nominatimApi.getCoordinates(client.address, client.place?.name).then((resp) => {
                setLocations(resp.data);
                changeLatLngInput(new LatLng(resp.data[0].lat, resp.data[0].lon));
            });
    }
    
    const changeLatLngInput = (newPosition: LatLng) => {
        setNewPosition(newPosition);
        marker.setLatLng(new LatLng(newPosition.lat, newPosition.lng));
        map.setView([newPosition.lat, newPosition.lng], 20);
    }

    useEffect(() => {
        setMap();
        if(client.id !== 0){
            setPosition(new LatLng(client.location.latitude, client.location.longitude));
            marker = new L.Marker([client.location.latitude, client.location.longitude], markerOptions).addTo(map);
        }else{
            marker = new L.Marker([position.lat, position.lng], markerOptions).addTo(map);
        }
        setMapEvents();
        setMarkerEvents();
    }, []);

    useEffect(() => {
        marker.setLatLng(new LatLng(position.lat, position.lng));
    }, [position]);

    return (
        <>
            <div className={styles.findCoordsRow}>
                <button type="button" className={styles.btnFindCoord} onClick={() => {findLocationByAddress()}}>
                    {t("components.clients.edit.findByAddress")}
                </button>
            </div>
            <div className={styles.mapSection}>
                <div id="map" className={styles.map}></div>
                {locations.length > 0 &&
                <div className={styles.dropdown}>
                    <select onChange={(e) => changeLocation(Number(e.target.value))} style={{border:"0px", width:"100%", textAlign:"center"}}>
                        {locations.map((item: any, index: number) => (
                        <option key={index} value={index}>
                            {item.display_name}
                        </option>
                        ))}
                    </select>
                </div>}
                <div className={styles.latLngInput}>
                    <Form style={{ width: "100%" }}>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <Form.Control style={{height:"10%"}} type="text" {...register('latitude')} value={position.lat} onChange={(e) => changeLatLngInput(new LatLng(Number(e.target.value), position.lng))} />
                                <Form.Control style={{height:"10%"}} type="text" value={position.lng} onChange={(e) => changeLatLngInput(new LatLng(position.lat, Number(e.target.value)))} />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div className={styles.rowEnd}>
                <div className={styles.errorMessage}>{t(errors.latitude?.message)}</div>
            </div>
        </>
    )
}