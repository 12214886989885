import {DefaultModel} from '../defaultModel'

export class Country extends DefaultModel {
    code: string;
    name: string;
    nameSrb: string;
    nameCro: string;
    constructor(){
        super();
        this.code = "";
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
    }
}

