import { Dispatch } from "redux";
import { serviceCategoryService } from "../../rest/client_service/serviceCategoryService";

export const LOAD_SERVICE_CATEGORIES = 'LOAD_SERVICE_CATEGORIES'

export const loadServiceCategories = (dispatch: Dispatch<any>) => {
    serviceCategoryService.findAll()
    .then(response => {
        dispatch({ type: LOAD_SERVICE_CATEGORIES, payload : response.data })})
}  

export const loadCategories = () => {
    return serviceCategoryService.findAll();
}  