import { AbstractRestService } from "../abstractRestService";
import { ClientService } from "../../model/client_service/clientService";
import { AxiosResponse } from "axios";
import http from "../../http-common";

export class ClientServicesRestService extends AbstractRestService<ClientService> {
    constructor() {
      super('/api/client-services');
    } 

    findByPage(page: number, size: number) : Promise<AxiosResponse<ClientService[]>>{
      return http.get(this.resourceUrl + "/page", {params:{page:page, size:size}});
    }

    findByIdClientId(id : number) : Promise<AxiosResponse<ClientService[]>>{
      return http.get(this.resourceUrl + "/client/" + id);
    }

    findByClientAndPage(id: number, page: number, size: number) : Promise<AxiosResponse<ClientService[]>>{
      return http.get(this.resourceUrl + "/page/client/" + id, {params:{page:page, size:size}});
    }

    existServicesForClient(id: number) : Promise<AxiosResponse<boolean>>{
      return http.get(this.resourceUrl + "/exist/client/" + id);
    }

    createService(data: FormData){
      return http.post(this.resourceUrl, data);
    }

    updateService(data: FormData){
      return http.put(this.resourceUrl, data);
    }

    filterServices(onlyMy : boolean, categoryId: number | null, clientName: string, page: number, size: number) : Promise<AxiosResponse<ClientService[]>>{
      return http.get(this.resourceUrl + "/filter", 
      {params:{onlyMy : onlyMy, serviceCategoryId:categoryId, clientName:clientName, page:page, size:size}});
    }
}
export const clientServicesService = new ClientServicesRestService();