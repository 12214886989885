import { SELECT_LOCATION } from "../actions/client/client";
import { LOAD_CLIENT_SERVICES } from "../actions/client_service/clientService";
import {
  CREATE_COUNTRY,
  DELETE_COUNTRY,
  LOAD_COUNTRIES,
  SELECT_COUNTRY,
  UPDATE_COUNTRY,
} from "../actions/admin/country";
import { Country } from "../model/admin/country";

export interface ApplicationState {
  countries: Country[];
  selectedCountry: Country;
}

const initialState: ApplicationState = {
  countries: [],
  selectedCountry: new Country(),
};

const countriesReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case CREATE_COUNTRY:
      return { ...state, countries: [...state.countries, payload] };
    case LOAD_COUNTRIES:
      return { ...state, countries: [...payload] };
    case DELETE_COUNTRY: {
      return {
        ...state,
        countries: state.countries.filter((c) => c.id !== payload),
      };
    }
    case SELECT_COUNTRY:
      return { ...state, selectedCountry: payload };
    case UPDATE_COUNTRY: {
      const country = state.countries.find((c) => c.id === payload.id);
      if (country) Object.assign(country, payload);
      return { ...state, countries: [...state.countries] };
    }
    case SELECT_LOCATION:
      return { ...state, selectedLocation: payload };
    case LOAD_CLIENT_SERVICES:
      return { ...state, clientServices: payload };
    default:
      return state;
  }
};

export default countriesReducer;
