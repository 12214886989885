import { AbstractRestService } from "../abstractRestService";
import { Country } from "../../model/admin/country";
import { AxiosResponse } from "axios";
import http from "../../http-common";

export class CountriesRestService extends AbstractRestService<Country> {
    constructor() {
      super('/api/countries');
    }  

    findByPage(page: number, size: number) : Promise<AxiosResponse<Country[]>>{
      return http.get(this.resourceUrl + "/page", {params:{page:page, size:size}});
    }
}
export const countryService = new CountriesRestService();