import styles from "./DownloadApp.module.css";
import { useTranslation } from "react-i18next";
import {
  APP_STORE_APP_LINK,
  GOOGLE_PLAY_APP_LINK,
} from "../../../utils/constants";

function DownloadApp() {
  const { t } = useTranslation("all");

  return (
    <div className={styles.aplication}>
      <div className={styles.aplicationInfoContainer}>
        <div className={styles.downloadAppTxtContainer}>
          <div className={styles.downloadAppTxt}>{t("global.downloadApp")}</div>
        </div>
        <div className={styles.downloadAppDescriptionContainer}>
          <div className={styles.downloadAppDescription}>
            {t("global.downloadAppDescription")}
          </div>
        </div>
        <div className={styles.downloadAppBtnsContainer}>
          <button
            className={styles.buttonDownloadApp}
            name="action"
            value="playStore"
            onClick={() => {
              window.open(GOOGLE_PLAY_APP_LINK);
            }}
          >
            <div className={styles.downloadPlayStore}></div>
          </button>
          <button
            className={styles.buttonDownloadApp}
            name="action"
            value="appStore"
            onClick={() => {
              window.open(APP_STORE_APP_LINK);
            }}
          >
            <div className={styles.downloadAppStore}></div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
