import { useState } from "react";
import styles from "./Login.module.css";
import News from "../../home/news/News";
import { useTranslation } from "react-i18next";
import Header from "../../layouts/header/Header";
import "../misc.css";
import ForgotPasswordForm from "./../forms/ForgotPasswordForm";
import LoginForm from "./../forms/LoginForm";
import RegisterForm from "./../forms/RegisterForm";
import { STATIC_HOST } from "../../../utils/communication";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";

function Login() {
  const { t } = useTranslation("all");

  const [formType, setFormType] = useState("login");

  const handleChangeForm = (type: string) => {
    setFormType(type);
  };

  return (
    <>
      <Header
        background={
          STATIC_HOST +
          "front/register-login/LOGIN_REGISTER_HEADER_POZADINA.png"
        }
        height={52.317880794701985}
        gradient={STATIC_HOST + "front/register-login/GRADIENT.png"}
        gradientHeight={22.61116367076632}
      />
      <div className={styles.mainContainer}>
        <div className={styles.loginContainer}>
          <TitleDarkLight
            titleDark={t("components.auth.register.title")}
            titleLight={t("components.auth.login.title")}
          ></TitleDarkLight>
          <div className={styles.sectionDescriptionLoginRegister}>
            <div className={styles.loginContentContainer}>
              <div className={styles.mainBox}>
                <div className={styles.blueBox}>
                  <div className={styles.tabsContainer}>
                    <div
                      className={
                        formType === "login"
                          ? styles.tabLoginActive
                          : styles.tabLogin
                      }
                      onClick={() => handleChangeForm("login")}
                    >
                      <p className={styles.tabTxtLogin}>
                        {t("components.auth.login.title")}
                      </p>
                    </div>
                    <div className={styles.divider}></div>
                    <div
                      className={
                        formType === "register"
                          ? styles.tabRegisterActive
                          : styles.tabRegister
                      }
                      onClick={() => handleChangeForm("register")}
                    >
                      <p className={styles.tabTxtRegister}>
                        {t("components.auth.register.tabTitle")}
                      </p>
                    </div>
                  </div>
                  <div className={styles.grayBox}>
                    {formType === "login" ? (
                      <LoginForm />
                    ) : formType === "register" ? (
                      <RegisterForm />
                    ) : formType === "forgotPass" ? (
                      <ForgotPasswordForm />
                    ) : null}
                  </div>
                  <div className={styles.tabsContainer}>
                    <div className={styles.forgotPasswordContainer}>
                      <p className={styles.forgotPasswordTxt}>
                        {t("components.auth.login.forgotPassword")}&nbsp;
                      </p>
                      <p
                        className={styles.forgotPasswordLink}
                        onClick={() => handleChangeForm("forgotPass")}
                      >
                        {t("components.auth.login.clickHere")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
      <DownloadApp></DownloadApp>
    </>
  );
}

export default Login;
