import { AbstractRestService } from "../abstractRestService";
import { Product } from "../../model/product/product";
import { AxiosResponse } from "axios";
import http from "../../http-common"

export class ProductsRestService extends AbstractRestService<Product> {
    constructor() {
      super('/api/products');
    }  

    findByPage(page: number, size: number) : Promise<AxiosResponse<Product[]>>{
      return http.get(this.resourceUrl + "/page", {params:{page:page, size:size}});
    }

    findByClientAndPage(id: number, page: number, size: number) : Promise<AxiosResponse<Product[]>>{
      return http.get(this.resourceUrl + "/page/client/" + id, {params:{page:page, size:size}});
    }

    findByIdClientId(id : number) : Promise<AxiosResponse<Product[]>>{
      return http.get(this.resourceUrl + "/client/" + id);
    }

    existProductsForClient(id: number) : Promise<AxiosResponse<boolean>>{
      return http.get(this.resourceUrl + "/exist/client/" + id);
    }

    createProduct(data: FormData){
      return http.post(this.resourceUrl, data);
    }

    updateProduct(data: FormData){
      return http.put(this.resourceUrl, data);
    }

    filterProducts(onlyMy: boolean, categoryId: number | null, clientName: string, page: number, size: number) : Promise<AxiosResponse<Product[]>>{
      return http.get(this.resourceUrl + "/filter", 
      {params:{onlyMy : onlyMy, productCategoryId:categoryId, clientName:clientName, page:page, size:size}});
    }
}

export const productsRestService = new ProductsRestService();