import { useEffect, useState } from "react";
import styles from "./Products.module.css";
import { Product } from "../../../model/product/product";
import { useDispatch, useSelector } from "react-redux";
import { loadProductsPerPage } from "../../../actions/product/product";
import { useTranslation } from "react-i18next";
import ProductCard from "../../product/product-card/ProductCard";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import Arrows from "../../layouts/arrow/Arrows";
import ViewAllButton from "../view-all-button/ViewAllButton";

function Products() {
  const { t } = useTranslation("all");
  const dispatch = useDispatch();
  const products = useSelector<any, Product[]>(
    (state) => state.products.products
  );
  const [page, setPage] = useState(0);
  const pageSize = 5;
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let isMounted = true;
    loadProductsPerPage(page, pageSize, dispatch).then((pages) => {
      if (isMounted) setLastPage(pages - 1);
    });
    return () => {
      isMounted = false;
    };
  }, [page]);

  return (
    <>
      <TitleDarkLight
        titleDark={t("global.eatpannonia")}
        titleLight={t("components.home.products")}
      />
      <div className={styles.arrowsRow}>
        <Arrows page={page} lastPage={lastPage} changePage={setPage} />
      </div>
      <div className={styles.sectionProductsContent}>
        <div className={styles.productsContent}>
          <div className={styles.products}>
            {products.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                handleDelete={() => {}}
              />
            ))}
          </div>
        </div>
        <ViewAllButton page="/products-page" />
      </div>
    </>
  );
}

export default Products;
