import styles from "./EditLogo.module.css"
import { useTranslation } from "react-i18next";
import { Client } from "../../../../model/client/client";
import ImageUploading from 'react-images-uploading';
import { STATIC_HOST } from "../../../../utils/communication";
import { MAX_PICTURE_SIZE } from "../../../../utils/constants";

export default function EditLogo(props : {client : Client, setClient : (client : Client) => void, logoImages : any[], setLogoImages : (images : any[]) => void}) {
    const {client, setClient, logoImages, setLogoImages} = props;
    const { t } = useTranslation('all');

    const onLogoImageChange = (imageList: any[]) => {
        let img = imageList[0];
        setClient({ ...client, logo: img['data_url'] });
        setLogoImages(imageList);
    };
    
    return (
        <>
            <div className={styles.row}>
                <div>
                    <ImageUploading
                        value={logoImages}
                        onChange={onLogoImageChange}
                        maxNumber={2}
                        dataURLKey="data_url"
                        maxFileSize={MAX_PICTURE_SIZE}
                        acceptType={['png', 'jpg', 'jpeg', 'gif']}
                    >
                        {({
                            onImageUpload,
                            isDragging,
                            dragProps,
                            errors
                        }) => (
                            <div className="upload__image-wrapper" style={{ paddingTop: "0.7%",width:"100%"}}>
                                <button type="button"
                                    style={{width:"13vw"}}
                                    onClick={onImageUpload}
                                    className={styles.buttonTitle}
                                    {...dragProps}
                                >{(client.logo == undefined || client.logo === "") ? t("components.clients.edit.addLogo") : t("components.clients.edit.editLogo")}
                                </button>
                                <div className={styles.errorMessage}>
                                {errors?.acceptType && <span>{t("components.clients.edit.imageWrongType")}</span>}
                                {errors?.maxFileSize && <span>{t("components.clients.edit.imageTooBig")}</span>}
                                </div>
                            </div>
                        )}
                    </ImageUploading>
                </div>
            </div>
            <div className={styles.row}>
                <div style={{width:"19vh", height:"19vh", marginTop:"1vh"}}>
                    <img src={client.logo && client.logo.substring(0, 4) === "data" ? client.logo : STATIC_HOST + client.logo} width="100%" height="100%"></img>
                </div>
            </div>
        </>
    )
}