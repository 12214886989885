import { newsService } from "../../rest/admin/newsService";
import { Dispatch } from "redux";

export const LOAD_NEWS = "LOAD_NEWS";
export const SELECT_NEWS = "SELECT_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

export const searchNewsPerPage =
  (text: string, page: number, size: number) =>
  async (dispatch: Dispatch<any>) => {
    const response = await newsService.searchByPage(text, page, size);
    dispatch({ type: LOAD_NEWS, payload: response.data });
    return Number(response.headers["x-total-page"]);
  };

export const findNewsById = (id: number, dispatch: Dispatch<any>) => {
  newsService.findById(id).then((response) => {
    dispatch({ type: SELECT_NEWS, payload: response.data });
  });
};

export const getNewsById = (id: number) => {
  return newsService.findById(id);
};

export const deleteNews = (id: number) => {
  return newsService.delete(id);
};

export const deleteNewsDispatch = (id: number, dispatch: Dispatch<any>) => {
  dispatch({ type: DELETE_NEWS, payload: id });
};

export const create = (data: FormData) => {
  return newsService.createNews(data);
};

export const update = (data: FormData) => {
  return newsService.updateNews(data);
};
