import styles from "./FilteredRoutes.module.css";
import { Route } from "../../../../model/route/route";
import "../../leaflet-legend.css";
import RouteCard from "../../route-card/RouteCard";
import { routesRestService } from "../../../../rest/route/routeService";
import { RouteFilter } from "../../../../filter/routeFilter";

export default function FilteredRoutes(props: {routes : Route[], setRoutes : (route : Route[]) => void,
    setSelectedRoute : (route : Route) => void,page: number, routeFilter : RouteFilter}) {
  const {routes, setRoutes, setSelectedRoute, page, routeFilter} = props;
  const size = 4;

  function filter() {
    routesRestService.filterRoutes(routeFilter,page,size).then((resp) => {
      setRoutes(resp.data);
    });
  }

  return (
      <>
        {routes.length > 0 && (
          <div className={styles.filteredRoutes}>
            {routes.map((item: Route, index: number) => (
              <RouteCard key={index} route={item} handleSelectRoute={setSelectedRoute} handleDelete={filter}/>
            ))}
          </div>
        )}
    </>
  );
}
