import styles from "./CommentCard.module.css";
import { Review } from "../../../../../model/client/review";
import Moment from "moment";
import {
  approveReview,
  hideReview,
} from "../../../../../actions/client/review";
import { useTranslation } from "react-i18next";

export default function CommentCard(props: {
  review: Review;
  handleToggleReview: () => void;
  canApprove: boolean;
}) {
  const { review, handleToggleReview, canApprove } = props;
  const { t } = useTranslation("all");

  const approve = () => {
    approveReview(review.id).then((resp) => {
      handleToggleReview();
    });
  };

  const hide = () => {
    hideReview(review.id).then((resp) => {
      handleToggleReview();
    });
  };

  return (
    <div>
      <div className={styles.commentCard}>
        <div className={styles.commentNameContainer}>
          <div className={styles.commentNameCol}>
            <p className={styles.commentName}>{review.name}</p>
          </div>
          <div className={styles.row}>
            {canApprove && (
              <div className={styles.btnCol}>
                {review.approved ? (
                  <button className={styles.btnHide} onClick={hide}>
                    {t("components.clients.client.hideComment")}
                  </button>
                ) : (
                  <button className={styles.btnApprove} onClick={approve}>
                    {t("components.clients.client.approveComment")}
                  </button>
                )}
              </div>
            )}
            <div>
              <p className={styles.commentDate}>
                {Moment(review.date).format("DD.MM.YYYY")}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.commentDescriptionContainer}>
          <p className={styles.commentDescription}>{review.description}</p>
        </div>
      </div>
    </div>
  );
}
