import styles from "./ProductCard.module.css";
import { useNavigate, Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { getUsernameFromLocalStorage, userHasRole } from "../../../utils/jwt";
import { Product } from "../../../model/product/product";
import image from "../../../assets/styles/images/BALON_LOGO.png";
import { useState } from "react";
import { DeleteModal } from "./DeleteModal";
import { nameOf } from "../../../utils/i18n";
import { STATIC_HOST } from "../../../utils/communication";

function ProductCard(props: { product: Product; handleDelete: () => void }) {
  const { product, handleDelete } = props;
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const navigate = useNavigate();
  const currentUsername = getUsernameFromLocalStorage();

  return (
    <div className={styles.productCardContainer}>
      <div className={styles.productCard}>
        <Link to={"/product/" + product.id} className={styles.link}>
          <img
            className={styles.imgProduct}
            src={
              product.files.length > 0
                ? STATIC_HOST + product.files[0].path
                : image
            }
            style={{ borderRadius: "5%" }}
            alt="Product"
          />
        </Link>
        <div className={styles.row}>
          <div className={styles.categoryInTitle}>
            <p className={styles.category}>{nameOf(product.productCategory)}</p>
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.titleCol}>
            <p className={styles.productTitle}>{nameOf(product)}</p>
          </div>
          {(userHasRole("admin") ||
            product.client.users?.some(
              (user) => user.username === currentUsername
            )) && (
            <>
              <div className={styles.iconCol} style={{ alignSelf: "center" }}>
                <MdEdit
                  className={styles.deleteIcon}
                  onClick={() => navigate("/edit-product/" + product.id)}
                  size={25}
                  style={{ paddingTop: "5%" }}
                />
              </div>
              <div className={styles.iconCol} style={{ alignSelf: "center" }}>
                <TiDelete
                  className={styles.deleteIcon}
                  onClick={() => setDeleteModalShow(true)}
                  size={30}
                  color="red"
                  style={{ paddingTop: "2.5%" }}
                />
              </div>
            </>
          )}
        </div>
        <DeleteModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          productId={product.id}
          setClient={() => {}}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
}

export default ProductCard;
