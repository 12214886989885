import { Client } from "../../model/client/client";
import { clientsRestService } from "../../rest/client/clientService";
import { Dispatch } from "redux";
import { LatLng } from "leaflet";
import { clientLikeService } from "../../rest/client/clientLikeService";

export const CREATE_CLIENT = "CREATE_CLIENT";
export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const FILTER_CLIENTS = "FILTER_CLIENTS";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const SELECT_CLIENT = "SELECT_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const LOAD_ALL_CLIENTS = "LOAD_ALL_CLIENTS";
export const LOAD_COLLABORATORS = "LOAD_COLLABORATORS";
export const LOAD_USERS = "LOAD_USERS";

export const createClient = (data: Client, dispatch: Dispatch<any>) => {
  clientsRestService.create(data).then((response) => {
    dispatch({ type: CREATE_CLIENT, payload: response.data });
  });
};

export const loadClientsByTypePerPage = async (
  type: string,
  page: number,
  size: number,
  dispatch: Dispatch<any>
) => {
  const response = await clientsRestService.findByPageAndType(type, page, size);
  if (type === "S") {
    dispatch({ type: LOAD_COLLABORATORS, payload: response.data });
  } else if (type === "K") {
    dispatch({ type: LOAD_USERS, payload: response.data });
  } else if (type === "P") {
    dispatch({ type: LOAD_CLIENTS, payload: response.data });
  } else {
    dispatch({ type: LOAD_ALL_CLIENTS, payload: response.data });
  }
  return Number(response.headers["x-total-page"]);
};

export const deleteClient = (id: number, dispatch: Dispatch<any>) => {
  dispatch({ type: DELETE_CLIENT, payload: id });
};

export const deleteClientWithoutDispatch = (id: number) => {
  return clientsRestService.delete(id);
};

export const filterClientsByPage = (
  productCategoryId: number,
  clientServiceId: number,
  page: number,
  size: number,
  dispatch: Dispatch<any>
) => {
  clientsRestService
    .filterByPage(productCategoryId, clientServiceId, page, size)
    .then((response) => {
      dispatch({ type: FILTER_CLIENTS, payload: response.data });
    });
};

export const filterClientsPerPage = (
  type: string,
  categoryId: number,
  name: string,
  rate: number,
  page: number,
  size: number
) => {
  return clientsRestService.filterClientsPerPage(
    type,
    categoryId,
    name,
    rate,
    page,
    size
  );
};

export const selectLocation = (data: LatLng, dispatch: Dispatch<any>) => {
  dispatch({ type: SELECT_LOCATION, payload: data });
};

export const selectClient = (id: number, dispatch: Dispatch<any>) => {
  clientsRestService.findById(id).then((response) => {
    dispatch({ type: SELECT_CLIENT, payload: response.data });
  });
};

export const updateClient = (data: Client, dispatch: Dispatch<any>) => {
  clientsRestService.update(data.id, data).then((response) => {
    dispatch({ type: UPDATE_CLIENT, payload: response.data });
  });
};

export const filter = (by: String, id: number) => {
  return clientsRestService.filter(by, id);
};

export const loadAllClients = (dispatch: Dispatch<any>) => {
  clientsRestService.findAll().then((response) => {
    dispatch({ type: LOAD_CLIENTS, payload: response.data });
  });
};

export const addLike = (id: number) => {
  return clientLikeService.addLikeForClient(id);
};

export const getClientById = (id: number) => {
  return clientsRestService.findById(id);
};

export const getClients = () => {
  return clientsRestService.findAll();
};

export const update = (formData: FormData) => {
  return clientsRestService.updateClient(formData);
};

export const create = async (formData: FormData) => {
  return clientsRestService.createClient(formData);
};

export const filterClients = (
  filters: {
    clientCategories: number[];
    productCategories: number[];
    serviceCategories: number[];
  },
  dispatch: Dispatch<any>
) => {
  clientsRestService.filterClients(filters).then((response) => {
    dispatch({ type: FILTER_CLIENTS, payload: response.data });
  });
};

export const loadClientsWithNumOfComments = (page: number, size: number) => {
  return clientsRestService.findWithNumOfComments(page, size);
};
