import {DefaultModel} from '../defaultModel'

export class Location extends DefaultModel {
    longitude: number;
    latitude: number;
    constructor( newLatitude: number, newLongitude: number){
        super();
        this.longitude = newLongitude;
        this.latitude = newLatitude;
    }
}