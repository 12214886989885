import React from "react";
import { FaAward } from "react-icons/fa";
import styles from "./ClientCard.module.css";

export default function ClientLogo(props: {
  background: any;
  golden: boolean;
}) {
  const { background, golden } = props;
  return (
    <>
      <div
        className={styles.imgClient}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        {golden ? (
          <div className={styles.logoGoldenIconContainer}>
            <div className={styles.goldenIcon}>
              <FaAward size={"5vh"} color={"#ffcc00"}></FaAward>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
