import { Country } from './country';
import {DefaultModel} from '../defaultModel'

export class Place extends DefaultModel {
    code: string;
    name: string;
    nameSrb: string;
    nameCro: string;
    country: Country;
    constructor(){
        super();
        this.code = "";
        this.name = "";
        this.nameSrb = "";
        this.nameCro = "";
        this.country = new Country();
    }
}