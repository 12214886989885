import { useEffect, useState } from "react";
import { Place } from "../../../../model/admin/place";
import News from "../../../home/news/News";
import styles from "./Places.module.css";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "./DeleteModal";
import { placeService } from "../../../../rest/admin/placeService";
import { deletePlace } from "../../../../actions/admin/place";
import HeaderWithTxt from "../../../layouts/header/HeaderWithTxt";
import { STATIC_HOST } from "../../../../utils/communication";
import Title from "../../../layouts/title/Title";
import Arrows from "../../../layouts/arrow/Arrows";
import PlacesTable from "./PlacesTable";

function PlacesPage() {
  const [places, setPlaces] = useState<Place[]>([]);
  const [page, setPage] = useState(0);
  const size = 10;
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const { t } = useTranslation("all");

  useEffect(() => loadPlacesPerPage(), [page]);

  const loadPlacesPerPage = () => {
    placeService.findByPage(page, size).then((resp) => {
      setPlaces(resp.data);
      setLastPage(Number(resp.headers["x-total-page"]) - 1);
    });
  };

  const onDeleteHandle = () => {
    deletePlace(selectedPlaceId).then(() => {
      setDeleteModalShow(false);
      loadPlacesPerPage();
    });
  };

  return (
    <div>
      <HeaderWithTxt
        background={STATIC_HOST + "front/home/header/HOME_HEADER_POZADINA.png"}
        height={84}
        gradient={STATIC_HOST + "front/home/header/GRADIENT.png"}
        gradientHeight={33}
        title=""
        golden={false}
      />
      <div className={styles.placesPage}>
        <div className={styles.sectionPlacesNews}>
          <div className={styles.placesSection}>
            <Title title={t("components.places.title")} icon="balon" />
            <div className={styles.arrowsRow}>
              <Arrows page={page} lastPage={lastPage} changePage={setPage} />
            </div>
            <PlacesTable
              places={places}
              setDeleteModalShow={setDeleteModalShow}
              setSelectedPlaceId={setSelectedPlaceId}
            />
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDeleteHandle={onDeleteHandle}
      />
    </div>
  );
}

export default PlacesPage;
