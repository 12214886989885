import styles from "./PhotoGallery.module.css";
import { useTranslation } from "react-i18next";
import { File } from "../../../model/file/file";
import { ReactSortable } from "react-sortablejs";
import ImageUploading from "react-images-uploading";
import { HiOutlinePlusSm } from "react-icons/hi";
import { TiDelete } from "react-icons/ti";
import { STATIC_HOST } from "../../../utils/communication";
import { MAX_PICTURE_SIZE } from "../../../utils/constants";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";

export default function PhotoGallery(props: {
  files: File[];
  setFiles: (files: File[]) => void;
  images: any[];
  setImages: (images: any[]) => void;
}) {
  const { files, setFiles, images, setImages } = props;
  const maxNumber = 100;
  const { t } = useTranslation("all");

  const handleDrag = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  const onImageChange = (imageList: any[]) => {
    const newFiles: File[] = [];
    for (const img of imageList) {
      if (!images.some((image) => img.file === image.file))
        newFiles.push(
          new File(img.file["name"], img["data_url"], "PICTURE", 0)
        );
    }
    setFiles(files.concat(newFiles));
    setImages(imageList);
  };

  const removeFile = (item: File) => {
    setFiles(files.filter((file) => file.path !== item.path));
    setImages(images.filter((img) => img["data_url"] !== item.path));
  };

  return (
    <div>
      <TitleDarkLight
        titleDark={t("components.products.edit.photoGalleryFirst")}
        titleLight={t("components.products.edit.photoGallerySecond")}
      ></TitleDarkLight>
      <div className={styles.upload}>
        <ImageUploading
          multiple
          value={images}
          onChange={onImageChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          maxFileSize={MAX_PICTURE_SIZE}
          acceptType={["png", "jpg", "jpeg", "gif"]}
        >
          {({ onImageUpload, isDragging, dragProps, errors }) => (
            <div
              className="upload__image-wrapper"
              style={{ paddingTop: "0.7%", width: "100%" }}
            >
              <button
                type="button"
                style={{ width: "100%", height: "120px" }}
                onClick={onImageUpload}
                className={styles.buttonTitle}
                {...dragProps}
              >
                <HiOutlinePlusSm
                  style={{ color: "gray", width: "20%", height: "100%" }}
                ></HiOutlinePlusSm>
                {t("components.news.edit.addPicture")}
              </button>
              <div className={styles.errorMessage}>
                {errors?.acceptType && (
                  <span>{t("components.clients.edit.imageWrongType")}</span>
                )}
                {errors?.maxFileSize && (
                  <span>{t("components.clients.edit.imageTooBig")}</span>
                )}
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
      <div className={styles.sortableContainer}>
        <div className={styles.row1}>
          {files && (
            <ReactSortable
              className={styles.sortable}
              list={files}
              setList={(newState: File[]) => handleDrag(newState)}
            >
              {files.map((file, index) => (
                <div className={styles.imgContainer} key={index}>
                  <img
                    src={
                      file.path.startsWith("data")
                        ? file.path
                        : STATIC_HOST + file.path
                    }
                    className={styles.img}
                    alt=""
                  />
                  <TiDelete
                    size={30}
                    color="#8b0000"
                    className={styles.deleteIcon}
                    onClick={() => removeFile(file)}
                  ></TiDelete>
                </div>
              ))}
            </ReactSortable>
          )}
        </div>
      </div>
    </div>
  );
}
