import { useTranslation } from "react-i18next";
import { Client } from "../../../model/client/client";
import PhotoGallery from "../../layouts/gallery/photo-gallery/PhotoGallery";
import VideoGallery from "../../layouts/gallery/video-gallery/VideoGallery";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";

export default function PhotoVideoSection(props: { client?: Client }) {
  const { client } = props;
  const { t } = useTranslation("all");

  if (!client) return <></>;

  return (
    <>
      {client.files.length > 0 && (
        <>
          <TitleDarkLight
            titleDark={t("components.clients.client.photoGaleryTitleDark")}
            titleLight={t("components.clients.client.photoGaleryTitleLight")}
          />
          <PhotoGallery picturePaths={client.files} />
        </>
      )}
      {client.videos.length > 0 && (
        <>
          <TitleDarkLight
            titleDark={t("components.clients.client.videoGaleryTitleDark")}
            titleLight={t("components.clients.client.videoGaleryTitleLight")}
          />
          <VideoGallery videoPaths={client.videos} />
        </>
      )}
    </>
  );
}
