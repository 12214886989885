import styles from "./EditRoute.module.css";
import News from "../../home/news/News";
import { Route } from "../../../model/route/route";
import { useEffect, useState } from "react";
import { RouteCategory } from "../../../model/route/routeCategory";
import {createRoute,getRouteById,updateRoute} from "../../../actions/route/route";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../layouts/header/Header";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm  } from "react-hook-form";
import { STATIC_HOST } from "../../../utils/communication";
import Map from "./map/Map";
import EditName from "../../edit/edit-name/EditName";
import UploadImage from "./upload-image/UploadImage";
import DurationAndCategory from "./duration-category/DurationAndCategory";
import EditDescription from "../../edit/edit-description/EditDescription";

export default function EditRoute() {
  const { t } = useTranslation("all");
  const [route, setRoute] = useState<Route>({} as Route);
  const params = useParams();
  const routeId = params.id;
  const navigate = useNavigate();
  const [images, setImages] = useState<any[]>([]);
  const [savable, setSavable] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionSrb, setDescriptionSrb] = useState("");
  const [descriptionCro, setDescriptionCro] = useState("");

  const formSchema = Yup.object().shape({
    name: Yup.string()
    .test("has text", t("components.validation.fieldRequired"), (value) => {
      return route.name !== "";
    }),
    nameSrb: Yup.string()
    .test("has text", t("components.validation.fieldRequired"), (value) => {
      return route.nameSrb !== "";
    }),
    nameCro: Yup.string()
    .test("has text", t("components.validation.fieldRequired"), (value) => {
      return route.nameCro !== "";
    }),
    category: Yup.number()
    .test("has text", t("components.validation.fieldRequired"), (value) => {
      return route.routeCategory.id > 0;
    }),
    duration: Yup.number()
    .test("has text", t("components.validation.fieldRequired"), (value) => {
      return route.durationDays !== 0;
    }),
  });

const validationOpt = { resolver: yupResolver(formSchema) }
const { register, handleSubmit, formState } = useForm(validationOpt);
const { errors } = formState;

const saveRoute = () => {
  let formData = new FormData();
  let newRoute = { ...route };
  newRoute.description = description;
  newRoute.descriptionSrb = descriptionSrb;
  newRoute.descriptionCro = descriptionCro;
  if (images.length > 0) formData.append("file", images[0].file);
  const blob = new Blob([JSON.stringify(newRoute)], {
    type: "application/json",
  });
  formData.append("dto", blob);
  if (newRoute.id == 0) {
    createRoute(formData).then((resp) => {
      navigate("/routes/0");
    });
  } else {
    updateRoute(formData).then(() => {
      navigate("/routes/0");
    });
  }
}

useEffect(() => {
  if (Number(routeId) !== 0) {
    getRouteById(Number(routeId)).then((response) => {
      setRoute(response.data);
      setDescription(response.data.description);
      setDescriptionSrb(response.data.descriptionSrb);
      setDescriptionCro(response.data.descriptionCro);
    });
  } else {
    let newRoute = new Route();
    newRoute.routeCategory = new RouteCategory();
    setRoute(newRoute);
  }
}, []);

  return (
    <>
      <Header background={STATIC_HOST + "front/rute/RUTE_HEADER.png"} height={88.64809081527348} gradient={STATIC_HOST + "front/rute/GRADIENT_RUTE.png"} gradientHeight={24.664602683178536}/>
      <form onSubmit={handleSubmit(saveRoute)}>
        <div className={styles.sectionRouteNews}>
          <div className={styles.routeSection}>
            <div className={styles.title}>
                <p>{route.id !== 0 ? t("components.routes.edit.title") : t("components.routes.edit.newTitle")}</p>
            </div>
            {savable && 
            <div className={styles.rowEnd}>
                <button type="submit" className={styles.btnSave}>
                  {t("components.routes.edit.save")}
                </button>
            </div>}
            {route.id !== undefined && <Map route={route} setRoute={setRoute} savable={savable} setSavable={setSavable}></Map>}
            <div className={styles.row}>
              <div className={styles.colRouteName}>
                {route.id !== undefined && <EditName name={route.name} nameSr={route.nameSrb} nameHr={route.nameCro} setName={(newName : string) => setRoute({...route, name : newName})} setNameSr={(newName : string) => setRoute({...route, nameSrb : newName})} setNameHr={(newName : string) => setRoute({...route, nameCro : newName})} title={t("components.routes.edit.name")} register={register} errors={errors}></EditName>}
              </div>
              <div className={styles.colImageUpload}>
                {route.id !== undefined && <UploadImage route={route} setRoute={setRoute} images={images} setImages={setImages}></UploadImage>}
              </div>
            </div>
            {route.id !== undefined && <DurationAndCategory route={route} setRoute={setRoute} register={register} errors={errors}></DurationAndCategory>}
            <EditDescription description={description} descriptionSr={descriptionSrb} descriptionHr={descriptionCro} setDescription={setDescription} setDescriptionSr={setDescriptionSrb} setDescriptionHr={setDescriptionCro} title={t("components.routes.edit.description")}></EditDescription>
          </div>
        <div className={styles.sectionNews}>
          <News size={6} /> 
        </div>
      </div>
    </form>
  </>
  );
}
