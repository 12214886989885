import styles from "./EditDescription.module.css"
import ZASTAVA_EN from "../../../assets/styles/icons/ZASTAVA_EN.png"
import ZASTAVA_SR from "../../../assets/styles/icons/ZASTAVA_SR.png"
import ZASTAVA_HR from "../../../assets/styles/icons/ZASTAVA_HR.png"
import { DefaultEditor } from 'react-simple-wysiwyg';
import { Form } from "react-bootstrap";

export default function EditMandatoryDescription(props : {description : string, descriptionSr : string, descriptionHr : string, setDescription : (desc : string) => void, setDescriptionSr : (desc : string) => void, setDescriptionHr : (desc : string) => void, register: any, errors : any, title : string}) {
    const {description, descriptionSr, descriptionHr, setDescription, setDescriptionSr, setDescriptionHr, register, errors, title} = props;
    return (
        <>
            <Form style={{ width: "100%", marginTop:"3vh"}}>
                <Form.Group className="mb-3">
                    <Form.Label className={styles.title}>{title}</Form.Label>
                </Form.Group>
            </Form>
            <div className={styles.rowDescription}>
                <div className={styles.countryIcon}><img width="100%" height="100%" src={ZASTAVA_EN}></img></div>
                <div className={styles.descriptionEditor}>
                    <DefaultEditor 
                        {...register('description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.errorMessage}>{errors.description?.message}</div>
            <div className={styles.rowDescription}>
                <div className={styles.countryIcon}><img width="100%" height="100%" src={ZASTAVA_SR}></img></div>
                <div className={styles.descriptionEditor}>
                    <DefaultEditor
                        {...register('descriptionSrb')}
                        value={descriptionSr}
                        onChange={(e) => setDescriptionSr(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.errorMessage}>{errors.descriptionSrb?.message}</div>
            <div className={styles.rowDescription}>
                <div className={styles.countryIcon}><img width="100%" height="100%" src={ZASTAVA_HR}></img></div>
                <div className={styles.descriptionEditor}>
                    <DefaultEditor
                        {...register('descriptionCro')}
                        value={descriptionHr}
                        onChange={(e) => setDescriptionHr(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.errorMessage}>{errors.descriptionCro?.message}</div>
        </>
    )
}