import { useState } from "react";
import Form from "react-bootstrap/Form";
import { googleLogin, login } from "../../../actions/auth/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./Form.module.css";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "react-google-login";
//import FacebookLogin from "react-facebook-login";
import { AccessTokenContainer } from "../../../dto/accessTokenContainer";
import { userHasRole } from "../../../utils/jwt";
import "../misc.css";

export default function LoginForm() {
  const { t } = useTranslation("all");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const [message, setMessage] = useState("");

  const handleChangeUser = (e: any) => {
    const inputId = e.target.id;
    const inputValue = e.target.value;
    switch (inputId) {
      case "usernameUser":
        setUser({ ...user, username: inputValue });
        break;
      case "passwordUser":
        setUser({ ...user, password: inputValue });
        break;
    }
  };

  const loginErrors = (error: any) => {
    switch (error) {
      case "ERRORS.INVALID_LOGIN":
        return t("components.auth.login.wrongCredentials");
      case "ERRORS.NOT_VALIDATED":
        return t("components.auth.login.notValidated");
      default:
        return t("components.auth.login.invalidLogin");
    }
  };

  const handleSubmitLogin = async (event: any) => {
    event.preventDefault();
    (dispatch(login(user)) as any)
      .then(() => {
        if (userHasRole("unreseted_user")) {
          navigate("/reset-password");
        } else navigate("/home");
      })
      .catch((mess: string) => {
        let message = loginErrors(mess);
        setMessage(message);
      });
  };

  const responseGoogle = (response: any) => {};

  const handleGoogleSuccess = (response: any) => {
    let dto = new AccessTokenContainer(
      JSON.stringify(response.accessToken),
      ""
    );
    (dispatch(googleLogin(dto)) as any)
      .then(() => {
        navigate("/home");
      })
      .catch((mess: string) => {});
  };
  /*
  const handleFacebookSuccess = (response: any) => {
    let dto = new AccessTokenContainer(response.accessToken, "");
    (dispatch(facebookLogin(dto)) as any)
      .then(() => {
        navigate("/home");
      })
      .catch((mess: string) => {});
  };

  const componentClicked = (data: any) => {
    //console.warn(data);
  };
  */

  return (
    <div className={styles.formContainer}>
      <Form onSubmit={handleSubmitLogin} className={styles.form}>
        <Form.Group>
          <div className={`row ${styles.labelclass}`}>
            <Form.Label>{t("components.auth.login.username")}</Form.Label>
          </div>

          <Form.Control
            id="usernameUser"
            className={styles.inputclass}
            autoFocus
            type="text"
            value={user.username}
            onChange={handleChangeUser}
          />
        </Form.Group>

        <Form.Group>
          <div className={`row ${styles.labelclass}`}>
            <Form.Label>{t("components.auth.login.password")}</Form.Label>
          </div>
          <Form.Control
            id="passwordUser"
            type="password"
            className={styles.inputclass}
            value={user.password}
            onChange={handleChangeUser}
          />
        </Form.Group>
        {message && (
          <div className={styles.messageContainer}>
            <p className={styles.errorMessage}>{message}</p>
          </div>
        )}
        <div className={styles.submitBtnContainer}>
          <button
            name="action"
            value="login"
            type="submit"
            className={styles.btnSubmit}
          >
            <p className={styles.txtSubmit}>
              {t("components.auth.login.signIn")}
            </p>
          </button>
        </div>
        <div className={styles.row}>
          <GoogleLogin
            className={styles.googleBtn}
            clientId={process.env.REACT_APP_GOOGLE_ID ?? ""}
            buttonText={t("components.auth.login.loginWithGoogle")}
            onSuccess={handleGoogleSuccess}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        {/* Removed in Issue 117 
        <div className={styles.row} style={{ marginTop: "10px" }}>
          <FacebookLogin
            containerStyle={{ width: "100%" }}
            cssClass={styles.facebookBtn}
            appId={
              process.env.REACT_APP_FACEBOOK_ID ?? ""
            }
            autoLoad={false}
            fields="name,email,picture"
            onClick={componentClicked}
            callback={handleFacebookSuccess}
            textButton={t("components.auth.login.loginWithFacebook")}
            icon="fa-facebook"
          />
        </div>
        */}
      </Form>
    </div>
  );
}
