import { DefaultModel } from "../defaultModel";
import { Role } from "./role";

export class User extends DefaultModel {
  username: string;
  email: string;
  password: string;
  repeatedPassword: string;
  firstName: string;
  lastName: string;
  address: string;
  phoneNumber: string;
  role: Role;
  clientId: number;
  roles: number[];
  language: string;
  constructor() {
    super();
    this.username = "";
    this.email = "";
    this.password = "";
    this.repeatedPassword = "";
    this.firstName = "";
    this.lastName = "";
    this.address = "";
    this.phoneNumber = "";
    this.role = new Role();
    this.clientId = 0;
    this.roles = [];
    this.language = "en";
  }
}
