import http from "../../http-common";

export class OpenRouteService {
  directionsUrl: string;
  constructor() {
    this.directionsUrl = "/api/open-route-service";
  }

  async getRoute(waypoints: any[]): Promise<string> {
    const response = await http.post(
      this.directionsUrl,
      { coordinates: waypoints },
      {
        headers: {
          Authorization:
            process.env.REACT_APP_ACCESS_TOKEN_OPEN_ROUTE_SERVICE ?? "",
        },
      }
    );

    return response.data?.routes?.[0]?.geometry ?? "";
  }
}

export const openRouteService = new OpenRouteService();
