import {
  DELETE_PRODUCT,
  LOAD_PRODUCTS,
  SELECT_PRODUCT,
} from "../actions/product/product";
import { LOAD_PRODUCT_CATEGORIES } from "../actions/product/productCategories";
import { Product } from "../model/product/product";
import { ProductCategory } from "../model/product/productCategory";

export interface ApplicationState {
  products: Product[];
  productCategories: ProductCategory[];
  selectedProduct: Product;
}

const initialState: ApplicationState = {
  products: [],
  productCategories: [],
  selectedProduct: new Product(),
};

const productReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case LOAD_PRODUCTS:
      return { ...state, products: [...payload] };
    case LOAD_PRODUCT_CATEGORIES:
      return { ...state, productCategories: [...payload] };
    case SELECT_PRODUCT:
      return { ...state, selectedProduct: payload };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((p) => p.id !== payload),
      };
    default:
      return state;
  }
};

export default productReducer;
