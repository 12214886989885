import { LOAD_NEWS, SELECT_NEWS, DELETE_NEWS } from "../actions/admin/news";
import { News } from "../model/admin/news";

export interface ApplicationState {
  news: News[];
  selectedNews: News;
}

const initialState: ApplicationState = {
  news: [],
  selectedNews: new News(),
};

const newsReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case LOAD_NEWS:
      return { ...state, news: [...payload] };
    case SELECT_NEWS:
      return { ...state, selectedNews: payload };
    case DELETE_NEWS:
      return {
        ...state,
        news: [...state.news.filter((item) => item.id !== payload)],
      };
    default:
      return state;
  }
};

export default newsReducer;
