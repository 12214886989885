import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ResetForgottenPassword.module.css";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from "../../../../rest/auth/authService";
import { ResetPasswordDto } from "../../../../dto/resetPasswordDto";
import Header from "../../../layouts/header/Header";
import headerBackground from "../../../../assets/styles/images/register-login/LOGIN_REGISTER_HEADER_POZADINA.png";
import gradient from "../../../../assets/styles/images/register-login/GRADIENT.png";

export default function ResetForgottenPassword() {
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [resetPasswordDto, setResetPasswordDto] = useState(
    new ResetPasswordDto()
  );

  const [message, setMessage] = useState(false);

  useEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
    }
  }, [searchParams]);

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("components.auth.resetPassword.passwordRequired"))
      .min(8, t("components.auth.resetPassword.passwordLengthRequired")),
    passwordConfirm: Yup.string()
      .required(t("components.auth.resetPassword.confirmPasswordRequired"))
      .oneOf(
        [Yup.ref("password")],
        t("components.auth.resetPassword.passwordsMustMatch")
      ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, setValue, formState, reset } =
    useForm(validationOpt);
  const { errors } = formState;

  const onSubmit = () => {
    authService
      .resetForgottenPassword(resetPasswordDto.password)
      .then(() => {
        navigate("/home");
      })
      .catch((error: any) => {
        setMessage(true);
      });
  };

  return (
    <>
      <Header background={headerBackground} height={52.317880794701985} gradient={gradient} gradientHeight={22.61116367076632}/>
      <div className={styles.row}>
        <div className={styles.sectionReset}>
          <div className={styles.empty}></div>
          <div className={styles.resetRow}>
            <div className={styles.titleRow}>
              <p className={styles.title}> {t("components.auth.resetPassword.title")}</p>
            </div>
            <div className={styles.formRow}>
              <div className={styles.col}></div>
              <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <div className={`row ${styles.labelclass}`}>
                    <Form.Label> {t("components.auth.resetPassword.password")}</Form.Label>
                  </div>
                  <Form.Control
                    type="password"
                    {...register("password")}
                    value={resetPasswordDto.password}
                    onChange={(e) =>
                      setResetPasswordDto({...resetPasswordDto, password: e.target.value})
                    }
                  />
                  <div className={styles.errorMessage}> {errors.password?.message} </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className={`row ${styles.labelclass}`}>
                    <Form.Label>{t("components.auth.resetPassword.repeatPassword")}</Form.Label>
                  </div>
                  <Form.Control
                    type="password"
                    {...register("passwordConfirm")}
                    value={resetPasswordDto.passwordConfirm}
                    onChange={(e) =>
                      setResetPasswordDto({...resetPasswordDto,passwordConfirm: e.target.value})
                    }
                  />
                  <div className={styles.errorMessage}>{errors.passwordConfirm?.message}</div>
                </Form.Group>
                {message && (
                  <div className={styles.messageContainer}>
                    <p className={styles.errorMessage}>{t("components.auth.resetPassword.resetFailed")}</p>
                  </div>
                )}
                <div className={styles.submitBtnContainer}>
                  <button name="action" value="reset" type="submit" className={styles.btnSubmit}>
                    <p className={styles.txtSubmit}>{t("components.auth.resetPassword.save")}</p>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
