import http from "../../http-common";

export class GoogleApiService {
    
  directionsUrl: string;
  mode : string
  constructor(){
      this.directionsUrl = "/api/google-directions"
      this.mode = "WALKING"
  };

  async getRoute(points : any[]): Promise<string>{
    let origin = points[0];
    if (origin.latitude && origin.longitude) {
      origin = `${origin.latitude}%2C${origin.longitude}`;
    }
   
    let destination = points[points.length-1];
    if (destination.latitude && destination.longitude) {
      destination = `${destination.latitude}%2C${destination.longitude}`;
    }
   
    let waypoints = points.length > 2 ? points.slice(1, -1): undefined;
    let waypointsString = "";
    if(waypoints){
      waypointsString = waypoints
      .map(waypoint => (waypoint.latitude && waypoint.longitude) ? `${waypoint.latitude}%2C${waypoint.longitude}` : waypoint)
      .join('%7C');
    }

    waypointsString = `optimize%3Atrue%7C${waypointsString}`;

    let url = `${this.directionsUrl}?origin=${origin}&waypoints=${waypointsString}&destination=${destination}&key=${process.env.REACT_APP_ACCESS_TOKEN_GOOGLE_MAPS}&mode=${this.mode.toLowerCase()}`;
    const response = await http.get(url);
    if(response.data.status === "REQUEST_DENIED")
      return "";
    return response.data.routes[0].overview_polyline.points;
  }
}

export const googleApiService = new GoogleApiService();