import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditClient.module.css";
import News from "../../home/news/News";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Client } from "../../../model/client/client";
import { create, getClientById, update } from "../../../actions/client/client";
import { File } from "../../../model/file/file";
import { Place } from "../../../model/admin/place";
import { ClientCategory } from "../../../model/client/clientCategory";
import HeaderByClientCategory from "../../layouts/header/HeaderByClientCategory";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import EditDescription from "../../edit/edit-description/EditDescription";
import EditName from "../../edit/edit-name/EditName";
import EditLogo from "./edit-logo/EditLogo";
import ResponsiblePersonAndIdNum from "./responsiblePersonAndIdNum/ResponsiblePersonAndIdNum";
import AnnouncmentAndSalesCategory from "./announcment-sales-category/AnnouncmentAndSalesCategory";
import InfoForm from "./info-form/InfoForm";
import Map from "./map/Map";
import PhotoGallery from "../../edit/photo-gallery/PhotoGallery";
import VideoGallery from "../../edit/video-gallery/VideoGallery";
import ProductsAndServices from "./products-services/ProductsAndServices";
import { Location } from "../../../model/client/location";
import Loading from "../../layouts/loading/Loading";

export default function EditClient() {
  const { t } = useTranslation("all");
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState<Client>({} as Client);
  const [images, setImages] = useState<any[]>([]);
  const [videoPaths, setVideoPaths] = useState<File[]>([]);
  const [description, setDescription] = useState(client.description);
  const [descriptionSrb, setDescriptionSrb] = useState(client.descriptionSrb);
  const [descriptionCro, setDescriptionCro] = useState(client.descriptionCro);
  const [logoImages, setLogoImages] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const formSchema = Yup.object().shape({
    name: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.name !== "";
      }
    ),
    nameSrb: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.nameSrb !== "";
      }
    ),
    nameCro: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.nameCro !== "";
      }
    ),
    address: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.address !== "";
      }
    ),
    category: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.clientCategory.id !== 0;
      }
    ),
    place: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return client.place?.id !== 0;
      }
    ),
    latitude: Yup.string().test(
      "has text",
      t("components.validation.locationRequired"),
      (value) => {
        return (
          client.location?.latitude !== 0 && client.location?.longitude !== 0
        );
      }
    ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOpt);
  const { errors } = formState;

  const saveClient = (data: any) => {
    setLoading(true);
    let formData = new FormData();
    for (let vid of client.videos) {
      if (!vid.path.includes("https://www.youtube.com/")) {
        return;
      }
    }
    client.description = description;
    client.descriptionSrb = descriptionSrb;
    client.descriptionCro = descriptionCro;
    for (let i = 0; i < logoImages.length; i++) {
      formData.append("logo", logoImages[i].file);
    }
    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }
    const blob = new Blob([JSON.stringify(client)], {
      type: "application/json",
    });
    formData.append("dto", blob);
    if (client.id == 0) {
      create(formData)
        .then(() => {
          navigate("/clients-page");
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      update(formData)
        .then(() => {
          navigate("/clients-page");
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const setClientById = () => {
    getClientById(Number(id)).then((resp) => {
      if (resp.data.place == undefined) {
        resp.data.place = new Place();
      }
      setVideoPaths(resp.data.videos);
      setClient(resp.data);
      setDescription(resp.data.description);
      setDescriptionSrb(resp.data.descriptionSrb);
      setDescriptionCro(resp.data.descriptionCro);
    });
  };

  const setNewClient = () => {
    let newClient = new Client();
    newClient.files = [];
    newClient.videos = [];
    newClient.place = new Place();
    newClient.clientCategory = new ClientCategory();
    newClient.location = new Location(0, 0);
    setClient(newClient);
    setDescription("");
    setDescriptionSrb("");
    setDescriptionCro("");
  };

  useEffect(() => {
    if (Number(id) !== 0) {
      setClientById();
    } else {
      setNewClient();
    }
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Loading />
    </div>
  ) : (
    <div>
      {client && client.id && (
        <HeaderByClientCategory client={client}></HeaderByClientCategory>
      )}
      <form onSubmit={handleSubmit(saveClient)}>
        <div className={styles.sectionClientNews}>
          <div className={styles.clientSection}>
            <div className={styles.saveRow}>
              <button type="submit" className={styles.btnSave}>
                {t("components.clients.edit.save")}
              </button>
            </div>
            <div className={styles.basicRow} style={{ marginTop: "3vh" }}>
              <div style={{ width: "50%" }}>
                <EditName
                  name={client.name}
                  nameSr={client.nameSrb}
                  nameHr={client.nameCro}
                  setName={(newName: string) =>
                    setClient({ ...client, name: newName })
                  }
                  setNameSr={(newName: string) =>
                    setClient({ ...client, nameSrb: newName })
                  }
                  setNameHr={(newName: string) =>
                    setClient({ ...client, nameCro: newName })
                  }
                  register={register}
                  errors={errors}
                  title={t("components.clients.edit.name")}
                ></EditName>
              </div>
              <div style={{ width: "50%" }}>
                <EditLogo
                  client={client}
                  setClient={setClient}
                  logoImages={logoImages}
                  setLogoImages={setLogoImages}
                ></EditLogo>
              </div>
            </div>
            <ResponsiblePersonAndIdNum
              client={client}
              setClient={setClient}
            ></ResponsiblePersonAndIdNum>
            <EditDescription
              description={description}
              descriptionSr={descriptionSrb}
              descriptionHr={descriptionCro}
              setDescription={setDescription}
              setDescriptionSr={setDescriptionSrb}
              setDescriptionHr={setDescriptionCro}
              title={t("components.clients.edit.description")}
            ></EditDescription>
            <AnnouncmentAndSalesCategory
              client={client}
              setClient={setClient}
            ></AnnouncmentAndSalesCategory>
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
        <div>
          <InfoForm
            client={client}
            setClient={setClient}
            errors={errors}
            register={register}
          ></InfoForm>
          {client !== undefined && client.location !== undefined && (
            <Map
              client={client}
              setClient={setClient}
              errors={errors}
              register={register}
            ></Map>
          )}
          <div className={styles.gallery}>
            <PhotoGallery
              files={client.files}
              setFiles={(newFiles: File[]) => {
                setClient({ ...client, files: newFiles });
              }}
              images={images}
              setImages={setImages}
            ></PhotoGallery>
            <VideoGallery
              videos={client.videos}
              setVideos={(newVideos: File[]) =>
                setClient({ ...client, videos: newVideos })
              }
              videoPaths={videoPaths}
              setVideoPaths={setVideoPaths}
            ></VideoGallery>
          </div>
          <ProductsAndServices
            client={client}
            setClient={setClient}
          ></ProductsAndServices>
        </div>
      </form>
    </div>
  );
}
