import { Marker, MarkerProps } from "react-leaflet";
import { ReactElement } from "react";
import { Icon } from "leaflet";
import { getIconByCode } from "../../utils/getIcon";

export const getMarkerSvg = (code: string): string => {
  return `
  <svg width="28.5" height="37" viewBox="0.55 -0.2 27 37"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
        <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#1c8693"></path>
    </g>
    <image x="4.5" y="4.5" width="20" height="20" xlink:href="${getIconByCode(
      code
    )}" style="filter: invert(100%);"/>
  </svg>
    `;
};

export const svgToURL = (svg: string) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
};

export interface CustomMarkerProps extends MarkerProps {
  code: string;
}

export const getCustomMarker = (code: string) => {
  return svgToURL(getMarkerSvg(code));
};

export const CustomMarker = (props: CustomMarkerProps): ReactElement => (
  <Marker
    {...props}
    icon={
      new Icon({
        iconUrl: svgToURL(getMarkerSvg(props.code)),
        iconAnchor: [12, 43],
      })
    }
  ></Marker>
);
