import styles from "./SearchNews.module.css";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { searchNewsPerPage } from "../../../../actions/admin/news";
import { useDispatch } from "react-redux";

export default function SearchNews(props: {
  size: number;
  page: number;
  searchText: string;
  setSearchText: (text: string) => void;
  setLastPage: (page: number) => void;
  canEdit: boolean;
}) {
  const { size, page, setLastPage, searchText, setSearchText, canEdit } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getNews = async () => {
    searchNewsPerPage(
      searchText,
      page,
      size
    )(dispatch).then((pages) => {
      setLastPage(pages - 1);
    });
  };

  return (
    <div className={styles.searchBoxContainer}>
      {canEdit && (
        <div className={styles.addNewsBtn}>
          <GoPlus
            color="#1c8693"
            size="5vh"
            className={styles.goPlus}
            onClick={() => navigate("/edit-news/0")}
          />
        </div>
      )}
      <div className={styles.inputSearchBoxContainer}>
        <input
          type="text"
          className={styles.inputSearchBox}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className={styles.searchBtnContainer}>
        <div className={styles.searchBtn} onClick={getNews}>
          <div className={styles.searchIcon}></div>
        </div>
      </div>
    </div>
  );
}
