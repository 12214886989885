import * as React from "react";

interface RatingProps {
  onClick: (index: number) => void;
  ratingValue: number | null;
  stars?: number;
  size?: number;
  fillColor?: string;
  emptyColor?: string;
  label?: boolean;
  className?: string;
}

const Rating: React.FC<RatingProps> = ({
  onClick,
  ratingValue = null,
  stars = 5,
  size = 25,
  fillColor = "#f1a545",
  emptyColor = "#cccccc",
  label = false,
  className = "",
}) => {
  return (
    <span
      style={
        className ? {} : { display: "inline-flex", verticalAlign: "bottom" }
      }
      className={className}
    >
      {[...Array(stars)].map((_, index: number) => (
        <span
          key={index}
          style={{
            cursor: "pointer",
            width: size,
            height: size,
          }}
          onClick={() => onClick(index + 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill={ratingValue && ratingValue > index ? fillColor : emptyColor}
          >
            <path d="M63.389 24.951L41.7 21.8 32 2.146 22.3 21.8.611 24.951l15.693 15.3L12.6 61.854 32 51.653l19.4 10.201-3.704-21.603z" />
          </svg>
        </span>
      ))}
      {label && (
        <span
          className={className ? `${className}-label` : ""}
          style={
            className
              ? {}
              : {
                  width: size,
                  height: size,
                  fontSize: size,
                  lineHeight: `${size}px`,
                  textAlign: "center",
                }
          }
        >
          {ratingValue}
        </span>
      )}
    </span>
  );
};

export default Rating;
