import React from "react";
import Nav from "../nav/Nav";
import styles from "./Header.module.css";
import HeaderImage from "./HeaderImage";

export default function HeaderWithTxt(props: {
  background: any;
  height: number;
  gradient: any;
  gradientHeight: number;
  title: string;
  golden: boolean;
}) {
  const { background, height, gradient, gradientHeight, title, golden } = props;
  return (
    <>
      <div className={styles.headerTopLine}></div>
      <div className={styles.wrapper}>
        <Nav gradient={gradient} height={gradientHeight} />
      </div>
      <HeaderImage
        background={background}
        height={height}
        title={title}
        golden={golden}
      ></HeaderImage>
    </>
  );
}
