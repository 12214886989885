import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT,
} from "../actions/auth/auth";
import { GET_CURRENT_USER, UPDATE_PROFILE_INFO } from "../actions/user/user";

const initialState = {
  token: localStorage.getItem("token"),
  currentUser: null,
};

const authReducer = (
  state = initialState,
  { type, payload }: { type: any; payload: any }
): any => {
  switch (type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, token: payload.token };
    case LOGIN_USER_FAIL:
      return { ...state, token: null, currentUser: null };
    case LOGOUT:
      return { ...state, token: null, currentUser: null };
    case UPDATE_PROFILE_INFO:
      return { ...state, currentUser: payload };
    case GET_CURRENT_USER:
      return { ...state, currentUser: payload };
    default:
      return state;
  }
};

export default authReducer;
