import styles from "./ClientDetailsPage.module.css";
import News from "../../home/news/News";
import ClientDetails from "./client-details/ClientDetails";
import { getClientById } from "../../../actions/client/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Client } from "../../../model/client/client";
import HeaderByClientCategory from "../../layouts/header/HeaderByClientCategory";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import PhotoVideoSection from "../photoVideoSection/PhotoVideoSection";
import Title from "../../layouts/title/Title";
import Comments from "./comments/Comments";
import { useTranslation } from "react-i18next";

function ClientDetailsPage() {
  const [client, setClient] = useState<Client>();
  const params = useParams();
  const id = params.id;
  const { t } = useTranslation("all");

  useEffect(() => {
    getClient();
    window.scrollTo(0, 0);
  }, []);

  const getClient = async () => {
    getClientById(Number(id)).then((resp) => {
      setClient(resp.data);
    });
  };

  return (
    <div>
      {!!client?.id && <HeaderByClientCategory client={client} />}
      <div className={styles.sectionClientNews}>
        <div className={styles.sectionClient}>
          {client && <ClientDetails client={client} />}
        </div>
        <div className={styles.sectionNews}>
          <News size={6} />
        </div>
      </div>
      <DownloadApp />
      {client && (
        <div className={styles.row}>
          <div style={{ width: "100%" }}>
            <PhotoVideoSection client={client} />
            <Title
              title={t("components.clients.client.commentsTitleDark")}
              icon="comment"
            />
            <Comments client={client} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientDetailsPage;
