import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditNews.module.css";
import NewsComponent from "../../home/news/News";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../model/file/file";
import { News } from "../../../model/admin/news";
import { create, getNewsById, update } from "../../../actions/admin/news";
import Header from "../../layouts/header/Header";
import gradient from "../../../assets/styles/images/vesti/VESTI_GRADIENT.png";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PhotoGallery from "../../edit/photo-gallery/PhotoGallery";
import VideoGallery from "../../edit/video-gallery/VideoGallery";
import EditName from "../../edit/edit-name/EditName";
import EditMandatoryDescription from "../../edit/edit-description/EditMandatoryDescription";
import { STATIC_HOST } from "../../../utils/communication";

export default function EditNews() {
  const { id } = useParams();
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const [news, setNews] = useState<News>({} as News);
  const [images, setImages] = useState<any[]>([]);
  const [videoPaths, setVideoPaths] = useState<File[]>([]);
  const [description, setDescription] = useState("");
  const [descriptionSrb, setDescriptionSrb] = useState("");
  const [descriptionCro, setDescriptionCro] = useState("");

  const formSchema = Yup.object().shape({
    title: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return news.title !== "";
      }
    ),
    titleSrb: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return news.titleSrb !== "";
      }
    ),
    titleCro: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return news.titleCro !== "";
      }
    ),
    description: Yup.object().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return description !== "";
      }
    ),
    descriptionSrb: Yup.object().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return descriptionSrb !== "";
      }
    ),
    descriptionCro: Yup.object().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => {
        return descriptionCro !== "";
      }
    ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOpt);
  const { errors } = formState;

  const saveNews = () => {
    let formData = new FormData();
    for (let vid of news.videos) {
      if (!vid.path.includes("https://www.youtube.com/")) {
        return;
      }
    }
    news.description = description;
    news.descriptionSrb = descriptionSrb;
    news.descriptionCro = descriptionCro;
    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }
    const blob = new Blob([JSON.stringify(news)], {
      type: "application/json",
    });
    formData.append("dto", blob);
    if (news.id == undefined || news.id == 0) {
      create(formData).then(() => {
        navigate("/home");
      });
    } else {
      update(formData).then(() => {
        navigate("/home");
      });
    }
  };

  const setNewsById = () => {
    getNewsById(Number(id)).then((resp) => {
      setNews(resp.data);
      setVideoPaths(resp.data.videos);
      setDescription(resp.data.description);
      setDescriptionSrb(resp.data.descriptionSrb);
      setDescriptionCro(resp.data.descriptionCro);
    });
  };

  const setNewNews = () => {
    let newNews = new News();
    newNews.files = [];
    newNews.videos = [];
    setNews(newNews);
    setDescription("");
    setDescriptionSrb("");
    setDescriptionCro("");
  };

  useEffect(() => {
    if (Number(id) != 0) {
      setNewsById();
    } else {
      setNews({ ...news, files: [], videos: [] });
    }
  }, []);

  useEffect(() => {
    if (Number(id) != 0) {
      setNewsById();
    } else {
      setNewNews();
    }
  }, [id]);

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/vesti/VESTI_HEADER.png"}
        height={57.06914344685242}
        gradient={gradient}
        gradientHeight={24.664602683178536}
      />
      <form onSubmit={handleSubmit(saveNews)}>
        <div className={styles.sectionEditNews}>
          <div className={styles.editSection}>
            <div className={styles.saveRow}>
              <button type="submit" className={styles.btnSave}>
                {t("components.news.edit.save")}
              </button>
            </div>
            <EditName
              name={news.title}
              nameSr={news.titleSrb}
              nameHr={news.titleCro}
              setName={(name: string) => setNews({ ...news, title: name })}
              setNameSr={(name: string) => setNews({ ...news, titleSrb: name })}
              setNameHr={(name: string) => setNews({ ...news, titleCro: name })}
              register={register}
              errors={errors}
              title={t("components.news.edit.newsTitle")}
            ></EditName>
            <EditMandatoryDescription
              description={description}
              descriptionSr={descriptionSrb}
              descriptionHr={descriptionCro}
              setDescription={setDescription}
              setDescriptionSr={setDescriptionSrb}
              setDescriptionHr={setDescriptionCro}
              register={register}
              errors={errors}
              title={t("components.news.edit.description")}
            ></EditMandatoryDescription>
            <PhotoGallery
              files={news.files}
              setFiles={(newFiles: File[]) => {
                setNews({ ...news, files: newFiles });
              }}
              images={images}
              setImages={setImages}
            ></PhotoGallery>
            <VideoGallery
              videos={news.videos}
              setVideos={(newVideos: File[]) =>
                setNews({ ...news, videos: newVideos })
              }
              videoPaths={videoPaths}
              setVideoPaths={setVideoPaths}
            ></VideoGallery>
          </div>
          <div className={styles.sectionNews}>
            <NewsComponent size={6} />
          </div>
        </div>
      </form>
    </div>
  );
}
