import styles from "./FilterAndPagination.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect, useState } from "react";
import "../../leaflet-legend.css";
import { getClients } from "../../../../actions/client/client";
import { Client } from "../../../../model/client/client";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RouteFilter } from "../../../../filter/routeFilter";
import IKONICA_RUTA from "../../../../assets/styles/images/rute/IKONICA_RUTA.png";
import IKONICA_TRAJANJE from "../../../../assets/styles/images/rute/IKONICA_TRAJANJE.png";
import IKONICA_OCENA from "../../../../assets/styles/images/rute/IKONICA_OCENA.png";
import DOTS from "../../../../assets/styles/images/rute/DOTS.png";
import DIVIDER from "../../../../assets/styles/images/rute/DIVIDER.png";
import { nameField, nameOf } from "../../../../utils/i18n";
import Select from "react-select";
import { selectStyles } from "../../../../utils/customSelectStyle";
import { userLoggedIn } from "../../../../utils/jwt";
import FilterTitle from "../../../layouts/title/FilterTitle";
import { RouteCategory } from "../../../../model/route/routeCategory";
import Arrows from "../../../layouts/arrow/Arrows";
import { routesRestService } from "../../../../rest/route/routeService";
import { routeCategoryRestService } from "../../../../rest/route/routeCategoryService";
import Rating from "../../../../Rating/Rating";

export default function FilterAndPagination(props: {
  setRoutes: (routes: Route[]) => void;
  setSelectedRoute: (route: Route) => void;
  page: number;
  setPage: (page: number) => void;
  routeFilter: RouteFilter;
  setRouteFilter: (filter: RouteFilter) => void;
}) {
  const {
    setRoutes,
    setSelectedRoute,
    page,
    setPage,
    routeFilter,
    setRouteFilter,
  } = props;
  const { t } = useTranslation("all");
  const [routeCategories, setRouteCategories] = useState<RouteCategory[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [lastPage, setLastPage] = useState(1);
  const size = 4;

  const durationList = Array.from({ length: 10 }).map((_, i) => ({
    value: i + 1,
    text: `${i + 1} ${
      i ? t("components.routes.days") : t("components.routes.day")
    }`,
  }));

  useEffect(() => {
    filter();
  }, [page]);

  const filter = () => {
    routesRestService.filterRoutes(routeFilter, page, size).then((resp) => {
      setRoutes(resp.data);
      setLastPage(Number(resp.headers["x-total-page"]) - 1);
    });
  };

  function onSelectClient(selectedList: Client[], selectedItem: Client) {
    const newClientIds = [...routeFilter.clientIds, selectedItem.id];
    setRouteFilter({ ...routeFilter, clientIds: newClientIds });
  }

  function onRemoveClient(selectedList: Client[], selectedItem: Client) {
    const newClientIds = routeFilter.clientIds.filter(
      (id) => id !== selectedItem.id
    );
    setRouteFilter({ ...routeFilter, clientIds: newClientIds });
  }

  useEffect(() => {
    routesRestService
      .filterRoutes(new RouteFilter(), page, size)
      .then((resp) => {
        setRoutes(resp.data);
        setLastPage(Number(resp.headers["x-total-page"]) - 1);
        setSelectedRoute(resp.data[0]);
      });
    routeCategoryRestService.findAll().then((resp) => {
      setRouteCategories(resp.data);
    });
    getClients().then((response) => setClients(response.data));
    setRouteFilter(new RouteFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.filterTitle}>
        <FilterTitle title={t("components.routes.filter.title")}></FilterTitle>
      </div>
      <div className={styles.filterAndPaginationSection}>
        <div className={styles.plusLink}>
          {userLoggedIn() && (
            <Link
              className={styles.plusLinkDesign}
              style={{ textDecoration: "none" }}
              to="/edit-route/0"
            >
              + {t("components.routes.addRoute")}
            </Link>
          )}
        </div>
        <div className={styles.filterCol}>
          <div className={styles.filterSection}>
            <div className={styles.filterCategory}>
              <div className={styles.categoryRow}>
                <div className={styles.marginTop}>
                  <div className={styles.filterTitlesColor}>
                    {t("components.routes.filter.category")}
                  </div>
                </div>
                <div className={styles.routeIcon}>
                  <img src={IKONICA_RUTA} width="100%" height="100%" alt="" />
                </div>
              </div>
              <div className={styles.row} style={{ marginTop: "5px" }}>
                <div className={styles.categories}>
                  <Select
                    options={routeCategories}
                    onChange={(item) => {
                      if (item)
                        setRouteFilter({ ...routeFilter, category: item.id });
                    }}
                    getOptionLabel={(routeCategory) => nameOf(routeCategory)}
                    placeholder={t("components.routes.filter.category") + "..."}
                    styles={selectStyles}
                  />
                </div>
              </div>
            </div>
            <div className={styles.divider}>
              <img src={DIVIDER} width="100%" height="100%" alt="" />
            </div>
            <div className={styles.filterCategory}>
              <div className={styles.categoryRow}>
                <div className={styles.marginTop}>
                  <div className={styles.filterTitlesColor}>
                    {t("components.routes.filter.duration")}
                  </div>
                </div>
                <div className={styles.routeIcon}>
                  <img
                    src={IKONICA_TRAJANJE}
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.row} style={{ marginTop: "5px" }}>
                <div className={styles.categories}>
                  <Select
                    options={durationList}
                    onChange={(item) => {
                      if (item)
                        setRouteFilter({
                          ...routeFilter,
                          duration: item.value,
                        });
                    }}
                    getOptionLabel={(item) => item.text}
                    placeholder={t("components.routes.filter.duration") + "..."}
                    styles={selectStyles}
                  />
                </div>
              </div>
            </div>
            <div className={styles.divider}>
              <img src={DIVIDER} width="100%" height="100%" alt="" />
            </div>
            <div className={styles.filterRate}>
              <div className={styles.row}>
                <div className={styles.marginTop}>
                  <div className={styles.filterTitlesColor}>
                    {t("components.routes.filter.minRate")}
                  </div>
                </div>
                <div className={styles.rateIcon}>
                  <img src={IKONICA_OCENA} width="100%" height="100%" alt="" />
                </div>
              </div>
              <div className={styles.row}>
                <div style={{ marginRight: "1vw" }}>
                  <Rating
                    ratingValue={routeFilter.rate}
                    size={20}
                    fillColor="orange"
                    emptyColor="gray"
                    onClick={(rate) => setRouteFilter({ ...routeFilter, rate })}
                  />
                </div>
              </div>
            </div>
            <div className={styles.divider}>
              <img src={DIVIDER} width="100%" height="100%" alt="" />
            </div>
            <div className={styles.filterClient}>
              <div className={styles.row}>
                <div className={styles.marginTop}>
                  <div className={styles.filterTitlesColor}>
                    {t("components.routes.filter.client")}
                  </div>
                </div>
                <div className={styles.dotsIcon}>
                  <img src={DOTS} width="100%" height="100%" alt="" />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.clients}>
                  <Multiselect
                    style={{
                      chips: { background: "#197d89" },
                      searchBox: {
                        borderRadius: "0.5vw",
                        backgroundColor: "white",
                        width: "65%",
                        marginLeft: "3.2vw",
                      },
                    }}
                    placeholder={t("components.routes.filter.chooseClient")}
                    options={clients}
                    onSelect={onSelectClient}
                    onRemove={onRemoveClient}
                    displayValue={nameField()}
                  />
                </div>
              </div>
            </div>
            <div className={styles.filterSearch}>
              <div className={styles.filterIcon} onClick={filter}></div>
            </div>
          </div>
        </div>
        <Arrows page={page} lastPage={lastPage} changePage={setPage} />
      </div>
    </>
  );
}
