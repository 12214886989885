import styles from "./ResponsiblePersonAndIdNum.module.css"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Client } from "../../../../model/client/client";
import { Form, InputGroup } from "react-bootstrap";

export default function ResponsiblePersonAndIdNum(props: {client : Client, setClient : (client : Client) => void} ) {
    const {client, setClient} = props;
    const [isIDNaN, setIsIDNaN] = useState(false);
    const { t } = useTranslation('all');

    return (
        <>
            <Form style={{ width: "100%", marginTop: "4vh" }}>
                <Form.Group className="mb-3">
                    <InputGroup>
                        <Form.Group className="mb-3" style={{ width: "50%" }}>
                            <Form.Label className={styles.title}>{t("components.clients.edit.responsiblePerson")}</Form.Label>
                            <Form.Control type="text" value={client.responsiblePerson} onChange={(e) => setClient({ ...client, responsiblePerson: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ width: "50%", paddingLeft: "2%" }}>
                            <Form.Label className={styles.title}>{t("components.clients.edit.identificationNumber")}</Form.Label>
                            <Form.Control type="text" value={client.identificationNumber}
                            onChange={(e) => {setIsIDNaN(false); isNaN(Number(e.target.value)) ? setIsIDNaN(true) : setClient({ ...client, identificationNumber: e.target.value })}} />
                        </Form.Group>
                    </InputGroup>
                </Form.Group>
            </Form>
            { isIDNaN &&
            <div className={styles.errorMessage} style={{marginLeft:"52%"}}>{t("components.validation.fieldHasToBeNumber")}</div>}
        </>
    )
}