import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function DeleteModal(props : {show : boolean, onHide : () => void, onDeleteHandle : () => void}) {
    const { t } = useTranslation('all');
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("components.users.delete.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {t("components.users.delete.message")}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>{t("components.users.delete.close")}</Button>
          <Button variant="danger" onClick={() => props.onDeleteHandle()}>{t("components.users.delete.delete")}</Button>
        </Modal.Footer>
      </Modal>
    );
  }