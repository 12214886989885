import styles from "./ProductsAndServices.module.css";
import { Client } from "../../../../model/client/client";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductCard from "../../../product/product-card/ProductCard";
import ServiceCard from "../../../service/service-card/ServiceCard";
import { findServicesByClientId } from "../../../../actions/client_service/clientService";
import { findProductsByClientId } from "../../../../actions/product/product";
import Title from "../../../layouts/title/Title";

export default function ProductsAndServices(props: {
  client: Client;
  setClient: (client: Client) => void;
}) {
  const { client, setClient } = props;
  const { t } = useTranslation("all");

  const handleDeleteService = () => {
    findServicesByClientId(client.id).then((resp) => {
      setClient({ ...client, clientServices: resp.data });
    });
  };

  const handleDeleteProduct = () => {
    findProductsByClientId(client.id).then((resp) => {
      setClient({ ...client, products: resp.data });
    });
  };

  return (
    <>
      {client.products && (
        <Title
          title={t("components.clients.client.products")}
          icon="balon"
        ></Title>
      )}
      {client.products && (
        <div className={styles.wrapRow}>
          {client.products.length > 0 &&
            client.products.map((product, index) => (
              <div key={index}>
                <ProductCard
                  product={product}
                  handleDelete={handleDeleteProduct}
                />
              </div>
            ))}
          <div className={styles.plusLink}>
            <Link className={styles.plusLinkDesign} to="/edit-product/0">
              + {t("components.clients.edit.addProduct")}
            </Link>
          </div>
        </div>
      )}
      {client.clientServices && (
        <Title
          title={t("components.clients.client.services")}
          icon="balon"
        ></Title>
      )}
      {client.clientServices && (
        <div className={styles.wrapRow}>
          {client.clientServices.map((service, index) => (
            <div key={index}>
              <ServiceCard
                service={service}
                handleDelete={handleDeleteService}
              />
            </div>
          ))}
          <div className={styles.plusLink}>
            <Link className={styles.plusLinkDesign} to="/edit-service/0">
              + {t("components.clients.edit.addService")}
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
