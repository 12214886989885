import styles from "./ClientsPage.module.css";
import News from "../../home/news/News";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../layouts/header/Header";
import { STATIC_HOST } from "../../../utils/communication";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import FilterTitle from "../../layouts/title/FilterTitle";
import { ClientFilter } from "../../../filter/clientFilter";
import Filter from "./filter/Filter";
import Clients from "./clients/Clients";

export default function ClientsPage() {
  const { t } = useTranslation("all");

  const [filter, setFilter] = useState(new ClientFilter());

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/partneri/PARTNERI_HEADER_POZADINA.png"}
        height={84}
        gradient={STATIC_HOST + "front/partneri/GRADIENT.png"}
        gradientHeight={25}
      />
      <div className={styles.clientsPage}>
        <div className={styles.sectionClientsNews}>
          <div className={styles.clientsSection}>
            <TitleDarkLight
              titleDark={t("components.clients.titleDark")}
              titleLight={t("components.clients.titleLight")}
            />
            <FilterTitle
              title={t("components.clients.filter.title")}
              style={{ marginRight: "-4.5%" }}
            ></FilterTitle>
            <Filter setFilter={setFilter} />
            <div className={styles.clients}>
              <Clients
                type="K"
                filter={filter}
                titleDark={t("components.home.clients")}
                titleLight={t("components.home.users")}
              />
              <Clients
                type="P"
                filter={filter}
                titleDark={t("global.eatpannonia")}
                titleLight={t("components.home.clients")}
              />
              <Clients
                type="S"
                filter={filter}
                titleDark={t("components.home.clients")}
                titleLight={t("components.home.collaborators")}
              />
            </div>
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
