import styles from "./ServicePage.module.css";
import { ClientService } from "../../../model/client_service/clientService";
import { useEffect, useState } from "react";
import ServiceDetails from "./ServiceDetails";
import { getServiceById } from "../../../actions/client_service/clientService";
import { useParams } from "react-router-dom";
import HeaderByClientCategory from "../../layouts/header/HeaderByClientCategory";
import DownloadApp from "../../layouts/download_app/DownloadApp";
import PhotoVideoSection from "../../client/photoVideoSection/PhotoVideoSection";

export default function SingleService() {
  const [service, setService] = useState<ClientService>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    getServiceById(+id).then((resp) => setService(resp.data));
  }, [id]);

  if (!service) return <DownloadApp />;

  return (
    <div>
      <HeaderByClientCategory client={service.client} />
      <div className={styles.servicePage}>
        <ServiceDetails service={service} />
      </div>
      <DownloadApp />
      <PhotoVideoSection client={service.client} />
    </div>
  );
}
