import I18n from "i18next";
import en from "../assets/locales/en.json";
import sr from "../assets/locales/sr.json";
import hr from "../assets/locales/hr.json";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

//I18n.locale = navigator.language;

const resources = {
  en: { all: en },
  sr: { all: sr },
  hr: { all: hr },
};

const supportedLangs = ["en", "sr", "hr"];

var langCodeFromStorage = localStorage.getItem("languageCode");
var systemLang = navigator.language;
var lang = "en";
if (langCodeFromStorage) {
  if (supportedLangs.includes(langCodeFromStorage)) {
    lang = langCodeFromStorage;
  }
} else {
  if (supportedLangs.includes(systemLang)) {
    lang = systemLang;
  }
  localStorage.setItem("languageCode", lang);
}

I18n.use(Backend)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: lang,
    lng: lang,
    keySeparator: ".",
    react: {
      useSuspense: false,
    },
  })
  .then();

export const nameOf = (obj?: {
  name: string;
  nameSrb: string;
  nameCro: string;
}) => {
  if (!obj) return "";
  if (I18n.language === "sr") return obj.nameSrb;
  if (I18n.language === "hr") return obj.nameCro;
  return obj.name;
};

export const titleOf = (obj?: {
  title: string;
  titleSrb: string;
  titleCro: string;
}) => {
  if (!obj) return "";
  if (I18n.language === "sr") return obj.titleSrb;
  if (I18n.language === "hr") return obj.titleCro;
  return obj.title;
};

export const descriptionOf = (obj?: {
  description: string;
  descriptionSrb: string;
  descriptionCro: string;
}) => {
  if (!obj) return "";
  if (I18n.language === "sr") return obj.descriptionSrb;
  if (I18n.language === "hr") return obj.descriptionCro;
  return obj.description;
};

export const nameField = () => {
  return I18n.language === "sr"
    ? "nameSrb"
    : I18n.language === "hr"
    ? "nameCro"
    : "name";
};

export default I18n;
