import { Client } from "../../../../model/client/client";
import { nameOf } from "../../../../utils/i18n";
import { useTranslation } from "react-i18next";

export default function Categories(props: { client: Client }) {
  const { client } = props;
  const { t } = useTranslation("all");
  return (
    <div style={{ marginLeft: "1.5em" }}>
      <b>{t("components.clients.client.announcmentCategory")}</b>
      <ul>
        {client.announcementCategories.map((c, i) => (
          <li key={i}>{nameOf(c)}</li>
        ))}
      </ul>
      <b>{t("components.clients.client.salesCategory")}</b>
      <ul>
        {client.salesCategories.map((c, i) => (
          <li key={i}>{nameOf(c)}</li>
        ))}
      </ul>
      {client.responsiblePerson && (
        <b>{t("components.clients.client.responsiblePerson")}</b>
      )}
      {client.responsiblePerson && (
        <ul>
          <li>{client.responsiblePerson}</li>
        </ul>
      )}
    </div>
  );
}
