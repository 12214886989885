import { LOAD_CLIENT_CATEGORIES } from '../actions/client/clientCategory';
import { ClientCategory } from '../model/client/clientCategory';

export interface ApplicationState {
    clientCategories: ClientCategory[]
}

const initialState: ApplicationState = {
    clientCategories: []
};

const clientCategoryReducer = (state = initialState, { type, payload }: {type: any, payload: any}): any => {
  switch (type) {
    case LOAD_CLIENT_CATEGORIES:
        return {...state, clientCategories: payload};
    default:
      return state;
  }
};

export default clientCategoryReducer;