import styles from "./Map.module.css";
import { Route } from "../../../../model/route/route";
import { useEffect, useState } from "react";
import "../../leaflet-legend.css";
import { Client } from "../../../../model/client/client";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { TiDeleteOutline } from "react-icons/ti";
import { Icon } from "leaflet";
import { getCustomMarker } from "../../../marker/CustomMarker";
import { clientsRestService } from "../../../../rest/client/clientService";
import { directionsService } from "../../../../rest/external_api/directionsService";
import { ClientCategory } from "../../../../model/client/clientCategory";
import { loadCategories } from "../../../../actions/client/clientCategory";
import { Location } from "../../../../model/client/location";
import { nominatimApi } from "../../../../rest/external_api/nominatimApi";
import { Form } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ClientType } from "../../../../model/client/clientType";
import { loadTypes } from "../../../../actions/client/clientType";
import {
  create,
  deleteClientWithoutDispatch,
} from "../../../../actions/client/client";

var L = require("leaflet");
var map: any;
var polyUtil = require("polyline-encoded");

export default function Map(props: {
  route: Route;
  setRoute: (route: Route) => void;
  savable: boolean;
  setSavable: (savable: boolean) => void;
}) {
  const { t } = useTranslation("all");
  const { route, setRoute, savable, setSavable } = props;
  const [clientsOptions, setClientsOptions] = useState<Client[]>([]);
  const [clientCategories, setClientCategories] = useState<ClientCategory[]>(
    []
  );
  const [clientTypes, setClientTypes] = useState<ClientType[]>([]);

  const [newLatitude, setNewLatitude] = useState<number>(0);
  const [newLongitude, setNewLongitude] = useState<number>(0);
  const [newClientName, setNewClientName] = useState("");

  const [invalidCoordinates, setInvalidCoordinates] = useState<boolean>(false);
  const [duplicateCoordinate, setDuplicateCoordinate] =
    useState<boolean>(false);

  useEffect(() => {
    loadCategories().then((resp) => {
      setClientCategories(resp.data);
    });
    loadTypes().then((resp) => {
      setClientTypes(resp.data);
    });
  }, []);

  const handleDrag = (newClientsList: Client[]) => {
    if (JSON.stringify(newClientsList) === JSON.stringify(route.clientInfo)) {
      return;
    }
    setRoute({ ...route, clientInfo: newClientsList });
    addMarkersToMap(newClientsList);
  };

  const addClientToClientList = (newClient: Client) => {
    let newClientsList = [...route.clientInfo];
    newClientsList.push(newClient);
    setRoute({ ...route, clientInfo: newClientsList });
    addMarkerToMap(newClient, newClientsList.length);
    setClientsOptions(
      clientsOptions.filter(function (cl: Client) {
        return cl.id !== newClient.id;
      })
    );
  };

  const addClientToMap = (e: any) => {
    if (e.target.value !== -1) {
      let selectedClient = clientsOptions[e.target.value];
      addClientToClientList(selectedClient);
    }
  };

  const onRemoveClick = (item: Client) => {
    let newClientsList = route.clientInfo.filter(function (cl: Client) {
      return cl.id !== item.id;
    });
    setRoute({ ...route, clientInfo: newClientsList });
    addMarkersToMap(newClientsList);
    if (item.isRoutePoint) {
      deleteClientWithoutDispatch(item.id);
      return;
    }
    let newClientOptions = [...clientsOptions];
    newClientOptions.push(item);
    setClientsOptions(newClientOptions);
  };

  const deleteMapLayers = () => {
    if (!map) return;
    map.eachLayer(function (layer: any) {
      map.removeLayer(layer);
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
  };

  const addMarkersToMap = (clients: Client[]) => {
    deleteMapLayers();
    setSavable(false);
    for (let i = 0; i < clients.length; i++) {
      L.marker([clients[i].location.latitude, clients[i].location.longitude], {
        icon: new Icon({
          iconUrl: getCustomMarker(clients[i].clientCategory.code),
          iconAnchor: [8, 30],
        }),
      }).addTo(map);
    }
  };

  const addMarkerToMap = (cl: Client, index: number) => {
    setSavable(false);
    L.marker([cl.location.latitude, cl.location.longitude], {
      icon: new Icon({
        iconUrl: getCustomMarker(cl.clientCategory.code),
        iconAnchor: [8, 30],
      }),
    }).addTo(map);
  };

  const showRoute = () => {
    let waypoints = route.clientInfo.map((ci) => [
      ci.location.latitude,
      ci.location.longitude,
    ]);
    let waypointsForORS = route.clientInfo.map((ci) => [
      ci.location.longitude,
      ci.location.latitude,
    ]);
    directionsService
      .getRoute(waypoints, waypointsForORS)
      .then((encoded: string) => {
        setRoute({ ...route, geoJSON: encoded });
        const latlngs = encoded ? polyUtil.decode(encoded) : waypoints;
        let polyline = L.polyline(latlngs).addTo(map);
        let featureGroup = L.featureGroup([polyline]);
        map.fitBounds(featureGroup.getBounds());
        setSavable(true);
      });
  };

  const setClientOptions = () => {
    clientsRestService.findAll().then((resp) => {
      const newClientOptions = resp.data.filter(
        (client) => !route.clientInfo.some((ci) => ci.id === client.id)
      );
      setClientsOptions(newClientOptions);
    });
  };

  const drawRoute = () => {
    deleteMapLayers();
    addMarkersToMap(route.clientInfo);
    const latlngs = polyUtil.decode(route.geoJSON);
    const polyline = L.polyline(latlngs).addTo(map);
    const featureGroup = L.featureGroup([polyline]);
    map.fitBounds(featureGroup.getBounds());
    setSavable(true);
  };

  useEffect(() => {
    map = L.map("map", {
      center: [45.2396, 19.8227],
      zoom: 10,
    });
    setClientOptions();
    if (route.id !== 0) {
      drawRoute();
    } else {
      deleteMapLayers();
    }
  }, []);

  const isDuplicatePin = (longitude: number, latitude: number): boolean => {
    var duplicate = false;
    for (let client of route.clientInfo) {
      if (
        client.location.longitude === longitude &&
        client.location.latitude === latitude
      ) {
        setDuplicateCoordinate(true);
        duplicate = true;
        break;
      }
    }
    return duplicate;
  };

  const saveClient = async (client: Client): Promise<number> => {
    let formData = new FormData();
    const blob = new Blob([JSON.stringify(client)], {
      type: "application/json",
    });
    formData.append("dto", blob);
    const result = await create(formData);
    return result.data.id;
  };

  const addEmptyClientToMap = async () => {
    var newClient = new Client();

    if (isDuplicatePin(newLongitude, newLatitude)) return;
    nominatimApi
      .validateLocation(newLongitude, newLatitude)
      .then(async (resp) => {
        if (resp) {
          newClient.name =
            newClientName.trim() === ""
              ? newLatitude + ", " + newLongitude
              : newClientName;
          const category = clientCategories.find(
            (category) => category.code === "C"
          );
          if (category) newClient.clientCategory = category;

          const type = clientTypes.find((type) => type.code === "P");
          if (type) newClient.type = type;
          const location = new Location(newLatitude, newLongitude);

          newClient.location = location;
          newClient.isRoutePoint = true;
          newClient.id = await saveClient(newClient);
          addClientToClientList(newClient);

          setNewLatitude(0);
          setNewLongitude(0);
          setNewClientName("");
        } else {
          setInvalidCoordinates(true);
        }
      });
  };

  const resetErrors = () => {
    setDuplicateCoordinate(false);
    setInvalidCoordinates(false);
  };

  const onLatChanged = (e: string) => {
    setNewLatitude(Number(e));
    resetErrors();
  };

  const onLonChanged = (e: string) => {
    setNewLongitude(Number(e));
    resetErrors();
  };

  return (
    <>
      {!savable && (
        <div className={styles.rowEnd}>
          <button type="button" className={styles.btnShow} onClick={showRoute}>
            {t("components.routes.edit.showRoute")}
          </button>
        </div>
      )}
      <div className={styles.mapSection}>
        <div className={styles.map} id="map"></div>
        <div>
          <div className={styles.dropdown}>
            <ReactSortable
              list={route.clientInfo}
              setList={(newState) => handleDrag(newState)}
            >
              {route.clientInfo.map((item: Client) => (
                <div key={item.id}>
                  {item.name}{" "}
                  <TiDeleteOutline
                    size="20"
                    onClick={() => onRemoveClick(item)}
                  ></TiDeleteOutline>{" "}
                </div>
              ))}
            </ReactSortable>
            <select
              onChange={(e) => addClientToMap(e)}
              style={{ border: "0px", width: "100%", textAlign: "center" }}
            >
              <option value={-1}>
                {t("components.routes.edit.selectClient")}..
              </option>
              {clientsOptions.map((item: Client, index: number) => (
                <option key={item.id} value={index}>
                  {" "}
                  {item.name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.pointform}>
            <div className={styles.pointInputGroup}>
              <Form.Group>
                <div>
                  <label>{t("components.clients.client.latitude")}</label>
                  <Form.Control
                    style={{ height: "25px" }}
                    type="number"
                    onChange={(e) => {
                      onLatChanged(e.target.value);
                    }}
                    value={newLatitude}
                  />
                </div>
                <div>
                  <label>{t("components.clients.client.longitude")}</label>
                  <Form.Control
                    style={{ height: "25px" }}
                    type="number"
                    onChange={(e) => onLonChanged(e.target.value)}
                    value={newLongitude}
                  />
                </div>
                <div>
                  <label>{t("components.clients.client.coordinate")}</label>
                  <Form.Control
                    style={{ height: "25px" }}
                    type="text"
                    onChange={(e) => setNewClientName(e.target.value)}
                    value={newClientName}
                  />
                </div>
              </Form.Group>
              {invalidCoordinates && (
                <span className={styles.errorMessage}>
                  {t("components.clients.client.error.nonExtistingCoordinate")}
                </span>
              )}
              {duplicateCoordinate && (
                <span className={styles.errorMessage}>
                  {t("components.clients.client.error.duplicateCoordinate")}
                </span>
              )}
              <div
                style={{ paddingBottom: "5px", paddingTop: "5px" }}
                className={`row`}
              >
                <AiOutlinePlusCircle
                  onClick={(e) => addEmptyClientToMap()}
                ></AiOutlinePlusCircle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
