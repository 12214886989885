import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { User } from "../../../../model/user/user";
import styles from "../edit/EditUser.module.css";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  updateProfileInfo,
} from "../../../../actions/user/user";

export function EditProfileModal(props: { show: boolean; onHide: () => void }) {
  const { t } = useTranslation("all");
  const currentUser = useSelector<any, User>((state) => state.auth.currentUser);
  const [user, setUser] = useState(currentUser ?? new User());
  const dispatch = useDispatch();
  const formSchema = Yup.object().shape({
    email: Yup.string().email(t("components.users.edit.emailRequired")),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOpt);
  const { errors } = formState;

  useEffect(() => {
    getCurrentUser(dispatch);
  }, [dispatch]);

  useEffect(() => setUser(currentUser ?? new User()), [currentUser]);

  const onSubmit = () => {
    updateProfileInfo(user, dispatch).then(() => props.onHide());
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ marginTop: 30 }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.username")}</Form.Label>
            <Form.Control type="text" value={user.username} disabled={true} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.email")}</Form.Label>
            <Form.Control
              type="text"
              {...register("email")}
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <div className={styles.errorMessage}>{errors.email?.message}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.firstName")}</Form.Label>
            <Form.Control
              type="text"
              value={user.firstName}
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.lastName")}</Form.Label>
            <Form.Control
              type="text"
              value={user.lastName}
              onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.address")}</Form.Label>
            <Form.Control
              type="text"
              value={user.address}
              onChange={(e) => setUser({ ...user, address: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("components.users.edit.phone")}</Form.Label>
            <Form.Control
              type="text"
              value={user.phoneNumber}
              onChange={(e) =>
                setUser({ ...user, phoneNumber: e.target.value })
              }
            />
          </Form.Group>

          <button
            className={styles.btnSave}
            type="submit"
            style={{ width: "25%", alignSelf: "center" }}
          >
            {t("components.users.edit.save")}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
