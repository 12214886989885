import styles from "./mobilePage.module.css";
import { useTranslation } from "react-i18next";
import header from "../../assets/styles/images/home/POZADINA_MOBILNA.png"
import { APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from "../../utils/constants";

export default function MobilePage() {
    const { t } = useTranslation("all");
    return (
        <div>
            <img src={header} width="100%" height="100%"></img>
            <div className={styles.centerDiv}>
                <div className={styles.aplication}>
                    <div className={styles.aplicationInfoContainer}>
                        <div className={styles.downloadAppTxtContainer}>
                            <div className={styles.downloadAppTxt}>
                                {t("global.downloadApp")}
                            </div>
                        </div>
                        <div className={styles.downloadAppBtnsContainer}>
                            <div style={{width:"42.5%"}}>
                            <button
                            className={styles.buttonDownloadApp}
                            name="action"
                            value="playStore"
                            onClick={() => {window.open(GOOGLE_PLAY_APP_LINK)}}
                            >
                            <div className={styles.downloadPlayStore}></div>
                            </button>
                            </div>
                            <div>
                            <button
                            className={styles.buttonDownloadApp}
                            name="action"
                            value="appStore"
                            onClick={() => {window.open(APP_STORE_APP_LINK)}}
                            >
                            <div className={styles.downloadAppStore}></div>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
    