import styles from "./ProductPage.module.css";
import News from "../../home/news/News";
import { Product } from "../../../model/product/product";
import { STATIC_HOST } from "../../../utils/communication";
import TitleWithShareButton from "../../layouts/title/TitleWithShareButton";
import DangerousDescription from "../../layouts/dangerous_description/DangerousDescription";
import Logo from "../../layouts/logo/Logo";
import PhotoGallery from "../../layouts/gallery/photo-gallery/PhotoGallery";
import { descriptionOf, nameOf } from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import ManageCartButtons from "../../shopping-cart/ManageCartButtons";

function ProductDetails(props: { product: Product }) {
  const { product } = props;
  const { t } = useTranslation("all");

  return (
    <div className={styles.sectionProductNews}>
      <div className={styles.productSection}>
        <TitleWithShareButton
          title={nameOf(product)}
          iconPath={product.client.clientCategory.code}
        />
        <div className={styles.priceSection}>
          <div>
            <div>
              <b>
                {product.unitPrice} din/{t("components.units." + product.unit)}
              </b>
            </div>
            <div>
              {t("components.products.edit.availableAmount")}:{" "}
              <b>
                {product.availableAmount}{" "}
                {t("components.units." + product.unit)}
              </b>
            </div>
            <ManageCartButtons product={product} />
          </div>
        </div>
        <div className={styles.descriptionLogoSection}>
          <div className={styles.descriptionContainer}>
            <DangerousDescription description={descriptionOf(product)} />
          </div>
          <Logo client={product.client} />
        </div>
        {product.files.length > 0 && (
          <div className={styles.mainPicture}>
            <img
              src={STATIC_HOST + product.files[0].path}
              alt="product"
              className={styles.mainImg}
              width="100%"
              height="100%"
            />
          </div>
        )}
        {product.files.length > 1 && (
          <PhotoGallery picturePaths={product.files.slice(1)} />
        )}
      </div>
      <div className={styles.sectionNews}>
        <News size={6} />
      </div>
    </div>
  );
}

export default ProductDetails;
