import styles from "./CategoryAndClient.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";
import { nameOf } from "../../../../utils/i18n";
import { Client } from "../../../../model/client/client";
import { userHasRole } from "../../../../utils/jwt";
import { getClients } from "../../../../actions/client/client";
import { ClientService } from "../../../../model/client_service/clientService";
import { ServiceCategory } from "../../../../model/client_service/serviceCategory";
import { loadCategories } from "../../../../actions/client_service/serviceCategory";

export default function CategoryAndClient(props: {
  service: ClientService;
  setService: (service: ClientService) => void;
  register: any;
  errors: any;
}) {
  const { t } = useTranslation("all");
  const { service, setService, register, errors } = props;
  const [categories, setCategories] = useState<ServiceCategory[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const setServiceCategory = (id: number) => {
    const serviceCategory = categories.find((c) => id === c.id);
    if (serviceCategory) setService({ ...service, serviceCategory });
  };

  const setServiceClient = (id: number) => {
    const client = clients.find((c) => id === c.id);
    if (client) setService({ ...service, client });
  };

  useEffect(() => {
    loadCategories().then((resp) => {
      setCategories(resp.data);
    });
    if (userHasRole("admin")) {
      getClients().then((resp) => {
        setClients(resp.data);
      });
    }
  }, []);

  return (
    <>
      <div className={styles.row}>
        <Form style={{ width: "100%", marginTop: "5vh" }}>
          <Form.Group className="mb-3">
            <InputGroup>
              {service.serviceCategory && (
                <Form.Group className="mb-3" style={{ width: "48%" }}>
                  <Form.Label className={styles.serviceTitle}>
                    {t("components.services.edit.category")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("category")}
                    value={service.serviceCategory.id}
                    onChange={(e) => setServiceCategory(Number(e.target.value))}
                  >
                    <option key={-1} value={0}></option>
                    {categories.map((serviceCategory, index) => (
                      <option key={index} value={serviceCategory.id}>
                        {nameOf(serviceCategory)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              {service.client && userHasRole("admin") && (
                <Form.Group
                  className="mb-3"
                  style={{ width: "52%", paddingLeft: "2%" }}
                >
                  <Form.Label className={styles.serviceTitle}>
                    {t("components.services.edit.client")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register("client")}
                    value={service.client.id}
                    onChange={(e) => setServiceClient(Number(e.target.value))}
                  >
                    <option key={-1} value={0}></option>
                    {clients.map((client, index) => (
                      <option key={index} value={client.id}>
                        {nameOf(client)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <div className={styles.basicRow}>
        <div className={styles.errorMessage} style={{ width: "50%" }}>
          <div>{errors.category?.message}</div>
        </div>
        <div className={styles.errorMessage} style={{ width: "40%" }}>
          <div>{errors.client?.message}</div>
        </div>
      </div>
    </>
  );
}
