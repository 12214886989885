export class ClientFilter {
  category: number;
  name: string;
  rate: number;
  constructor() {
    this.category = 0;
    this.name = "";
    this.rate = 0;
  }
}
