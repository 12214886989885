import { useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "./Form.module.css";
import { useTranslation } from "react-i18next";
import "../misc.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../../actions/auth/auth";
import { useDispatch } from "react-redux";

export default function ForgotPasswordForm() {
  const { t } = useTranslation("all");
  const dispatch = useDispatch();

  const formSchema = yup.object({
    username: yup
      .string()
      .required(t("components.auth.forgotPassword.txtEmailErrorRequired"))
      .email(t("components.auth.forgotPassword.txtEmailErrorEmail")),
  });

  const [messageForgotPass, setMessageForgotPass] = useState("");
  const [forgotPassUsername, setForgotPassUsername] = useState("");
  const [successMessageForgotPass, setSuccessMessageForgotPass] =
    useState(false);

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, setValue, formState, reset } =
    useForm(validationOpt);
  const { errors } = formState;

  const forgotPasswordErrors = (m: any) => {
    let messageError;
    switch (m) {
      case "ERRORS.USERNAME_NOT_EXIST":
        messageError = t(
          "components.auth.forgotPassword.usernameNotExistError"
        );
        break;
      default:
        messageError = t(
          "components.auth.forgotPassword.invalidForgotPassword"
        );
    }
    return messageError;
  };

  const handleSubmitForgotPassword = async () => {
    setMessageForgotPass("");
    setSuccessMessageForgotPass(false);

    (dispatch(forgotPassword(forgotPassUsername)) as any)
      .then(() => {
        setSuccessMessageForgotPass(true);
      })
      .catch((mess: any) => {
        let message = forgotPasswordErrors(mess);
        setMessageForgotPass(message);
      });
  };

  return (
    <div className={styles.formContainer}>
      <Form
        onSubmit={handleSubmit(handleSubmitForgotPassword)}
        className={styles.form}
      >
        <Form.Group>
          <div className={`row ${styles.labelclass}`}>
            <Form.Label>
              {t("components.auth.forgotPassword.username")}
            </Form.Label>
          </div>

          <Form.Control
            {...register("username")}
            id="username"
            className={styles.inputclass}
            autoFocus
            type="text"
            value={forgotPassUsername}
            onChange={(e) => setForgotPassUsername(e.target.value)}
          />
          <div className={styles.errorMessage}>{errors.username?.message}</div>
        </Form.Group>

        {messageForgotPass && (
          <div className={styles.messageContainer}>
            <p className={styles.errorMessage}>{messageForgotPass}</p>
          </div>
        )}

        {successMessageForgotPass && (
          <div className={styles.messageContainer}>
            <p className={styles.successMessage}>
              {t("components.auth.forgotPassword.successSubmit")}
            </p>
          </div>
        )}
        <div className={styles.submitBtnContainer}>
          <button
            name="action"
            value="forgotPass"
            type="submit"
            className={styles.btnSubmit}
          >
            <p className={styles.txtSubmit}>
              {t("components.auth.forgotPassword.submit")}
            </p>
          </button>
        </div>
      </Form>
    </div>
  );
}
