import styles from "./Filter.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientFilter } from "../../../../filter/clientFilter";
import { ClientCategory } from "../../../../model/client/clientCategory";
import { loadCategories } from "../../../../actions/client/clientCategory";
import Select from "react-select";
import { nameOf } from "../../../../utils/i18n";
import { selectStyles } from "../../../../utils/customSelectStyle";
import PRODUCT_CATEGORY_ICON from "../../../../assets/styles/icons/PRODUCT_CATEGORY_ICON.png";
import DOTS from "../../../../assets/styles/icons/DOTS.png";
import DIVIDER from "../../../../assets/styles/icons/DIVIDER.png";
import { Link } from "react-router-dom";
import { userHasRole } from "../../../../utils/jwt";
import Rating from "../../../../Rating/Rating";
import IKONICA_OCENA from "../../../../assets/styles/images/rute/IKONICA_OCENA.png";
import SEARCH_ICON from "../../../../assets/styles/images/ikonicaSearch.png";

export default function Filter(props: {
  setFilter: (filter: ClientFilter) => void;
}) {
  const { t } = useTranslation("all");
  const { setFilter } = props;
  const [clientFilter, setClientFilter] = useState(new ClientFilter());
  const [clientCategories, setClientCategories] = useState<ClientCategory[]>(
    []
  );

  useEffect(() => {
    loadCategories().then((resp) => {
      setClientCategories(resp.data);
    });
  }, []);

  function filter() {
    setFilter(clientFilter);
  }

  function selectCategory(item: any) {
    let newClientFilter = { ...clientFilter, category: item.id };
    setClientFilter(newClientFilter);
  }

  function selectRate(event: any) {
    let newClientFilter = { ...clientFilter, rate: Number(event) };
    setClientFilter(newClientFilter);
  }

  return (
    <div className={styles.filterAndPaginationSection}>
      <div className={styles.plusLink}>
        {userHasRole("admin") && (
          <Link className={styles.plusLinkDesign} to="/edit-client/0">
            + {t("components.clients.edit.addClient")}
          </Link>
        )}
      </div>
      <div className={styles.filterCol}>
        <div className={styles.filterSection}>
          <div className={styles.filterCategory}>
            <div className={styles.filterRow}>
              <div className={styles.marginTop}>
                <div className={styles.filterTitlesColor}>
                  {t("components.clients.filter.category")}
                </div>
              </div>
              <div className={styles.filterCategoryIcon}>
                <img
                  src={PRODUCT_CATEGORY_ICON}
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.categories}>
                <Select
                  options={clientCategories}
                  onChange={selectCategory}
                  getOptionLabel={(clientCategory) => nameOf(clientCategory)}
                  placeholder={t("components.clients.filter.category")}
                  styles={selectStyles}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "0.10416666666666667vw",
              height: "5.469556243550052vh",
              marginTop: "2.063983488132095vh",
            }}
          >
            <img src={DIVIDER} width="100%" height="100%" alt="" />
          </div>
          <div className={styles.filterRate}>
            <div className={styles.row}>
              <div className={styles.marginTop}>
                <div className={styles.filterTitlesColor}>
                  {t("components.routes.filter.minRate")}
                </div>
              </div>
              <div className={styles.rateIcon}>
                <img src={IKONICA_OCENA} width="100%" height="100%" alt="" />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Rating
                  ratingValue={clientFilter.rate}
                  size={20}
                  fillColor="orange"
                  emptyColor="gray"
                  onClick={selectRate}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "0.10416666666666667vw",
              height: "5.469556243550052vh",
              marginTop: "2.063983488132095vh",
            }}
          >
            <img src={DIVIDER} width="100%" height="100%" alt="" />
          </div>
          <div className={styles.filterName}>
            <div className={styles.filterCategoryRow}>
              <div className={styles.marginTop}>
                <div className={styles.filterTitlesColor}>
                  {t("components.clients.filter.clientName")}
                </div>
              </div>
              <div className={styles.dotsIcon}>
                <img src={DOTS} width="100%" height="100%" alt="" />
              </div>
            </div>
            <div className={styles.row} style={{ marginTop: "5px" }}>
              <div className={styles.categories}>
                <input
                  type="text"
                  className={styles.inputClientName}
                  value={clientFilter.name}
                  onChange={(e: any) =>
                    setClientFilter({ ...clientFilter, name: e.target.value })
                  }
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.filterSearchContainer}>
            <div className={styles.filterSearch} onClick={() => filter()}>
              <div className={styles.filterIcon}>
                <img src={SEARCH_ICON} width="100%" height="100%" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
