import styles from "./Services.module.css";
import { useEffect, useState } from "react";
import { loadServicesPerPage } from "../../../actions/client_service/clientService";
import { ClientService } from "../../../model/client_service/clientService";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ServiceCard from "../../service/service-card/ServiceCard";
import TitleDarkLight from "../../layouts/title/TitleDarkLight";
import ViewAllButton from "../view-all-button/ViewAllButton";
import Arrows from "../../layouts/arrow/Arrows";

function Services() {
  const { t } = useTranslation("all");
  const dispatch = useDispatch();
  const services = useSelector<any, ClientService[]>(
    (state) => state.services.clientServices
  );
  const [page, setPage] = useState(0);
  const size = 5;
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let isMounted = true;
    loadServicesPerPage(page, size, dispatch).then((pages) => {
      if (isMounted) setLastPage(pages - 1);
    });
    return () => {
      isMounted = false;
    };
  }, [page]);

  return (
    <>
      <TitleDarkLight
        titleDark={t("global.eatpannonia")}
        titleLight={t("components.home.services")}
      />
      <div className={styles.arrowsRow}>
        <Arrows page={page} lastPage={lastPage} changePage={setPage} />
      </div>
      <div className={styles.sectionServicesContent}>
        <div className={styles.servicesContent}>
          <div className={styles.services}>
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                service={service}
                handleDelete={() => {}}
              />
            ))}
          </div>
        </div>
      </div>
      <ViewAllButton page="/services-page" />
    </>
  );
}

export default Services;
