import styles from "./Logo.module.css";
import { nameOf } from "../../../utils/i18n";
import { useNavigate } from "react-router-dom";
import { STATIC_HOST } from "../../../utils/communication";
import { Client } from "../../../model/client/client";

function Logo(props: { client: Client }) {
  const { client } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.logoContainer}>
      {client.logo && (
        <img
          src={STATIC_HOST + client.logo}
          className={styles.img}
          width="80%"
          onClick={() => navigate("/client-details-page/" + client.id)}
          alt=""
        />
      )}
      <div
        className={styles.clientDetailsRow}
        style={{ fontWeight: "bold" }}
        onClick={() => navigate("/client-details-page/" + client.id)}
      >
        {nameOf(client)}
      </div>
      <div className={styles.placeRow}>
        {nameOf(client.place)}, {nameOf(client.place?.country)}
      </div>
    </div>
  );
}

export default Logo;
