import { useEffect, useState } from "react";
import styles from "./EmailValidated.module.css";
import { useSearchParams } from "react-router-dom";
import { authService } from "../../../rest/auth/authService";
import { useTranslation } from "react-i18next";
import Header from "../../layouts/header/Header";
import { STATIC_HOST } from "../../../utils/communication";

function EmailValidated() {
  const { t } = useTranslation("all");
  const [isValidated, setValidated] = useState(false);
  const [isError, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    authService
      .validateAccount(token ?? "")
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        if (token) {
          localStorage.setItem("token", token);
          setValidated(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  return (
    <>
      <Header
        background={
          STATIC_HOST +
          "front/register-login/LOGIN_REGISTER_HEADER_POZADINA.png"
        }
        height={52}
        gradient={STATIC_HOST + "front/register-login/GRADIENT.png"}
        gradientHeight={23}
      />
      <div className={styles.mainContainer}>
        <div className={styles.pageSection}>
          {isValidated && (
            <p className={styles.title}>
              {t("components.validation.successMessage")}
            </p>
          )}
          {isError && (
            <p className={styles.title}>
              {t("components.validation.errorMessage")}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default EmailValidated;
