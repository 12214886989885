import styles from "./Title.module.css";
import BALON_ICON from "../../../assets/styles/icons/BALON_IKONICA.png";

function TitleDarkLight(props: { titleDark?: string; titleLight?: string }) {
  const { titleDark, titleLight } = props;
  return (
    <div className={styles.title}>
      <div className={styles.icon}>
        <img src={BALON_ICON} alt="" />
      </div>
      <div className={styles.titleStyleDark}>{titleDark}</div>
      <div className={styles.titleStyleLight}>{titleLight}</div>
    </div>
  );
}

export default TitleDarkLight;
