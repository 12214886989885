import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditService.module.css";
import News from "../../home/news/News";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../model/file/file";
import { Client } from "../../../model/client/client";
import { userHasRole } from "../../../utils/jwt";
import { ClientService } from "../../../model/client_service/clientService";
import { ServiceCategory } from "../../../model/client_service/serviceCategory";
import {
  create,
  getServiceById,
  update,
} from "../../../actions/client_service/clientService";
import Header from "../../layouts/header/Header";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { STATIC_HOST } from "../../../utils/communication";
import VideoGallery from "../../edit/video-gallery/VideoGallery";
import PhotoGallery from "../../edit/photo-gallery/PhotoGallery";
import EditName from "../../edit/edit-name/EditName";
import EditDescription from "../../edit/edit-description/EditDescription";
import CategoryAndClient from "./CategoryAndClient/CategoryAndClient";

export default function EditService() {
  const { id } = useParams();
  const [service, setService] = useState<ClientService>({} as ClientService);
  const [images, setImages] = useState<any[]>([]);
  const [videoPaths, setVideoPaths] = useState<File[]>([]);
  const { t } = useTranslation("all");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [descriptionSrb, setDescriptionSrb] = useState("");
  const [descriptionCro, setDescriptionCro] = useState("");

  const formSchema = Yup.object().shape({
    name: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!service.name
    ),
    nameSrb: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!service.nameSrb
    ),
    nameCro: Yup.string().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!service.nameCro
    ),
    category: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!service.serviceCategory.id
    ),
    client: Yup.number().test(
      "has text",
      t("components.validation.fieldRequired"),
      (value) => !!service.client.id || userHasRole("client")
    ),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(validationOpt);
  const { errors } = formState;

  const saveService = () => {
    let formData = new FormData();
    for (let vid of service.videos) {
      if (!vid.path.includes("https://www.youtube.com/")) {
        return;
      }
    }
    service.description = description;
    service.descriptionSrb = descriptionSrb;
    service.descriptionCro = descriptionCro;
    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i].file);
    }
    for (let i = 0; i < service.files.length; i++) {
      service.files[i].orderNum = i + 1;
    }
    const blob = new Blob([JSON.stringify(service)], {
      type: "application/json",
    });
    formData.append("dto", blob);
    if (!service.id) {
      create(formData).then(() => {
        navigate("/services-page");
      });
    } else {
      update(formData).then(() => {
        navigate("/services-page");
      });
    }
  };

  const setServiceById = () => {
    getServiceById(Number(id)).then((resp) => {
      setService(resp.data);
      setVideoPaths(resp.data.videos);
      setDescription(resp.data.description);
      setDescriptionSrb(resp.data.descriptionSrb);
      setDescriptionCro(resp.data.descriptionCro);
    });
  };

  const setNewService = () => {
    let newService = new ClientService();
    newService.serviceCategory = new ServiceCategory();
    newService.client = new Client();
    setService(newService);
    setDescription("");
    setDescriptionSrb("");
    setDescriptionCro("");
  };

  useEffect(() => {
    if (Number(id)) {
      setServiceById();
    } else {
      setNewService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <Header
        background={STATIC_HOST + "front/usluge/USLUGE_HEADER_POZADINA.png"}
        height={86.06811145510837}
        gradient={STATIC_HOST + "front/usluge/GRADIENT.png"}
        gradientHeight={24.664602683178536}
      />
      <form onSubmit={handleSubmit(saveService)}>
        <div className={styles.sectionServiceNews}>
          <div className={styles.serviceSection}>
            <div className={styles.saveRow}>
              <button type="submit" className={styles.btnSave}>
                {t("components.services.edit.save")}
              </button>
            </div>
            <EditName
              name={service.name}
              nameSr={service.nameSrb}
              nameHr={service.nameCro}
              setName={(newName) => setService({ ...service, name: newName })}
              setNameSr={(newName) =>
                setService({ ...service, nameSrb: newName })
              }
              setNameHr={(newName) =>
                setService({ ...service, nameCro: newName })
              }
              title={t("components.services.edit.name")}
              register={register}
              errors={errors}
            />
            <EditDescription
              description={description}
              descriptionSr={descriptionSrb}
              descriptionHr={descriptionCro}
              setDescription={setDescription}
              setDescriptionSr={setDescriptionSrb}
              setDescriptionHr={setDescriptionCro}
              title={t("components.services.edit.description")}
            />
            <CategoryAndClient
              service={service}
              setService={setService}
              register={register}
              errors={errors}
            />
            <PhotoGallery
              files={service.files}
              setFiles={(newFiles) => {
                setService({ ...service, files: newFiles });
              }}
              images={images}
              setImages={setImages}
            />
            <VideoGallery
              videos={service.videos}
              setVideos={(newVideos) => {
                setService({ ...service, videos: newVideos });
              }}
              videoPaths={videoPaths}
              setVideoPaths={setVideoPaths}
            />
          </div>
          <div className={styles.sectionNews}>
            <News size={6} />
          </div>
        </div>
      </form>
    </div>
  );
}
